import axios from 'axios';
import DEV from '../../url';
export class ValeurService {


    create(data){
        return axios.post(`${DEV}valeur/create`,data).then((res)=>res.data);
    }


    update(id,data){
        return axios.put(`${DEV}valeur/update/${id}`, data).then(res=> res.data);
    }

    updateImage(id,data){
        return axios.put(`${DEV}valeur/updateImage/${id}`, data).then(res=> res.data);
    }

    get(id){
        return axios.get(`${DEV}valeur/get/${id}`).then((res)=>res.data);
    }

    all(){
        return axios.get(`${DEV}valeur/all`).then((res)=>res.data);
    }

    delete(id){
        return axios.delete(`${DEV}valeur/delete/`+id).then((res)=>res.data);
    }
}
