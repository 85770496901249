import React, {useEffect, useRef, useState} from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Toast} from 'primereact/toast';
import {Button} from 'primereact/button';
import {Toolbar} from 'primereact/toolbar';
import {InputText} from 'primereact/inputtext';
import {HeadingService} from "../../service/HeadingService/HeadingService";
import DEV from '../../url';
const HeadingList = () => {

 

   

    const service = new HeadingService();


    const [items, setItems] = useState([null]);

    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    const exportCSV = () => {
        dt.current.exportCSV();
    }

    useEffect(() => {
        service.all().then((res) => {
            toast.current.show({severity: 'success', summary: 'Opération Achevée', detail: 'Opération effectuée avec succés', life: 3000});
            setItems(res);
        });
    }, []);





/* 
    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Exporter en CSV" icon="pi pi-upload" className="p-button-help" onClick={exportCSV}/>
            </React.Fragment>
        )
    } */

    const typeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Type</span>
                <b> {rowData?.type_heading}</b>
            </>
        );
    }

    const imgBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Image</span>
                <img height={200} width={300} src={`${DEV}uploads/headings/${rowData?.img}`} alt="entete-img"/>
            </>
        );
    }



    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <a href={`/#/enteteImage/` + rowData?.id}> <Button title='Modifier Image' icon="pi pi-upload" className="p-button-rounded p-button-warning mr-1">
                </Button> </a> &nbsp;
            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Les entêtes (L'image doit être avec les dimensions d'une couverture)</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search"/>
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Cherchez ..."/>
            </span>
        </div>
    );

    return (
        <div className="grid crud-demo" style={{marginTop: 0}}>
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast}/>
                    <Toolbar className="mb-4"></Toolbar>


                    <DataTable ref={dt} value={items}
                               dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                               paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                               currentPageReportTemplate="Affichage {first} du {last} au {totalRecords} éléments"
                               globalFilter={globalFilter} emptyMessage="Aucun item n'a été trouvé." header={header} responsiveLayout="scroll">

                        <Column field="type_heading" header="Type" sortable body={typeBodyTemplate} headerStyle={{width: '20%', minWidth: '10rem'}}></Column>
                        <Column header="Image" body={imgBodyTemplate} headerStyle={{width: '20%', minWidth: '10rem'}}></Column>
                        <Column headerStyle={{width: '20%', minWidth: '10rem'}} body={actionBodyTemplate}></Column>
                    </DataTable>

                                 </div>
            </div>
        </div>
    )

}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps?.location?.pathname === nextProps?.location?.pathname;
};

export default React.memo(HeadingList, comparisonFn);
