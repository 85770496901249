import React, { useState, useEffect } from "react";
import { ProductService } from "../../service/productService/ProductService";


const ProductsStat = () => {

    const service = new ProductService();

    const [count, setCount] = useState([]);


    const count_ = () => {

        service.all().then((res)=>{
            setCount(res);
        })

    }

    useEffect(()=>{
          count_();
    },[]);




    return (
        <div className="card mb-0">
            <div className="flex justify-content-between mb-3">
                <div>
                    <span className="block text-500 font-medium mb-3">Produits</span>
                    <div className="text-900 font-medium text-xl">
                    {count.length}
                    </div>
                </div>
                <div className="flex align-items-center justify-content-center bg-blue-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                    <i className="pi pi-shopping-bag text-blue-500 text-xl" />
                </div>
            </div>

        </div>
    );

}

export default React.memo(ProductsStat);
