import React, { useEffect, useRef, useState } from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { InputText } from "primereact/inputtext";
import ShowMoreText from "react-show-more-text";
import { Dialog } from "primereact/dialog";
import { ReminderService } from "../../service/reminderService/ReminderService";


const Reminders = () => {

    const service = new ReminderService();

    const toast = useRef(null);
    const dt = useRef(null);
    const [reminder, setReminder] = useState();
    const [deleteItemDialog, setDeleteItemDialog] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [reminders, setReminders] = useState([null]);
    useEffect(() => {
        service.getAll().then((res) => {
            toast.current.show({ severity: 'success', summary: 'Opération Achevée', detail: 'Opération effectuée avec succés', life: 3000 });
            setReminders(res);
        });
    }, []);

    const confirmDelete = async (e, id) => {
        e.preventDefault();
        await service.supprimer(id).then((res) => {
            toast.current.show({ severity: 'success', summary: 'Opération Achevée', detail: 'Cet item a été supprimé', life: 3000 });
            setDeleteItemDialog(false);
            const trash = reminders.filter(() => res?.result !== id);
            setReminders(trash);
            window.location.reload(false);
        }, []);

    }

    const hideDeleteItemDialog = () => {
        setDeleteItemDialog(false);
    }

    const deleteItem = (e, contact) => {
        e.preventDefault();
        setReminder({ ...contact });
        setDeleteItemDialog(true);
    }

    const executeOnClick = (isExpanded) => {
        console.log(isExpanded);
    }


    const fullnameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Nom Complet</span>
                <span> {rowData?.fullname}</span>
            </>
        );
    }

    const emailBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Adresse</span>
                <span> {rowData?.email}</span>
            </>
        );
    }


    const subjectBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Objet</span>
                <span> {rowData?.subject}</span>
            </>
        );
    }

    const messageBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Message</span>
                <ShowMoreText

                    lines={2}
                    more="Voir Plus"
                    less="Voir Moins"
                    className="text-justify"
                    anchorClass="my-anchor-css-class"
                    onClick={executeOnClick}
                    expanded={false}
                    width={280}
                    truncatedEndingComponent={"... "}
                >
                    <b> {rowData?.message}</b>
                </ShowMoreText>

            </>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">



                    &nbsp;

                <Button onClick={(e) => deleteItem(e, rowData)} title='Supprimer cet item' icon="pi pi-trash" className="p-button-rounded p-button-danger mt-2 mr-1" />

            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Rappels</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const deleteItemDialogFooter_ = (rowData) => (
        <>
            <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={hideDeleteItemDialog} />
            <Button label="Oui" icon="pi pi-check" className="p-button-text" onClick={(e) => confirmDelete(e, rowData.id)} />
        </>
    );


    return (
        <div className="grid crud-memo" style={{marginTop:0}}>
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <DataTable ref={dt} value={reminders}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Affichage {first} du {last} au {totalRecords} items"
                        globalFilter={globalFilter} emptyMessage="Aucun rappel n'a été trouvé." header={header} responsiveLayout="scroll">

                        <Column field="fullname" header="Nom Complet" sortable body={fullnameBodyTemplate} headerStyle={{ width: '20%', minWidth: '10rem' }}></Column>
                        <Column field="email" header="Adresse" sortable body={emailBodyTemplate} headerStyle={{ width: '20%', minWidth: '10rem' }}></Column>
                        <Column field="subject" header="Objet" body={subjectBodyTemplate} sortable headerStyle={{ width: '20%', minWidth: '8rem' }}></Column>
                        <Column field="message" header="Message" body={messageBodyTemplate} sortable headerStyle={{ width: '20%', minWidth: '8rem' }}></Column>

                        <Column headerStyle={{ width: '20%', minWidth: '10rem' }} body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={deleteItemDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteItemDialogFooter_(reminder)} onHide={hideDeleteItemDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {reminder && <span>Êtes-vous sûr de vouloir supprimer ce rappel : {reminder?.fullname}{" Ayant l'adresse  : "}{reminder?.email} ?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    )


}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps?.location?.pathname === nextProps?.location?.pathname;
};

export default React.memo(Reminders, comparisonFn);
