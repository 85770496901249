import { Button } from "primereact/button";
import { Toast } from 'primereact/toast';
import { Toolbar } from "primereact/toolbar";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import classNames from "classnames";
import { ValeurService } from "../../service/valeurService/ValeurService";

const ModifierValeur = () => {

     const history = useNavigate();
    const toast = useRef(null);


    const { id } = useParams();
    const service = new ValeurService();

    const [title, setTitle] = useState("");

    const fetch = async () => {
        service.get(id).then((res) => {
            setTitle(res?.title);
          
        });
    }


    const modifier = async (e) => {
        e.preventDefault();
        /*const formData = new FormData()
        formData.append('title', title)
        formData.append('type_chaine', type_chaine)*/
        if(title===""){
            toast.current.show({ severity: "error", summary: "Merci de bien compléter les champs", life: 3000 });
        }else{
             service.update(id, {title:title}).then((res) => {
            toast.current.show({ severity: 'success', summary: 'Opération Achevée', detail: 'Valeur Modifiée', life: 3000 });
        });
        }
       
    }


    useEffect(() => {
        fetch();

    }, []);

      const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button onClick={(e) => modifier(e)} label="Sauvegarder" icon="pi pi-fw pi-check" className="p-button-success mr-2" />
            </React.Fragment>
        )
    }

    const goto = (e) => {
        e.preventDefault();
        history("/valeurs")
    }

     const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button onClick={(e) => goto(e)} className="p-button-rounded p-button-outlined p-button-danger">
                    <i className="pi pi-times"></i>
                </Button>
            </React.Fragment>
        )
    }


     return (
        <React.Fragment>
            <div className="grid" style={{marginTop:0}}>
                <Toast ref={toast} />
                <Toolbar className="mb-4 col-12" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                <div className="col-12 md:col-12 mt-4">
                    <div className="card p-fluid">
                        <h5>Modifier cette valeur</h5>
                        <form>
                            <div className="field">
                                <label htmlFor="title">Titre</label>
                            <InputText id="title" value={title} onChange={(e) => setTitle(e.target.value)}
                                       className={classNames({'p-invalid': !title})}/>
                            </div>

                      
                        </form>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )


}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps?.location?.pathname === nextProps?.location?.pathname;
};

export default React.memo(ModifierValeur, comparisonFn);
