import {Button} from "primereact/button";
import React, {useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Toolbar} from "primereact/toolbar";
import {Toast} from 'primereact/toast';
import {InputText} from "primereact/inputtext";
import {SpaService} from "../../service/SpaService/SpaService";

const AssignSpaVideos = () => {

    const history = useNavigate();
    const toast = useRef(null);

    const service = new SpaService();
    const {id} = useParams();

    const [label, setLabel] = useState("");
    const [link, setLink] = useState("");
        const [listImg, setListImg] = useState([]);


    const getSpa = async () => {
        service.get(id).then(() => {
            console.log("");
        })
    }

    const affecter = async (e) => {
        e.preventDefault();
        service.assignVideos(id, {label, link}).then(() => {
            toast.current.show({severity: 'success', summary: 'Opération Achevée', detail: 'Vous avez affecté une vidéo', life: 3000});
            window.location.reload(false);
        });
    }

    const afficherVideos = async () => {
        await service.getSpaVideos(id).then((res) => {
            setListImg(res?.data);
            console.log(res.data)
        });
    }

    useEffect(() => {
        afficherVideos();
    }, []);


    const supprimerVideo = async (idSpaVideo,e) => {
        e.preventDefault();
         service.deleteVideos(idSpaVideo).then((res) => {
            toast.current.show({severity: 'success', summary: 'Opération Achevée', detail: 'Vous avez supprimé une vidéo ', life: 3000});
             console.log(idSpaVideo)
            const trash = listImg.filter(() =>res?.result !== idSpaVideo);
            setListImg(trash);
        });
    }

    const goto = (e) => {
        e.preventDefault();
        history("/hammam_spa")
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button onClick={(e) => affecter(e)} label="Assigner" icon="pi pi-fw pi-check" className="p-button-success mr-2"/>
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button onClick={(e) => goto(e)} className="p-button-rounded p-button-outlined p-button-danger">
                    <i className="pi pi-times"></i>
                </Button>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>

            <div className="grid" style={{marginTop:0}}>
                <Toast ref={toast}/>
                <Toolbar className="mb-4 col-12" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                <div className="col-12 md:col-12 mt-4">
                    <div className="card p-fluid">
                        <h5>Affecter des vidéos à votre Hammam & Spa</h5>
                        <form>
                            <div className="field">
                                <label htmlFor="tag">Label</label>
                                <InputText onChange={(e) => setLabel(e.target.value)} className="mt-3" id="tag" type="text"/>

                            </div>
                            <div className="field">
                                <label htmlFor="link">Lien de la vidéo</label>
                                <InputText type="text" onChange={(e)=>setLink(e.target.value)} accept="*"/>

                            </div>

                        </form>
                    </div>


                </div>

                <div className="col-12 md:col-12 mt-4">
                    <div className="card p-fluid">
                        <h5>Voir les vidéos</h5>
                        <div className="grid">
                            {
                                listImg.map((item, index) => (

                                    <div key={index} className="field col">
                                        <a target={"_blank"} href={item.link}>Consulter cette vidéo</a>
                                        <Button onClick={(e) => supprimerVideo(item?.idSpaVideo,e)} className="p-button-rounded p-button-danger p-button-text">
                                            <i className="pi pi-times"></i> &nbsp; Retirer
                                        </Button>

                                    </div>

                                ))
                            }

                        </div>

                    </div>


                </div>

            </div>

        </React.Fragment>
    )

}
const comparisonFn = function (prevProps, nextProps) {
    return prevProps?.location?.pathname === nextProps?.location?.pathname;
};

export default React.memo(AssignSpaVideos, comparisonFn);
