import axios from 'axios';
import DEV from '../../url';
export class ConventionService {

    create(data) {

        return  axios.post(`${DEV}convention/create`,data).then((res)=>res.data);
    }
    all() {

        return  axios.get(`${DEV}convention/all`).then((res)=>res.data);
    }
    allTn() {

        return  axios.get(`${DEV}convention/allTn`).then((res)=>res.data);
    }
    allNotTn() {

        return  axios.get(`${DEV}convention/allNotTn`).then((res)=>res.data);
    }
    delete(id)
    {
        return axios.delete(`${DEV}convention/delete/${id}`);
    }

    async get(id)
    {
        const res = await axios.get(`${DEV}convention/get/${id}`);
        return res;
    }
    update(id,data) {
        return axios.put(`${DEV}convention/update/`+id,data).then((res)=>res.data);
    }
}
