import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { Toolbar } from "primereact/toolbar";
import React, { useEffect, useRef, useState } from "react";
import { ActivityService } from "../../service/activityService/ActivityService";
import { useNavigate, useParams } from "react-router-dom";
import DEV from "../../url";
const ModifierActivité = () => {
    const navigate = useNavigate();
    const toast = useRef(null);

    const activitéService = new ActivityService();
    const { id } = useParams();
    const [title, setTitle] = useState("");
    const [price, setPrice] = useState(0);
    const [description, setDescription] = useState("");
    const [img, setImg] = useState("");
    const [type, setType] = useState("");
    const [dateAc, setDateAc] = useState("");

    const dropdownValues = [
        { name: "Intérieure", code: "int" },
        { name: "Extérieure", code: "ext" },
        { name: "Culturelle", code: "clt" },
        { name: "Atelier", code: "atl" },
    ];

    const modifierActivité = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("title", title);
        formData.append("price", price);
        formData.append("description", description);
        //   formData.append('img', img)
        formData.append("type", type);
        //   formData.append('dateAc', dateAc)

        if (title === "" || description === "" || img === "" || type === "") {
            toast.current.show({ severity: "error", summary: "Merci de bien compléter les champs", life: 3000 });
        } else {
            activitéService.updateActivity(id, formData, {}).then(() => {
                toast.current.show({ severity: "success", summary: "Opération Achevée", detail: "Activité Modifiée", life: 3000 });
            });
        }
    };

    const getActivité = async () => {
        activitéService.getActivity(id).then((res) => {
            setTitle(res.title);
            setPrice(res.price);
            setDescription(res.description);
            setImg(res.img);
            setType(res.type);
            // setDateAc(res.dateAc);
        });
    };

    useEffect(() => {
        getActivité();
    }, []);

    const handleUploadChange = (e) => {
        if (e.target.files[0]) {
            setImg(e.target.files[0]);
        }
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button onClick={(e) => modifierActivité(e)} label="Sauvegarder" icon="pi pi-fw pi-check" className="p-button-success mr-2" />
            </React.Fragment>
        );
    };

    const gotoactivities = (e) => {
        e.preventDefault();
        navigate("/activites");
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button onClick={(e) => gotoactivities(e)} className="p-button-rounded p-button-outlined p-button-danger">
                    <i className="pi pi-times"></i>
                </Button>
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            <div className="grid" style={{ marginTop: 0 }}>
                <Toast ref={toast} />
                <Toolbar className="mb-4 col-12" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                <div className="col-12 md:col-12 mt-4">
                    <div className="card p-fluid">
                        <h5>Modifier Cette Activité</h5>
                        <form>
                            <div className="field">
                                <label htmlFor="title">Titre</label>
                                <InputText value={title} onChange={(e) => setTitle(e.target.value)} id="title" type="text" />
                            </div>

                            <div className="field">
                                <label htmlFor="price">Prix</label>
                                <InputText value={price} onChange={(e) => setPrice(e.target.value)} id="price" type="number" />
                            </div>

                            <div className="field">
                                <label htmlFor="description">Description</label>
                                <InputTextarea cols={8} rows={3} className="form-control" value={description || " "} onChange={(e) => setDescription(e.target.value)} id="description" />
                            </div>

                            {/*<div className="field">
                                <label htmlFor="img">Image </label>
                                <InputText onChange={handleUploadChange} className="mt-3" id="img" type="file" />

                                <div align="center">

                                                <div align="center" className="mt-3">
                                                    <img src={img} alt="image activité" height={180} width="auto" />
                                                </div>
                                </div>


                                <InputText
                                    hidden={true}
                                    placeholder="after you upload the image, paste the url here"
                                    id="img"
                                    type="text"
                                    name="img"
                                    value={img}
                                    onChange={(e) => setImg(img)}
                                />
                            </div>*/}
                            <div className="field">
                                <label className="mb-3">Type</label>
                                <Dropdown name="type" value={type} onChange={(e) => setType(e.target.value)} options={dropdownValues} optionValue="name" optionLabel="name" placeholder="Selectionner Votre Type" />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps?.location?.pathname === nextProps?.location?.pathname;
};

export default React.memo(ModifierActivité, comparisonFn);
