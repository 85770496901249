import { Button } from "primereact/button";
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { Toolbar } from "primereact/toolbar";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FeedbackService } from "../../service/FeedbackService/FeedbackService";


const ConfirmFeedback = () => {

    const history = useNavigate();
    const toast = useRef(null);

    const service = new FeedbackService();
    const { id } = useParams();

    const [fullname, setFullname] = useState("");
    const [message, setMessage] = useState("");
    const [subject, setSubject] = useState("");
    const [approbation, setApprobation] = useState("");
    const [dateFeedback, setDateFeedback] = useState("");

    const dropdownValues = [
        { name: 'Oui', code: 'oui' },
        { name: 'Non', code: 'non' },
    ];

    const modifier = async (e) => {
        e.preventDefault();
        if(approbation===""){
            toast.current.show({ severity: "error", summary: "Merci de bien vouloir traiter l'approbation de cet avis.", life: 3000 });
        }else{
            service.approve(id, {fullname:fullname,message:message,subject:subject,approbation:approbation,dateFeedback:dateFeedback }).then((res) => {
            toast.current.show({ severity: 'success', summary: 'Opération Achevée', detail: 'Avis Apprové', life: 3000 });
        });
        }
        
    }

    const fetch = async () => {
        service.get(id).then((res) => {
            setFullname(res?.data?.fullname);
            setSubject(res?.data?.subject);
            setMessage(res?.data?.message);
            setDateFeedback(res?.data?.dateFeedback);
            setApprobation(res?.data?.approbation);
        });
    }

    useEffect(() => {
        fetch();
    }, []);


    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button onClick={(e) => modifier(e)} label="Sauvegarder" icon="pi pi-fw pi-check" className="p-button-success mr-2" />
            </React.Fragment>
        )
    }

    const goto = (e) => {
        e.preventDefault();
        history("/avis")
    }


    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button onClick={(e) => goto(e)} className="p-button-rounded p-button-outlined p-button-danger">
                    <i className="pi pi-times"></i>
                </Button>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <div className="grid"style={{marginTop:0}}>
                <Toast ref={toast} />
                <Toolbar className="mb-4 col-12" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                <div className="card col-12 md:col-12 mt-4">
                    <h4>Objet</h4>
                    <span>{subject ? <>{subject}</> : <></>}</span>
                </div>
                <div className="card col-12 md:col-12 mt-4">
                    <h4>Message</h4>
                    <span className="text-justify">{message ? <>{message}</> : <></>}</span>
                </div>
                <div className="card col-12 md:col-12 mt-4" style={{border:'1px solid green'}}>
                    <h4>Confirmez-vous l'approbation de cet avis ? Si oui, il sera affiché dans la rubrique des avis.</h4>
                    <div className="col-12">
                        <Dropdown style={{width:200}} className="" name="type" value={approbation} onChange={(e) => setApprobation(e.target.value)} options={dropdownValues} optionValue="name" optionLabel="name" placeholder="Selectionner votre choix" />
                    </div>
                </div>

            </div>
        </React.Fragment>
    )


}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(ConfirmFeedback, comparisonFn);
