import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import React, { useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ProductService } from "../../service/productService/ProductService";
import classNames from "classnames";
const ModifierImageProduit = () => {
    const history = useNavigate();
    const toast = useRef(null);
    const [submitted, setSubmitted] = useState(false);
    const _ = new ProductService();

    const { id } = useParams();

    const [img, setImg] = useState("");

    const modifierImage = async (e) => {
        e.preventDefault();
        setSubmitted(true);
        const formData = new FormData();

        formData.append("img", img);

        if (img === "") {
            toast.current.show({ severity: "error", summary: "Merci de bien compléter le champ requis.", life: 3000 });
        } else {
            _.updateItem(id, formData).then(() => {
                toast.current.show({ severity: "success", summary: "Opération Achevée", detail: "Image Modifiée", life: 3000 });
            });
        }
    };

    const handleUploadChange = (e) => {
        if (e.target.files[0]) {
            setImg(e.target.files[0]);
        } else {
            setImg(img);
        }
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button onClick={(e) => modifierImage(e)} label="Sauvegarder" icon="pi pi-fw pi-check" className="p-button-success mr-2" />
            </React.Fragment>
        );
    };

    const goback = (e) => {
        e.preventDefault();
        history("/produits");
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button onClick={(e) => goback(e)} className="p-button-rounded p-button-outlined p-button-danger">
                    <i className="pi pi-times"></i>
                </Button>
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            <div className="grid" style={{ marginTop: 0 }}>
                <Toast ref={toast} />
                <Toolbar className="mb-4 col-12" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                <div className="col-12 md:col-12 mt-4">
                    <div className="card p-fluid">
                        <h5>Modifier Cette Image</h5>
                        <form>
                            <div className="field">
                                <label htmlFor="img">Image</label>
                                <InputText autoFocus className={classNames({ "p-invalid mt-3": submitted && !img })} onChange={handleUploadChange} id="img" type="file" />
                                {submitted && !img && <small className="p-invalid">Champ Obligatoire.</small>}

                                <div className="mt-3" style={{ borderRadius: 5, height: "auto", border: "1px solid green", backgroundColor: "#B7E5B0", color: "black" }}>
                                    <p className="pt-2 mx-3">
                                        <i className="pi pi-fw pi-file"></i> <b>A noter :</b> <br />
                                        - L'image est un champ obligatoire. <br />- Le nom de l'image doit être sous format <b>jpeg, png ou bien jpg</b>. <br />- Le nom de l'image ne doit pas contenir des éspaces.
                                    </p>
                                </div>
                                <div align="center">
                                    <div align="center">
                                        <img src={img} alt="image_produit" height={100} width="auto" id="img" name="img" />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps?.location?.pathname === nextProps?.location?.pathname;
};

export default React.memo(ModifierImageProduit, comparisonFn);
