import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";

import { RestaurantService } from "../../service/restaurantService/restaurantService";
import { Dropdown } from "primereact/dropdown";
import classNames from "classnames";
import DEV from "../../url";
const MenuCard = () => {
    let empty = {
        id: 0,
        file_src: "",
        visibility: "",
    };

    const [submitted, setSubmitted] = useState(false);

    const service_ = new RestaurantService();
    const [menu, setMenu] = useState(null);
    const [dialog, setDialog] = useState(false);
    const [menus, setMenus] = useState([]);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [visibility, setVisibility] = useState("");
    const [file_src, setFile_src] = useState("");

    const dropdownValues = [
        { name: "Visible", code: 1 },
        { name: "Caché", code: 0 },
    ];

    const fetch = () => {
        service_.displayMenuRestaurant().then((res) => {
            toast.current.show({ severity: "success", summary: "Opération Achevée", detail: "Opération effectuée avec succés", life: 3000 });
            setMenus(res);
        });
    };

    useEffect(() => {
        fetch();
    }, []);

    const hideDeleteDialog = () => {
        setDeleteDialog(false);
    };

    const delete_ = (e, event) => {
        e.preventDefault();
        setMenu({ ...event });
        setDeleteDialog(true);
    };

    const confirmDelete = async (e, id) => {
        e.preventDefault();
        await service_.deleteMenuCard(id).then((res) => {
            toast.current.show({ severity: "success", summary: "Opération Achevée", detail: "Cette carte a été supprimé", life: 3000 });
            setDeleteDialog(false);
            const trash = menus.filter(() => res?.result !== id);
            setMenus(trash);
            window.location.reload(false);
        }, []);
    };

    const openNew = () => {
        setMenu(empty);
        setDialog(true);
    };

    const save = () => {
        setSubmitted(true);

        const formData = new FormData();

        formData.append("file_src", file_src);
        formData.append("visibility", visibility);
        if (file_src === "" || visibility === 0) {
            toast.current.show({ severity: "error", summary: "Merci de bien compléter les champs", life: 3000 });
            setDialog(true);
        } else {
            service_.createMenuRestaurant(formData, {}).then(() => {
                toast.current.show({ severity: "success", summary: "Opération Achevée", detail: "Menu Créé", life: 3000 });
                setMenus(menus);

                window.location.reload(false);
                setDialog(false);
            });
        }
    };

    const hideDialog = () => {
        setDialog(false);
    };

    const handleUploadChange = (e) => {
        if (e.target.files[0]) {
            setFile_src(e.target.files[0]);
        }
    };

    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    const exportCSV = () => {
        dt.current.exportCSV();
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Nouvelle carte" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                </div>
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Exporter en CSV" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        );
    };

    const visibilityBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Visibilité</span>
                <b> {rowData.visibility === 0 ? "caché" : "visible"}</b>
            </>
        );
    };

    const imgBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Image</span>
                <img src={`${DEV}uploads/restaurants/${rowData?.file_src}`} height={150} width={140} alt={"file_src"} />
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <a href={`/#/menuUpdate/modifierVisibilité/` + rowData.id}>
                    {" "}
                    <Button title="Modifier la visibilité de cette carte " icon="pi pi-eye" className="p-button-rounded p-button-info mr-1"></Button>{" "}
                </a>
                <a href={`/#/menuUpdate/modifier/` + rowData.id}>
                    {" "}
                    <Button title="Changer cette carte " icon="pi pi-upload" className="p-button-rounded p-button-warning mr-1"></Button>{" "}
                </a>
                <Button title="Supprimer cette carte" icon="pi pi-trash" className="p-button-rounded p-button-danger mt-2 mr-1" onClick={(e) => delete_(e, rowData)} />
            </div>
        );
    };

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Menu à la carte</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Cherchez ..." />
            </span>
        </div>
    );

    const dialogFooter = (
        <>
            <Button label="Annuler" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Sauvegarder" icon="pi pi-check" className="p-button-text" onClick={save} />
        </>
    );

    const deleteDialogFooter_ = (rowData) => (
        <>
            <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={hideDeleteDialog} />
            <Button label="Oui" icon="pi pi-check" className="p-button-text" onClick={(e) => confirmDelete(e, rowData.id)} />
        </>
    );

    return (
        <div className="grid crud-demo" style={{ marginTop: 0 }}>
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    <DataTable
                        ref={dt}
                        value={menus}
                        dataKey="id"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Affichage {first} du {last} au {totalRecords} éléments"
                        globalFilter={globalFilter}
                        emptyMessage="Aucun carte n'a été trouvé."
                        header={header}
                        responsiveLayout="scroll"
                    >
                        <Column header="Image" body={imgBodyTemplate} headerStyle={{ width: "20%", minWidth: "10rem" }}></Column>
                        <Column field="visibility" header="Visibility" sortable body={visibilityBodyTemplate} headerStyle={{ width: "20%", minWidth: "10rem" }}></Column>

                        <Column headerStyle={{ width: "20%", minWidth: "10rem" }} body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={dialog} style={{ width: "450px" }} header="Créer une carte" modal className="p-fluid" footer={dialogFooter} onHide={hideDialog}>
                        <div className="field">
                            <label htmlFor="file_src">Image</label>
                            <InputText 
                            autoFocus className={classNames({ "p-invalid": submitted && !file_src })}
                            type="file" id="file_src" name="file_src" onChange={handleUploadChange} required />
                            {submitted && !file_src && <small className="p-invalid">Champ Obligatoire.</small>}

                            <div className="mt-3" style={{ borderRadius: 5, height: "auto", border: "1px solid green", backgroundColor: "#B7E5B0", color: "black" }}>
                                <p className="pt-2 mx-3">
                                    <i className="pi pi-fw pi-file"></i> <b>A noter :</b> <br />
                                    - L'image est un champ obligatoire. <br />- Le nom de l'image doit être sous format <b>jpeg, png ou bien jpg</b>. <br />- Le nom de l'image ne doit pas contenir des éspaces.
                                </p>
                            </div>
                            <div align="center">
                                <div className="mt-3" align="center">
                                    <img height={140} width="auto" src={file_src} alt="menu-carte" />
                                </div>
                            </div>
                        </div>

                        <div className="field">
                            <label htmlFor="visibility">Visibilité</label>

                            <Dropdown 
                             autoFocus className={classNames({ "p-invalid mt-1": submitted && !visibility })}

                             name="type" value={visibility} onChange={(e) => setVisibility(e.target.value)} options={dropdownValues} optionValue="code" optionLabel="name" placeholder="Selectionner la Visibilité" />
                            {submitted && !visibility && <small className="p-invalid">Champ Obligatoire.</small>}

                        </div>
                    </Dialog>
                    <Dialog visible={deleteDialog} style={{ width: "450px" }} header="Confirm" modal footer={deleteDialogFooter_(menu)} onHide={hideDeleteDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                            {menu && <span>Êtes-vous sûr de vouloir supprimer cette carte : {menu.title} ?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps?.location?.pathname === nextProps?.location?.pathname;
};

export default React.memo(MenuCard, comparisonFn);
