import React, {createContext, useState,useRef} from "react";
import {LoginService} from "./service/userService/loginService";
import { useNavigate} from "react-router-dom";
import jwt_decode from "jwt-decode";
import axios from "axios";
import { Toast } from "primereact/toast";

const AuthContext = createContext();

export const AuthProvider = ({children}) => {
    const toast = useRef(null);

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const service = new LoginService();
    const navigate = useNavigate();

    const [fullname, setFullname] = useState("");
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    /* const reloadToken = () => {
            try {
                service.token().then((response) => {

                    setToken(response.accessToken);
                    const decoded = jwt_decode(response?.accessToken);
                    setFullname(decoded.fullname);
                    setExpire(decoded?.expire);

                  setIsLoggedIn(true)
                })
            } catch (error) {
                if (error.response) {
                    navigate("/");
                }
            }
        }*/
    const axiosJWT = axios.create();
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();

        if (expire * 1000 < currentDate.getTime()) {

            service.token().then((response) => {
                config.headers.Authorization = `Bearer ${response?.accessToken}`;
                setToken(response?.accessToken);
                const decoded = jwt_decode(response?.accessToken);
                setExpire(decoded.exp);

            })

        }
        return config;
    });


    const signIn = () => {

        try {
            service.token().then((response) => {

                setToken(response.accessToken);
                sessionStorage.setItem("refreshToken",response.accessToken);
                const decoded = jwt_decode(response?.accessToken);
                setFullname(decoded.fullname);
                console.log(fullname);
                console.log(token);
                setExpire(decoded?.expire);
                setIsLoggedIn(true)
                toast.current.show({ severity: 'success', summary: 'Vous êtes connecté.', life: 3000 });
                navigate("/dashboard")

            }).catch(()=>{
                toast.current.show({ severity: 'error', summary: 'Erreur de connection.', life: 3000 });
            })

        } catch (error) {
            if (error.response) {
                navigate("/");
               // toast.current.show({ severity: 'success', summary: 'Erreur de connection.', life: 3000 });

            }
        }
    };

    const signOut = () => {
        service.logout().then((res) => {
            navigate('/', {replace: true});

            setIsLoggedIn(false)
            sessionStorage.removeItem("refreshToken");

        })

    };

    const value = {
        isLoggedIn,
        signIn,
        signOut
    };

    return (
        <AuthContext.Provider value={value}>
            <Toast ref={toast}/>
            {children} 
            
        </AuthContext.Provider>
    );
};

export default AuthContext;
