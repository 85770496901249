import axios from 'axios';
import DEV from '../../url';
export class LiensUtilesService {

    create(data) {

        return  axios.post(`${DEV}links/create`,data).then((res)=>res.data);
    }
    all() {
  let token = sessionStorage.getItem('access');

        return  axios.get(`${DEV}links/all`,  {
  'Content-Type' : 'application/json',
  'Accept' : 'application/json',
  'Authorization' : 'Bearer '+token
}).then((res)=>res.data);
    }
    delete(id)
    {
        return axios.delete(`${DEV}links/delete/${id}`);
    }

    async get(id)
    {
        const res = await axios.get(`${DEV}links/get/${id}`);
        return res;
    }
    update(id,data) {
        return axios.put(`${DEV}links/update/`+id,data).then((res)=>res.data);
    }
}
