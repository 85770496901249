import axios from "axios";
import DEV from "../../url";
export class RoomsService{

    post(data){
        return axios.post(`${DEV}rooms/create`,data);
    }

    async all(){
        const res = await axios.get(`${DEV}rooms/all`);
        return res.data;
    }

    updatebyid(id,data){
        return axios.put(`${DEV}rooms/update/${id}`,data);
    }

    updateItem(id,data){
        return axios.put(`${DEV}rooms/updateImage/${id}`, data).then(res=> res.data);
    }

    getbyid(id){
        return axios.get(`${DEV}rooms/get/${id}`).then(res=>res.data);
    }

    deletebyid(id){
        return axios.delete(`${DEV}rooms/delete/${id}`);
    }


     assign(id,data){
        return axios.post(`${DEV}rooms/assign/${id}`, data);

    }

    getImages(id){
        return axios.get(`${DEV}rooms/images/`+id).then(res=>res.data);
    }


    deleteimagebyid(id){
        return axios.delete(`${DEV}rooms/deleteImageRoom/${id}`);
    }

}
