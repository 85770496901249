import React, {useEffect, useRef, useState} from 'react';
import classNames from 'classnames';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Toast} from 'primereact/toast';
import {Button} from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import {Toolbar} from 'primereact/toolbar';
import {Dialog} from 'primereact/dialog';
import {InputText} from 'primereact/inputtext';
import {ChannelService} from '../../service/channelService/ChannelService';
import DEV from '../../url';
const Channels = () => {

    let empty = {
        id: 0,
        title: '',
        logo: ''
    }

    const dropdownValues = [
        { name: 'Chaîne', code: 'ch' },
        { name: 'Radio', code: 'rad' },
        { name: 'Réseau Social', code: 'rs' },
    ];

    const service = new ChannelService();

    const [row, setRow] = useState(null);
    const [dialog, setDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [items, setItems] = useState([null]);
    const [deleteDialog, setDeleteDialog] = useState(false);

    const [title, setTitle] = useState("");
    const [logo, setLogo] = useState("");
    const [type_chaine, setType_chaine] = useState("");
    const [link, setLink] = useState("");


    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    const handleUploadChange = e => {
        if (e.target.files[0]) {
            setLogo(e.target.files[0]);
        }
    }


    const exportCSV = () => {
        dt.current.exportCSV();
    }

    useEffect(() => {
        service.all().then((res) => {
            toast.current.show({severity: 'success', summary: 'Opération Achevée', detail: 'Opération effectuée avec succés', life: 3000});
            setItems(res);
        });
    }, []);

    const hideDeleteDialog = () => {
        setDeleteDialog(false);
    }

    const delete_ = (e, event) => {
        e.preventDefault();
        setRow({...event});
        setDeleteDialog(true);
    }

    const confirmDelete = async (e, id) => {
        e.preventDefault();
        await service.delete(id).then((res) => {
            toast.current.show({severity: 'success', summary: 'Opération Achevée', detail: 'Cet item a été supprimé', life: 3000});
            setDeleteDialog(false);
            const trash = items.filter(() => res?.result !== id);
            setItems(trash);
            window.location.reload(false);
        }, []);

    }

    const openNew = () => {
        setRow(empty);
        setSubmitted(false);
        setDialog(true);
    }

    const save = () => {
        setSubmitted(true);
        const formData = new FormData()
        formData.append('title', title)
        formData.append('logo', logo)
        formData.append('type_chaine', type_chaine)
        formData.append('link', link)
        if(title===""||logo===""||type_chaine===""||link===""){
            toast.current.show({ severity: "error", summary: "Merci de bien compléter les champs", life: 3000 });
            setDialog(true);
        }else{
            service.create(formData, {}).then(() => {
            toast.current.show({severity: 'success', summary: 'Opération Achevée', detail: 'Lien Créé', life: 3000});
            setItems(items);

            window.location.reload(false);
            setDialog(false);
        });
        }


       

    }

    const hideDialog = () => {
        setSubmitted(false);
        setDialog(false);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Nouveau média" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew}/>
                </div>
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Exporter en CSV" icon="pi pi-upload" className="p-button-help" onClick={exportCSV}/>
            </React.Fragment>
        )
    }

    const titleBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Titre</span>
                <b> {rowData?.title}</b>
            </>
        );
    }

    const logoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Logo</span>
                <img height={70} width={"auto"} src={`${DEV}uploads/channels/${rowData?.logo}`} alt="channel-img"/>
            </>
        );
    }

    const type_chaineBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Type</span>
                <b> {rowData?.type_chaine}</b>
            </>
        );
    }

     const linkBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Lien web</span>
                <b> {rowData?.link}</b>
            </>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">

                <a href={`/#/channel/modifierChannel/` + rowData?.id}> <Button title='Modifier cet item' icon="pi pi-pencil" className="p-button-rounded p-button-success mr-1">
                </Button> </a> &nbsp;
                <a href={`/#/channel/modifierImage/` + rowData?.id}> <Button title='Modifier Image' icon="pi pi-upload" className="p-button-rounded p-button-warning mr-1">
                </Button> </a> &nbsp;

                <Button title='Supprimer cet item' icon="pi pi-trash" className="p-button-rounded p-button-danger mt-2 mr-1" onClick={(e) => delete_(e, rowData)}/>

            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Chaînes, Radios & Réseaux Sociaux</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search"/>
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Cherchez ..."/>
            </span>
        </div>
    );

    const dialogFooter = (
        <>
            <Button label="Annuler" icon="pi pi-times" className="p-button-text" onClick={hideDialog}/>
            <Button label="Sauvegarder" icon="pi pi-check" className="p-button-text" onClick={save}/>
        </>
    );

    const deleteDialogFooter_ = (rowData) => (
        <>
            <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={hideDeleteDialog}/>
            <Button label="Oui" icon="pi pi-check" className="p-button-text" onClick={(e) => confirmDelete(e, rowData.id)}/>
        </>
    );

    return (
        <div className="grid crud-demo" style={{marginTop: 0}}>
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast}/>
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>


                    <DataTable ref={dt} value={items}
                               dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                               paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                               currentPageReportTemplate="Affichage {first} du {last} au {totalRecords} éléments"
                               globalFilter={globalFilter} emptyMessage="Aucun item n'a été trouvé." header={header} responsiveLayout="scroll">

                        <Column field="title" header="Libellé" sortable body={titleBodyTemplate} headerStyle={{width: '10%', minWidth: '10rem'}}></Column>
                        <Column header="Logo" body={logoBodyTemplate} headerStyle={{width: '20%', minWidth: '10rem'}}></Column>
                        <Column field="type" header="Type" sortable body={type_chaineBodyTemplate} headerStyle={{width: '20%', minWidth: '10rem'}}></Column>
                        <Column field="link" header="Lien web" sortable body={linkBodyTemplate} headerStyle={{width: '0%', minWidth: '5rem'}}></Column>
                        <Column headerStyle={{width: '20%', minWidth: '10rem'}} body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={dialog} style={{width: '450px'}} header="Créer un réseau social" modal className="p-fluid" footer={dialogFooter} onHide={hideDialog}>
                        <div className="field">
                            <label htmlFor="title">Libellé</label>
                            <InputText id="title" value={title} onChange={(e) => setTitle(e.target.value)} required autoFocus 
                            className={classNames({'p-invalid': submitted && !title})}/>
                            {submitted && !title && <small className="p-invalid">Champ Obligatoire.</small>}
                        </div>
                        <div className="field" >
                            <label htmlFor="image">Logo</label>
                            <InputText
                                type="file"
                                id="logo"
                                name="logo"
                                onChange={handleUploadChange}
                                className={classNames({'p-invalid': submitted && !logo})}

                            />
                            {submitted && !logo && <small className="p-invalid">Champ Obligatoire.</small>}

                            <div className='mt-3' style={{borderRadius:5, height:"auto",border:'1px solid green',backgroundColor:"#B7E5B0",color:"black"}}>
                                                <p className='pt-2 mx-3'>   
                                                <i className="pi pi-fw pi-file"></i> <b>A noter :</b> <br/>
                                                - L'image est un champ obligatoire. <br/>
                                                - Le nom de l'image doit être sous format <b>jpeg, png ou bien jpg</b>. <br/>
                                                - Le nom de l'image ne doit pas contenir des éspaces.</p>
                                </div>
                            <div align="center">

                                <div className='mt-3' align="center">
                                    <img height={140} width="auto" src={logo} alt="img-par-défaut"/>
                                </div>
                            </div>

                        </div>
                        <div className="field">
                            <label className="mb-3">Type</label>


                            <Dropdown
                            className={classNames({'p-invalid': submitted && !type_chaine})}
                            name="type" value={type_chaine} onChange={(e) => setType_chaine(e.target.value)} options={dropdownValues} optionValue="name" optionLabel="name" placeholder="Selectionnez votre type" />
                            {submitted && !type_chaine && <small className="p-invalid">Champ Obligatoire.</small>}

                        </div>
                         <div className="field">
                            <label htmlFor="lien">Lien web</label>
                            <InputText id="link" value={link} onChange={(e) => setLink(e.target.value)} required autoFocus className={classNames({'p-invalid': submitted && !link})}/>
                            {submitted && !link && <small className="p-invalid">Champ Obligatoire.</small>}

                        </div>
                    </Dialog>
                    <Dialog visible={deleteDialog} style={{width: '450px'}} header="Confirm" modal footer={deleteDialogFooter_(row)} onHide={hideDeleteDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{fontSize: '2rem'}}/>
                            {row && <span>Êtes-vous sûr de vouloir supprimer cet élément : {row.title} ?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    )

}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps?.location?.pathname === nextProps?.location?.pathname;
};

export default React.memo(Channels, comparisonFn);
