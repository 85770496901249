import axios from "axios";
import DEV from "../../url";

export class ContactService {

    getAll(){
       return axios.get( `${DEV}contact/all`).then((res)=>res.data);
    }

    supprimer(id)
    {
        return axios.delete(`${DEV}contact/delete/`+id);
    }
}
