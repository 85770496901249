import React from 'react';

export const AppFooter = () => {

    const year =  new Date().getFullYear();

    return (
        <div  className="layout-footer mt-3">
            <p>
                Copyright © {year} Dar Zaghouan
                - Template by: <a href="https://www.ipactconsult.com/"> <b>IPACT Consult </b></a>
            </p>
        </div>
    );
}
