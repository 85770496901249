import axios from 'axios';
import DEV from '../../url';
export class VideoService {

    create(id,data){
        return axios.post(`${DEV}video/create/${id}`,data).then((res)=>res.data);
    }

    update(id,data){
        return axios.put(`${DEV}video/update/${id}`,data).then((res)=>res.data);
    }

    get(id){
        return axios.get(`${DEV}video/get/${id}`).then((res)=>res.data);
    }

    all(){
        return axios.get(`${DEV}video/all`).then((res)=>res.data);
    }

    delete(id){
        return axios.delete(`${DEV}video/delete/$`+id);
    }
}
