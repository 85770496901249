import React, {useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {SpaService} from "../../service/SpaService/SpaService";
import {Button} from "primereact/button";
import {Toast} from "primereact/toast";
import {Toolbar} from "primereact/toolbar";
import {InputText} from "primereact/inputtext";
import {InputTextarea} from "primereact/inputtextarea";

const ModifierSpa = () => {

    const history = useNavigate();
    const toast = useRef(null);

    const service = new SpaService();
    const {id} = useParams();

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");

    const modifier = async (e) => {
        e.preventDefault();
        if(title===""||description===""){
            toast.current.show({ severity: "error", summary: "Merci de bien compléter les champs", life: 3000 });
        }else{
                    service.update(id, {title: title, description: description}).then((res) => {
            toast.current.show({severity: 'success', summary: 'Opération Achevée', detail: 'Item Modifié', life: 3000});
        });
        }

    }

     const fetch = async () => {
        service.get(id).then((res) => {
            setTitle(res?.data?.title);
            setDescription(res?.data?.description);
        });
    }

     useEffect(() => {
        fetch();
    }, []);

     const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button onClick={(e) => modifier(e)} label="Sauvegarder" icon="pi pi-fw pi-check" className="p-button-success mr-2" />
            </React.Fragment>
        )
    }

    const goto = (e) => {
        e.preventDefault();
        history("/hammam_spa")
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button onClick={(e) => goto(e)} className="p-button-rounded p-button-outlined p-button-danger">
                    <i className="pi pi-times"></i>
                </Button>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <div className="grid" style={{marginTop:0}}>
                <Toast ref={toast} />
                <Toolbar className="mb-4 col-12" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                <div className="col-12 md:col-12 mt-4">
                    <div className="card p-fluid">
                        <h5>Modifier Cet Item</h5>
                        <form>
                            <div className="field">
                                <label htmlFor="title">Titre</label>
                                <InputText value={title || ''} onChange={(e) => setTitle(e.target.value)} id="sectionName" type="text" />
                            </div>

                            <div className="field">
                                <label className="mb-3">Description</label>
                                <InputTextarea id="description" value={description || ''} onChange={(e) => setDescription(e.target.value)} required rows={3} cols={20}/>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )

}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps?.location?.pathname === nextProps?.location?.pathname;
};

export default React.memo(ModifierSpa, comparisonFn);
