import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { Toolbar } from "primereact/toolbar";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState } from "draft-js";
import { convertToHTML } from "draft-convert";
import DOMPurify from 'dompurify';
import { PackService } from "../../service/packService/PackService";

const ModifierPack = () => {

    const history = useNavigate();
    const toast = useRef(null);

    const service_ = new PackService();
    const { id } = useParams();

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [img, setImg] = useState("");
    const [type, setType] = useState("");
    const [details, setDetails] = useState();
    const [rules, setRules] = useState([]);
    const [price, setPrice] = useState("");
    const [maxCapacity, setMaxCapacity] = useState(0);

    const dropdownValues = [
        {name: 'Loisirs', code: 'ls'},
        {name: 'Terroir', code: 'tr'},
        {name: 'Fermier Riche', code: 'fr'},
    ];

        const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty(),
    );
    const [convertedContent, setConvertedContent] = useState(null);

    const handleEditorChange = (state) => {
        setEditorState(state);
        convertContentToHTML();
    }

    const convertContentToHTML = () => {
        let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
        setConvertedContent(currentContentAsHTML);
    }
    const createMarkup = (html) => {
        return {
            __html: DOMPurify.sanitize(html)
        }
    }

    const modifier = async (e) => {
        e.preventDefault();
         const formData = new FormData()
        formData.append('title', title)
        formData.append('description', convertedContent)
        //formData.append('img', img)
         formData.append('type', type)
         formData.append('details', details)
         formData.append('rules', rules)
         formData.append('price',price)
         formData.append('maxCapacity', maxCapacity)

         if(title===""||type===""||details===""||rules===""){
            toast.current.show({ severity: "error", summary: "Merci de bien compléter les champs", life: 3000 });
         }else{
                    service_.updatebyid(id, formData,{  }).then(() => {
            toast.current.show({ severity: 'success', summary: 'Opération Achevée', detail: 'Pack Modifié', life: 3000 });
        });
         }

    }

    const get = async () => {
        await service_.getbyid(id).then((res) => {
            setTitle(res.title);
            setDescription(res.description);
            setImg(res.img);
            setType(res.type);
            setDetails(res.details);
            setRules(res.rules);
            setPrice(res.price);
            setMaxCapacity(res.maxCapacity);
        });
    }

    useEffect(() => {
        get();
    }, []);

    const handleUploadChange = e => {
        if (e.target.files[0]) {
            setImg(e.target.files[0]);
        }
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button onClick={(e) => modifier(e)} label="Sauvegarder" icon="pi pi-fw pi-check" className="p-button-success mr-2" />
            </React.Fragment>
        )
    }

    const backto = (e) => {
        e.preventDefault();
        history("/packs")
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button onClick={(e) => backto(e)} className="p-button-rounded p-button-outlined p-button-danger">
                    <i className="pi pi-times"></i>
                </Button>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <div className="grid" style={{marginTop:0}}>
                <Toast ref={toast} />
                <Toolbar className="mb-4 col-12" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                <div className="col-12 md:col-12 mt-4">
                    <div className="card p-fluid">
                        <h5>Modifier Ce Pack</h5>
                        <form>
                            <div className="field">
                                <label htmlFor="title">Titre</label>
                                <InputText value={title} onChange={(e) => setTitle(e.target.value)} id="title" type="text" />
                            </div>



                            <div className="field">
                                <label htmlFor="description">Description</label>
                               <Editor
                                    editorStyle={{height:140}}
                                    placeholder='mettre à jour votre contenu'
                                    editorState={editorState}
                                    onEditorStateChange={handleEditorChange}
                                    wrapperClassName="wrapper-class"
                                    editorClassName="editor-class"
                                    toolbarClassName="toolbar-class"
                                />
                                <hr className='mt-5' />

                                {
                                    description ?
                                        <div>
                                            <h3>Consulter la description à modifier</h3>

                                                <div  className='card' dangerouslySetInnerHTML={createMarkup(description)} />
                                        </div> : <></>
                                }
                            </div>

                            {/*<div className="field">
                                <label htmlFor="img">Image </label>
                                <InputText onChange={handleUploadChange} className="mt-3" id="img" type="file" />

                                <div align="center">

                                                <div align="center" className="mt-3">
                                                    <img src={img} alt="image activité" height={180} width="auto" />
                                                </div>
                                </div>


                            </div>*/}

                            <div className="field">
                                <label htmlFor="rules">Régles</label>
                                <InputTextarea id="rules" value={rules} onChange={(e) => setRules(e.target.value)} required rows={3} cols={20} />
                            </div>

                              <div className="field">
                            <label htmlFor="price">Prix</label>
                            <InputText id="price" value={price} onChange={(e) => setPrice(e.target.value)} required autoFocus />
                        </div>


                            <div className="field">
                                <label htmlFor="title">Capacité Moyenne</label>
                                <InputText type="text" pattern="[1-9][0-9]*" id="maxCapacity" value={maxCapacity} onChange={(e) => setMaxCapacity(e.target.value)} required autoFocus  />
                            </div>

                            <div className="field">
                                <label className="mb-3">Type</label>
                                <Dropdown name="type" value={type} onChange={(e) => setType(e.target.value)} options={dropdownValues} optionValue="name" optionLabel="name" placeholder="Selectionner Votre Type" />
                            </div>


                        </form>
                    </div>


                </div>

            </div>
        </React.Fragment>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps?.location?.pathname === nextProps?.location?.pathname;
};

export default React.memo(ModifierPack, comparisonFn);
