import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { storage } from '../../firebase/firebaseConfig';
import {VideoService} from "../../service/videoService/VideoService";
import Moment from "react-moment";

const Videos = () => {

    let empty = {
        id: 0,
        title: '',
        datePost: '',
        link : ''
    }

    const service = new VideoService();
    const [row, setRow] = useState(null);
    const [dialog, setDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [items, setItems] = useState([null]);
    const [deleteDialog, setDeleteDialog] = useState(false);

    const [title, setTitle] = useState("");
    const [datePost, setDatePost] = useState("");
    const [link, setLink] = useState("");


    const [file, setFile] = useState(null);
    const [url, setUrl] = useState('');
    const [progress, setProgress] = useState(0);
    const [waitMsg, setWaitMsg] = useState('');
    const [msgSuccess, setMsgSuccess] = useState('');
    const [copyMsg, setCopyMsg] = useState('');

    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    const handleUploadChange = e => {
        if (e.target.files[0]) {
            setFile(e.target.files[0]);
        }
    }

    const uploadImage = (e) => {

        setWaitMsg("Please wait while the file is uploaded !" + waitMsg);
        e.preventDefault();
        const uploadTask = storage.ref(`files/${file.name}`).put(file);
        uploadTask.on(
            "state_changed",
            snapshot => {
                const progress = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
                setProgress(progress);
            },
            error => {
                console.log(error);
            },
            () => {
                storage
                    .ref("files")
                    .child(file.name)
                    .getDownloadURL()
                    .then(url => {
                        setUrl(url);
                        setMsgSuccess("File Uploaded Successfully !" + msgSuccess);
                        setWaitMsg("");
                        setCopyMsg("Copy the URL within this input field" + copyMsg)
                    })
            }
        )

    }


    const exportCSV = () => {
        dt.current.exportCSV();
    }

        useEffect(() => {
        service.all().then((res) => {
            toast.current.show({ severity: 'success', summary: 'Opération Achevée', detail: 'Opération effectuée avec succés', life: 3000 });
            setItems(res);
        });
    }, []);

    const hideDeleteDialog = () => {
        setDeleteDialog(false);
    }

    const delete_ = (e, event) => {
        e.preventDefault();
        setRow({ ...event });
        setDeleteDialog(true);
    }

    const confirmDelete = async (e, id) => {
        e.preventDefault();
        await service.delete(id).then((res) => {
            toast.current.show({ severity: 'success', summary: 'Opération Achevée', detail: 'Cet item a été supprimé', life: 3000 });
            setDeleteDialog(false);
            const trash = items.filter(() => res?.result !== id);
            setItems(trash);
            window.location.reload(false);
        }, []);

    }

     const openNew = () => {
        setRow(empty);
        setSubmitted(false);
        setDialog(true);
    }

    const save = () => {
        setSubmitted(true);
        service.createVideo({ title,datePost, link: url }).then(() => {
            toast.current.show({ severity: 'success', summary: 'Opération Achevée', detail: 'Lien Créé', life: 3000 });
            setItems(items);

            window.location.reload(false);
        });

        setDialog(false);

    }

        const hideDialog = () => {
        setSubmitted(false);
        setDialog(false);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Nouveau" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                </div>
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        )
    }

    const titleBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Titre</span>
                <b> {rowData?.title}</b>
            </>
        );
    }

    const datePostBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Date Post</span>

                <Moment format={"YYYY MMM dd"}>
                    <b> {rowData?.datePost}</b>
                </Moment>

            </>
        );
    }

    const linkBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Vidéo</span>
                    <iframe title='vidéo' src={rowData?.link}></iframe>
            </>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">

                <a href={`/#/video/modifier/` + rowData?.id}>    <Button title='Modifier cet item' icon="pi pi-pencil" className="p-button-rounded p-button-success mr-1">
                </Button> </a> &nbsp;

                <Button title='Supprimer cet item' icon="pi pi-trash" className="p-button-rounded p-button-danger mt-2 mr-1" onClick={(e) => delete_(e, rowData)} />

            </div>
        );
    }

     const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Vidéos</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const dialogFooter = (
        <>
            <Button label="Annuler" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Sauvegarder" icon="pi pi-check" className="p-button-text" onClick={save} />
        </>
    );

    const deleteDialogFooter_ = (rowData) => (
        <>
            <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={hideDeleteDialog} />
            <Button label="Oui" icon="pi pi-check" className="p-button-text" onClick={(e) => confirmDelete(e, rowData.id)} />
        </>
    );

     return (
        <div className="grid crud-demo" style={{marginTop:0}}>
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>


                    <DataTable ref={dt} value={items}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Affichage {first} du {last} au {totalRecords} activités"
                        globalFilter={globalFilter} emptyMessage="Aucun item n'a été trouvé." header={header} responsiveLayout="scroll">

                        <Column field="title" header="Titre" sortable body={titleBodyTemplate} headerStyle={{ width: '20%', minWidth: '10rem' }}></Column>
                        <Column field="datePost" header="Date de Création" sortable body={datePostBodyTemplate} headerStyle={{ width: '20%', minWidth: '10rem' }}></Column>
                        <Column header="Vidéo" body={linkBodyTemplate} headerStyle={{ width: '20%', minWidth: '10rem' }}></Column>
                        <Column headerStyle={{ width: '20%', minWidth: '10rem' }} body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={dialog} style={{ width: '450px' }} header="Créer un Lien" modal className="p-fluid" footer={dialogFooter} onHide={hideDialog}>
                        <div className="field">
                            <label htmlFor="title">Titre</label>
                            <InputText id="title" value={title} onChange={(e) => setTitle(e.target.value)} required autoFocus className={classNames({ 'p-invalid': submitted && !title })} />
                            {submitted && !title && <small className="p-invalid">Champ Obligatoire.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="title">Date de création</label>
                            <InputText id="datePost" value={datePost} type={"date"} onChange={(e) => setDatePost(e.target.value)} required autoFocus className={classNames({ 'p-invalid': submitted && !datePost })} />
                            {submitted && !datePost && <small className="p-invalid">Champ Obligatoire.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="image">Vidéo</label>
                            <InputText
                                type="file"
                                id="link"
                                name="link"
                                onChange={handleUploadChange}
                                required
                            />
                            <Button className='mt-3' onClick={uploadImage}>Charger la vidéo</Button>
                            <div align="center">
                                Chargement de la progression : {progress}% <progress className='mt-3' value={progress} max="100" />
                           
                            </div>


                            <InputText
                                hidden={true}
                                placeholder="after you upload the image, paste the url here"
                                id="urlimage"
                                type="text"
                                name="file"
                                value={url}
                                onChange={(e) => setLink(url)}
                            />



                        </div>
                    </Dialog>
                    <Dialog visible={deleteDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteDialogFooter_(row)} onHide={hideDeleteDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {row && <span>Êtes-vous sûr de vouloir supprimer cet item : {row.title} ?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    )


}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps?.location?.pathname === nextProps?.location?.pathname;
};

export default React.memo(Videos, comparisonFn);
