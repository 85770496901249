import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import React, { useRef, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { LabellisationService } from "../../service/labellisationService/LabellisationService";

const ModifierLabellisation = () => {
    const history = useNavigate();
    const toast = useRef(null);

    const service = new LabellisationService();
    const { id } = useParams();
    const [title, setTitle] = useState("");
    const [subtitle, setSubtitle] = useState("");
    const [text, setText] = useState("");

    const get = async () => {
        service.getbyid(id).then((res) => {
            setTitle(res?.data?.title);
            setSubtitle(res?.data?.subtitle);
            setText(res?.data?.text);
        });
    };

    useEffect(() => {
        get();
    }, []);

    const modifier = async (e) => {
        e.preventDefault();
        if (title === "" || subtitle === "" || text === "") {
            toast.current.show({ severity: "error", summary: "Merci de bien compléter les champs", life: 3000 });
        } else {
            service.updatebyid(id, { title: title, subtitle: subtitle, text: text }).then(() => {
                toast.current.show({ severity: "success", summary: "Opération Achevée", detail: "Labellisation Modifiée", life: 3000 });
            });
        }
    };

    const gotosections = (e) => {
        e.preventDefault();
        history("/labellisation");
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button onClick={(e) => modifier(e)} label="Sauvegarder" icon="pi pi-fw pi-check" className="p-button-success mr-2" />
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button onClick={(e) => gotosections(e)} className="p-button-rounded p-button-outlined p-button-danger">
                    <i className="pi pi-times"></i>
                </Button>
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            <div className="grid" style={{ marginTop: 0 }}>
                <Toast ref={toast} />
                <Toolbar className="mb-4 col-12" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                <div className="col-12 md:col-12 mt-4">
                    <div className="card p-fluid">
                        <h5>Modifier Labellisation</h5>
                        <form>
                            <div className="field">
                                <label htmlFor="title">Titre</label>
                                <InputText value={title || ""} onChange={(e) => setTitle(e.target.value)} type="text" name="title" id="title" />
                            </div>
                            <div className="field">
                                <label htmlFor="subtitle">Sous Titre</label>
                                <InputText value={subtitle || ""} onChange={(e) => setSubtitle(e.target.value)} type="text" name="subtitle" id="subtitle" />
                            </div>
                            <div className="field">
                                <label htmlFor="text">Text</label>
                                <InputTextarea value={text || ""} id="text" onChange={(e) => setText(e.target.value)} required rows={3} cols={20} />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps?.location?.pathname === nextProps?.location?.pathname;
};

export default React.memo(ModifierLabellisation, comparisonFn);
