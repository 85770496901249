import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { InputTextarea } from "primereact/inputtextarea";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { ProductService } from "../../service/productService/ProductService";
import { Dropdown } from "primereact/dropdown";
import DEV from "../../url";
const Produits = () => {
    let empty = {
        id: 0,
        title: "",
        description: "",
        image: "",
        type: "",
    };

    const service_ = new ProductService();
    const [product, setProduct] = useState(null);
    const [dialog, setDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [products, setProducts] = useState([]);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [img, setImg] = useState("");
    const [type, setType] = useState("");
    const [price, setPrice] = useState("");

    const dropdownValues = [
        { name: "Fromages & Létiers", code: "frlt" },
        { name: "Kaak Warka", code: "kw" },
        { name: "Olive & Huile Olive", code: "oh" },
        { name: "Tabouna", code: "tb" },
        { name: "Bonbons & Halkoum", code: "bh" },
        { name: "Plantes médicinales", code: "pl" },
    ];

    useEffect(() => {
        service_.all().then((res) => {
            toast.current.show({ severity: "success", summary: "Opération Achevée", detail: "Opération effectuée avec succés", life: 3000 });
            setProducts(res);
        });
    }, []);

    const hideDeleteDialog = () => {
        setDeleteDialog(false);
    };

    const delete_ = (e, event) => {
        e.preventDefault();
        setProduct({ ...event });
        setDeleteDialog(true);
    };

    const confirmDelete = async (e, id) => {
        e.preventDefault();
        await service_
            .deletebyid(id)
            .then((res) => {
                toast.current.show({ severity: "success", summary: "Opération Achevée", detail: "Ce produit a été supprimé", life: 3000 });
                setDeleteDialog(false);
                const trash = products.filter(() => res?.result !== id);
                setProducts(trash);
                window.location.reload(false);
            }, [])
            .catch(() => {
                toast.current.show({ severity: "error", summary: "Opération échouée", detail: "Le produit que vous venez de le supprimer, contient d'autres images. Merci de les supprimer avant.", life: 6000 });
            });
    };

    const openNew = () => {
        setProduct(empty);
        setSubmitted(false);
        setDialog(true);
    };

    const save = () => {
        setSubmitted(true);
        const formData = new FormData();
        formData.append("title", title);
        formData.append("description", description);
        formData.append("img", img);
        formData.append("type", type);
        formData.append("price", price);

        if (title === "" || img === "" || type === "") {
            toast.current.show({ severity: "error", summary: "Merci de bien compléter les champs", life: 3000 });
            setDialog(true);
        } else {
            service_.post(formData, {}).then(() => {
                toast.current.show({ severity: "success", summary: "Opération Achevée", detail: "Produit Créé", life: 3000 });
                setProducts(products);

                window.location.reload(false);
                setDialog(false);
            });
        }
    };

    const hideDialog = () => {
        setSubmitted(false);
        setDialog(false);
    };

    const handleUploadChange = (e) => {
        if (e.target.files[0]) {
            setImg(e.target.files[0]);
        }
    };

    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    const exportCSV = () => {
        dt.current.exportCSV();
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Nouveau produit" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                </div>
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Exporter en CSV" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        );
    };

    const titleBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Titre</span>
                <b> {rowData.title}</b>
            </>
        );
    };

    const imgBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Image</span>
                <img alt={"produit"} src={`${DEV}uploads/products/${rowData?.img}`} height={140} width={250} />
            </>
        );
    };

    const typeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Type</span>
                {rowData.type}
            </>
        );
    };

    const priceBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Prix</span>
                {rowData.price == undefined ? (
                    <span></span>
                ) : (
                    <span>
                        {rowData.price} {"DT"}
                    </span>
                )}
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <a href={`/#/produit/affecterimages/` + rowData.id}>
                    <Button title="Ajouter autres images" icon="pi pi-images" className="p-button-rounded p-button-info mt-2 mr-1" />
                </a>
                <a href={`/#/produit/modifier/` + rowData.id}>
                    {" "}
                    <Button title="Modifier ce produit" icon="pi pi-pencil" className="p-button-rounded p-button-success mr-1"></Button>{" "}
                </a>
                <a href={`/#/produit/modifierImage/` + rowData.id}>
                    {" "}
                    <Button title="Modifier Image" icon="pi pi-upload" className="p-button-rounded p-button-warning mr-1"></Button>{" "}
                </a>

                <Button title="Supprimer ce produit" icon="pi pi-trash" className="p-button-rounded p-button-danger mt-2 mr-1" onClick={(e) => delete_(e, rowData)} />
            </div>
        );
    };

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Produits</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Cherchez ..." />
            </span>
        </div>
    );

    const dialogFooter = (
        <>
            <Button label="Annuler" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Sauvegarder" icon="pi pi-check" className="p-button-text" onClick={save} />
        </>
    );

    const deleteDialogFooter_ = (rowData) => (
        <>
            <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={hideDeleteDialog} />
            <Button label="Oui" icon="pi pi-check" className="p-button-text" onClick={(e) => confirmDelete(e, rowData.id)} />
        </>
    );

    return (
        <div className="grid crud-demo" style={{ marginTop: 0 }}>
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    <DataTable
                        ref={dt}
                        value={products}
                        dataKey="id"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Affichage {first} du {last} au {totalRecords} éléments"
                        globalFilter={globalFilter}
                        emptyMessage="Aucun produit n'a été trouvé."
                        header={header}
                        responsiveLayout="scroll"
                    >
                        <Column field="title" header="Titre" sortable body={titleBodyTemplate} headerStyle={{ width: "20%", minWidth: "10rem" }}></Column>
                        <Column header="Image" body={imgBodyTemplate} headerStyle={{ width: "20%", minWidth: "10rem" }}></Column>
                        <Column field="type" header="Type" body={typeBodyTemplate} sortable headerStyle={{ width: "20%", minWidth: "8rem" }}></Column>
                        <Column field="price" header="Prix" body={priceBodyTemplate} sortable headerStyle={{ width: "20%", minWidth: "8rem" }}></Column>

                        <Column headerStyle={{ width: "20%", minWidth: "10rem" }} body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={dialog} style={{ width: "450px" }} header="Créer un produit" modal className="p-fluid" footer={dialogFooter} onHide={hideDialog}>
                        <div className="field">
                            <label htmlFor="title">Titre</label>
                            <InputText id="title" value={title} onChange={(e) => setTitle(e.target.value)} required 
                            autoFocus className={classNames({ "p-invalid": submitted && !title })} />
                            {submitted && !title && <small className="p-invalid">Champ Obligatoire.</small>}
                        </div>

                        <div className="field">
                            <label htmlFor="description">Description</label>
                            <InputTextarea id="description" value={description} onChange={(e) => setDescription(e.target.value)} required rows={3} cols={20} />
                        </div>

                        <div className="field">
                            <label htmlFor="image">Image</label>
                            <InputText
                             autoFocus className={classNames({ "p-invalid": submitted && !img })}
                            type="file" id="img" name="img" onChange={handleUploadChange} required />
                            {submitted && !img && <small className="p-invalid">Champ Obligatoire.</small>}

                            <div className="mt-3" style={{ borderRadius: 5, height: "auto", border: "1px solid green", backgroundColor: "#B7E5B0", color: "black" }}>
                                <p className="pt-2 mx-3">
                                    <i className="pi pi-fw pi-file"></i> <b>A noter :</b> <br />
                                    - L'image est un champ obligatoire. <br />- Le nom de l'image doit être sous format <b>jpeg, png ou bien jpg</b>. <br />- Le nom de l'image ne doit pas contenir des éspaces.
                                </p>
                            </div>
                            <div align="center">
                                <div className="mt-3" align="center">
                                    <img height={140} width="auto" src={img} alt="produit" />
                                </div>
                            </div>
                        </div>

                        <div className="field">
                            <label className="mb-3">Type</label>

                            <Dropdown
                            autoFocus className={classNames({ "p-invalid": submitted && !type })}
                            name="type" value={type} onChange={(e) => setType(e.target.value)} options={dropdownValues} optionValue="name" optionLabel="name" placeholder="Selectionner Votre Type" />
                            {submitted && !type && <small className="p-invalid">Champ Obligatoire.</small>}

                        </div>

                        <div className="field">
                            <label htmlFor="price">Prix</label>
                            <InputText type="text" pattern="[0-9]+(\\.[0-9][0-9]?)?" id="price" value={price} onChange={(e) => setPrice(e.target.value)} required />
                        </div>
                    </Dialog>
                    <Dialog visible={deleteDialog} style={{ width: "450px" }} header="Confirm" modal footer={deleteDialogFooter_(product)} onHide={hideDeleteDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                            {product && <span>Êtes-vous sûr de vouloir supprimer ce produit : {product.title} ?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps?.location?.pathname === nextProps?.location?.pathname;
};

export default React.memo(Produits, comparisonFn);
