import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import React, { useRef, useState, useEffect } from "react";
import { LabellisationService } from "../../service/labellisationService/LabellisationService";
import classNames from "classnames";
const Labellisation = () => {

    
    const toast = useRef(null);
    const [submitted,setSubmitted]=useState(false);

    const service = new LabellisationService();
    const [title, setTitle] = useState("");
    const [subtitle, setSubtitle] = useState("");
    const [text, setText] = useState("");

    const [items, setItems] = useState([]);

    const postContent = async (e) => {
        e.preventDefault();
        setSubmitted(true);
        
        if(title === "" || subtitle === "" || text === ""){
            toast.current.show({ severity: "error", summary: "Merci de bien compléter les champs", life: 3000 });  
        }else{
            service.create({title,subtitle,text}).then(() => {
                toast.current.show({ severity: "success", summary: "Opération effectuée avec succées", life: 3000 });
                window.location.reload(false);
            });
        }
        
    };

    const getItems = async () => {
        service.getAll().then((res) => {
            toast.current.show({ severity: "success", summary: "Opération effectuée avec succées", life: 3000 });
            setItems(res);
        });
    };

    const deleteSection = (e, whoarewe) => {
        e.preventDefault();
        setLabelisation({ ...whoarewe });
        setDeleteSectionDialog(true);
    };

    const [deleteSectionDialog, setDeleteSectionDialog] = useState(false);
    const [labelisation, setLabelisation] = useState(null);
    const hideDeleteSectionDialog = () => {
        setDeleteSectionDialog(false);
    };
    const confirmDeleteSection = async (e, id) => {
        e.preventDefault();
        await service
            .deletebyid(id)
            .then((res) => {
                toast.current.show({ severity: "success", summary: "Opération Achevée", detail: "Cette labellisation a été supprimée", life: 3000 });
                setDeleteSectionDialog(false);
                const trash = items.filter(() => res?.result !== id);
                setItems(trash);
                window.location.reload(false);
            }, [])
            .catch(() => {});
    };

    useEffect(() => {
        getItems();
    }, []);

   

    const [globalFilter, setGlobalFilter] = useState(null);

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button onClick={(e) => postContent(e)} label="Sauvegarder" icon="pi pi-fw pi-check" className="p-button-success mr-2" />
            </React.Fragment>
        );
    };

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Labellisation</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Cherchez..." />
            </span>
        </div>
    );

    const deleteSectionDialogFooter_ = (rowData) => (
        <>
            <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={hideDeleteSectionDialog} />
            <Button label="Oui" icon="pi pi-check" className="p-button-text" onClick={(e) => confirmDeleteSection(e, rowData.id)} />
        </>
    );

    const dt = useRef(null);

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <a href={`/#/labellisation-modifier/${rowData.id}`}>
                    {" "}
                    <Button title="Modifier cet item" icon="pi pi-pencil" className="p-button-rounded p-button-success mr-1"></Button>{" "}
                </a>

                <Button title="Supprimer cet item" icon="pi pi-trash" className="p-button-rounded p-button-danger mt-2 mr-1" onClick={(e) => deleteSection(e, rowData)} />
            </div>
        );
    };

    const titleBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Titre</span>
                <b> {rowData.title}</b>
            </>
        );
    };

    const subtitleBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Sous titre</span>
                <b> {rowData.subtitle}</b>
            </>
        );
    };

    const textBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Texte</span>
                <b> {rowData.text}</b>
            </>
        );
    };

  

    return (
        <React.Fragment>
            <div className="grid" style={{ marginTop: 0 }}>
                <Toast ref={toast} />
                <Toolbar className="mb-4 col-12" left={leftToolbarTemplate}></Toolbar>
                <div className="col-12 md:col-12 mt-4">
                    <div className="card p-fluid">
                        <h5>Ajouter une Labellisation</h5>
                        <form >
                            <div className="field">
                                <label htmlFor="title">Titre</label>
                                <InputText 
                                autoFocus className={classNames({ "p-invalid": submitted && !title })}
                                onChange={(e) => setTitle(e.target.value)} type="text" name="title" id="title" value={title} />
                                {submitted && !title && <small className="p-invalid">Champ Obligatoire.</small>}

                            </div>
                            <div className="field">
                                <label htmlFor="subtitle">Sous Titre</label>
                                <InputText 
                                autoFocus className={classNames({ "p-invalid": submitted && !subtitle })}
                                onChange={(e) => setSubtitle(e.target.value)} type="text" name="subtitle" id="subtitle" value={subtitle} />
                                {submitted && !subtitle && <small className="p-invalid">Champ Obligatoire.</small>}

                            </div>
                            <div className="field">
                                <label htmlFor="text">Text</label>
                                <InputTextarea 
                                autoFocus className={classNames({ "p-invalid": submitted && !text })}
                                id="text" value={text} onChange={(e) => setText(e.target.value)} required rows={3} cols={20} />
                            
                            {submitted && !text && <small className="p-invalid">Champ Obligatoire.</small>}

                            </div>
                            
                        </form>
                    </div>
                </div>
            </div>
            <div className="grid">
                <Toast ref={toast} />


                <DataTable
                    ref={dt}
                    value={items}
                    dataKey="id"
                    paginator
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25]}
                    className="datatable-responsive"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Affichage {first} du {last} au {totalRecords} éléments"
                    globalFilter={globalFilter}
                    emptyMessage="Aucun élément n'a été trouvé."
                    header={header}
                    responsiveLayout="scroll"
                >
                    <Column field="titre" header="Titre" sortable body={titleBodyTemplate} headerStyle={{ width: "20%", minWidth: "10rem" }}></Column>
                    <Column field="subtitle" header="Sous titre" sortable body={subtitleBodyTemplate} headerStyle={{ width: "20%", minWidth: "10rem" }}></Column>
                    <Column field="text" header="Texte" sortable body={textBodyTemplate} headerStyle={{ width: "100%", minWidth: "10rem" }}></Column>

                    <Column headerStyle={{ width: "20%", minWidth: "10rem" }} body={actionBodyTemplate}></Column>
                </DataTable>

                <Dialog visible={deleteSectionDialog} style={{ width: "450px" }} header="Confirm" modal footer={deleteSectionDialogFooter_(labelisation)} onHide={hideDeleteSectionDialog}>
                    <div className="flex align-items-center justify-content-center">
                        <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                        {labelisation && <span>Êtes-vous sûr de vouloir supprimer cet élément : {labelisation.title} ?</span>}
                    </div>
                </Dialog>
            </div>
        </React.Fragment>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps?.location?.pathname === nextProps?.location?.pathname;
};

export default React.memo(Labellisation, comparisonFn);
