import axios from 'axios';
import DEV from '../../../url';
export class ContactInfoService {

    getContactInfos()
    {
        return axios.get(`${DEV}contactInfo/all`).then((data)=>data.data);
    }

    deleteContactInfo(id){
        return axios.delete(`${DEV}contactInfo/delete/${id}`).then(res=> res.data);
    }

    deleteContactInfos(){
        return axios.delete(`${DEV}contactInfo/deleteAll`).then(res=> res.data);
    }

    createContactInfo(data){
        return axios.post(`${DEV}contactInfo/create`,data).then(res=> res.data);
    }

    getContactInfo(id)
    {
        const res = axios.get(`${DEV}contactInfo/get/${id}`);
        return res;
    }

    updateContactInfo(id,data)
    {
        return axios.put(`${DEV}contactInfo/update/${id}`,data).then(res=>res.data);
    }
}
