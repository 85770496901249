/* eslint-disable jsx-a11y/iframe-has-title */
import { Button } from "primereact/button";
import { Toast } from 'primereact/toast';
import { Toolbar } from "primereact/toolbar";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import {ChannelService} from "../../service/channelService/ChannelService";
import {storage} from "../../firebase/firebaseConfig";


const ModifierVidéo = () => {

         const history = useNavigate();
    const toast = useRef(null);

    const { id } = useParams();
    const service = new ChannelService();

    const [title, setTitle] = useState("");
    const [datePost, setDatePost] = useState("");
    const [link, setLink] = useState("");


        const [file, setFile] = useState(null);
    const [url, setUrl] = useState('');
    const [progress, setProgress] = useState(0);
    const [waitMsg, setWaitMsg] = useState('');
    const [msgSuccess, setMsgSuccess] = useState('');
    const [copyMsg, setCopyMsg] = useState('');


    const handleUploadChange = e => {
        if (e.target.files[0]) {
            setFile(e.target.files[0]);
        }
    }

    const uploadImage = (e) => {

        setWaitMsg("Please wait while the file is uploaded !" + waitMsg);
        e.preventDefault();
        const uploadTask = storage.ref(`files/${file.name}`).put(file);
        uploadTask.on(
            "state_changed",
            snapshot => {
                const progress = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
                setProgress(progress);
            },
            error => {
                console.log(error);
            },
            () => {
                storage
                    .ref("files")
                    .child(file.name)
                    .getDownloadURL()
                    .then(url => {
                        setUrl(url);
                        setMsgSuccess("File Uploaded Successfully !" + msgSuccess);
                        setWaitMsg("");
                        setCopyMsg("Copy the URL within this input field" + copyMsg)
                    })
            }
        )

    }

    const modifier = async (e) => {
        e.preventDefault();
        service.update(id, { title: title, datePost:datePost, link: url }).then((res) => {
            toast.current.show({ severity: 'success', summary: 'Opération Achevée', detail: 'Lien Modifié', life: 3000 });
        });
    }

    const fetch = async () => {
        service.get(id).then((res) => {
            setTitle(res?.title);
            setDatePost(res?.datePost);
            setUrl(res?.link);
        });
    }

    useEffect(() => {
        fetch();
    }, []);


    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button onClick={(e) => modifier(e)} label="Sauvegarder" icon="pi pi-fw pi-check" className="p-button-success mr-2" />
            </React.Fragment>
        )
    }

    const goto = (e) => {
        e.preventDefault();
        history("/videos")
    }

     const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button onClick={(e) => goto(e)} className="p-button-rounded p-button-outlined p-button-danger">
                    <i className="pi pi-times"></i>
                </Button>
            </React.Fragment>
        )
    }

      return (
        <React.Fragment>
            <div className="grid" style={{marginTop:0}}>
                <Toast ref={toast} />
                <Toolbar className="mb-4 col-12" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                <div className="col-12 md:col-12 mt-4">
                    <div className="card p-fluid">
                        <h5>Modifier Cette vidéo</h5>
                        <form>
                            <div className="field">
                                <label htmlFor="title">Titre</label>
                                <InputText value={title || ''} onChange={(e) => setTitle(e.target.value)} id="sectionName" type="text" />
                            </div>
                            <div className="field">
                                <label htmlFor="title">Date</label>
                                <InputText value={datePost || ''} onChange={(e) => setDatePost(e.target.value)} id="sectionName" type="date" />
                            </div>

                        <div className="field">
                                <label htmlFor="link">Vidéo</label>
                                <InputText type="file" onChange={handleUploadChange} accept="*"/>
                                <Button className='mt-3' onClick={uploadImage}>Charger la vidéo</Button>
                                <div align="center">
                                    Chargement de la progression : {progress}% <progress className='mt-3' value={progress} max="100"/>
                                    {
                                        url !== '' ?
                                            <React.Fragment>
                                                <div align="center">
                                                    <iframe src={url}></iframe>
                                                </div>
                                            </React.Fragment> : <React.Fragment>
                                            </React.Fragment>
                                    }
                                </div>
                        </div>
                        </form>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )

}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps?.location?.pathname === nextProps?.location?.pathname;
};

export default React.memo(ModifierVidéo, comparisonFn);
