import React, { useState, useEffect, useRef } from 'react';

import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { TermsService } from '../../service/TermsService/TermsService';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState } from "draft-js";
import { convertToHTML } from "draft-convert";
import DOMPurify from 'dompurify';
import { useNavigate, useParams } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
const ModifierTerms = () => {

    const history = useNavigate();
    const toast = useRef(null);

    const { id } = useParams();

    const service = new TermsService();
    const [description, setDescription] = useState("");
    const [title,setTitle]=useState("");
    const [copied, setCopied] = useState(false);


    const modifier = async (e) => {
        e.preventDefault();
        if(title===""){
            toast.current.show({ severity: "error", summary: "Merci de bien compléter les champs", life: 3000 });
        }else{
             service.update(id, {title:title,description: convertedContent }).then((res) => {
            toast.current.show({ severity: 'success', summary: 'Opération Achevée', detail: 'Contenu Modifié', life: 3000 });
        });
        }
       
    }

    const fetch = async () => {
        service.get(id).then((res) => {
            setDescription(res?.description);
            setTitle(res?.title);
        });
    }

    useEffect(() => {
        fetch();
    }, []);



    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty(),
    );
    const [convertedContent, setConvertedContent] = useState(null);

    const handleEditorChange = (state) => {
        setEditorState(state);
        convertContentToHTML();
    }

    const convertContentToHTML = () => {
        let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
        setConvertedContent(currentContentAsHTML);
    }
    const createMarkup = (html) => {
        return {
            __html: DOMPurify.sanitize(html)
        }
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button onClick={(e) => modifier(e)} label="Sauvegarder" icon="pi pi-fw pi-check" className="p-button-success mr-2" />
            </React.Fragment>
        )
    }

    const goto = (e) => {
        e.preventDefault();
        history("/terms")
    }


    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button onClick={(e) => goto(e)} className="p-button-rounded p-button-outlined p-button-danger">
                    <i className="pi pi-times"></i>
                </Button>
            </React.Fragment>
        )
    }


    return (
        <React.Fragment>
            <div className="grid" style={{marginTop:0}}>
                <Toast ref={toast} />
                <Toolbar className="mb-4 col-12" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                <div className="col-12 md:col-12 mt-4">
                    <div className="card p-fluid">
                        <h5>Mettre à jour ce Contenu</h5>
                        <form>
                            <div className='field'>
                                <label htmlFor="">Titre</label>
                                <InputText value={title || ''} onChange={(e) => setTitle(e.target.value)} id="title" type="text" />

                            </div>
                            <div className="field">
                                <Editor
                                    editorStyle={{height:140}}
                                    placeholder='mettre à jour votre contenu'
                                    editorState={ editorState} 
                                    onEditorStateChange={handleEditorChange}
                                    wrapperClassName="wrapper-class"
                                    editorClassName="editor-class"
                                    toolbarClassName="toolbar-class"
                                />
                                <hr className='mt-5' />

                                {
                                    description ?
                                        <div>
                                            <h3>Consulter le contenu à modifier</h3>

                                                <div  className='card' dangerouslySetInnerHTML={createMarkup(description)} />
                                        </div> : <></>
                                }
                            </div>


                        </form>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )


}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps?.location?.pathname === nextProps?.location?.pathname;
};

export default React.memo(ModifierTerms, comparisonFn);
