import axios from 'axios';
import DEV from '../../../url';
export class BannerService {

    getBanners()
    {
        return axios.get(`${DEV}banner/all`).then((data)=>data.data);
    }

    deleteBanner(id){
        return axios.delete(`${DEV}banner/delete/`+id).then(res=> res.data);
    }

    deleteBanners(id){
        return axios.delete(`${DEV}banner/deleteAll`).then(res=> res.data);
    }

    createBanner(data){
        return axios.post(`${DEV}banner/create`,data).then(res=> res.data);
    }

    getBanner(id)
    {
        return axios.get(`${DEV}banner/get/${id}`).then((res)=>res.data);
    }

    updateBanner(id,data)
    {
        return axios.put(`${DEV}banner/update/`+id,data).then(res=>res.data);
    }

    updateItem(id,data){
        return axios.put(`${DEV}banner/updateImage/${id}`, data).then(res=> res.data);
    }
}
