import React from 'react';
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'prismjs/themes/prism-coy.css';
import './assets/demo/flags/flags.css';
import './assets/demo/Demos.scss';
import './assets/layout/layout.scss';
import 'react-clock/dist/Clock.css';

import './App.scss';
import {HashRouter} from 'react-router-dom'
import Routes from './Routers';
import {AuthProvider} from "./AuthContext";
const App = () => {
    return (
        <HashRouter>
 <AuthProvider>
    <Routes/>
           </AuthProvider>
    </HashRouter>
    );

}

export default App;
