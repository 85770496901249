import axios from 'axios';
import DEV from '../../url';

export class ActivityService {


    getRecent()
    {
        return axios.get(`${DEV}activity/recent`);
    }

    getActivities() {
        return axios.get(`${DEV}activity/all`).then((data)=>data.data.result);
    }

    deleteActivity(id){
        return axios.delete(`${DEV}activity/delete/`+id).then(res=> res.data);
    }

    deleteActivities(){
        return axios.delete(`${DEV}activity/deleteAll`).then(res=> res.data);
    }

    createActivity(data){
        return axios.post(`${DEV}activity/create`,data).then(res=> res.data);
    }

    getActivity(id)
    {
        return axios.get(`${DEV}activity/get/`+id).then(res=> res.data);
    }

    updateActivity(id,data)
    {
        return axios.put(`${DEV}activity/update/`+id,data).then(res=>res.data);
    }

    updateItem(id,data){
        return axios.put(`${DEV}activity/updateImage/${id}`, data).then(res=> res.data);
    }

    affecterImagesActivité(id,data)
    {
        return axios.post(`${DEV}activity/assign/`+id,data).then(res=>res.data);
    }

    afficherImagesActivité(id)
    {
        return axios.get(`${DEV}activity/images/`+id).then(res=>res.data);
    }

    deleteImgActivity(id){
        return axios.delete(`${DEV}activity/deleteimageactivity/`+id).then(res=> res.data);
    }

    updateSelectedItem(id){
        return axios.put(`${DEV}activity/updatetoselected/`+id).then(res=> res.data);
    }
    updateunSelectedItem(id){
        return axios.put(`${DEV}activity/updatetounselected/`+id).then(res=> res.data);
    }

    countAllActivities(){
        return axios.get(`${DEV}activity/countactivities`).then(res=> res.data);
    }

    countAllActivitiesOutDoor(){
        return axios.get(`${DEV}activity/countactivitiesoutdoor`).then(res=> res.data);
    }


    countAllActivitiesInDoor(){
        return axios.get(`${DEV}activity/countactivitiesindoor`).then(res=> res.data);
    }

    countAllActivitiesCulturelles(){
        return axios.get(`${DEV}activity/countactivitiesculturelles`).then(res=> res.data);
    }


}
