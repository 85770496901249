import axios from 'axios';
import DEV from '../../url';
export class FeedbackService {

    async all()
    {
        const res = await axios.get(`${DEV}feedback/all`);
        return res.data;
    }

    async recent()
    {
        const res = await axios.get(`${DEV}feedback/recent`);
        return res;
    }

    delete(id)
    {
        return axios.delete(`${DEV}feedback/delete/${id}`);
    }

    async get(id)
    {
        const res = await axios.get(`${DEV}feedback/get/${id}`);
        return res;
    }

    approve(id,data)
    {
        return axios.put(`${DEV}feedback/approve/`+id,data).then((res)=>res.data);
    }
}
