/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { ActivityService } from "../../service/activityService/ActivityService";
import DEV from "../../url";

const Activités = () => {
    //Activity JS Section

    let emptyActivity = {
        id: 0,
        title: "",
        price: 0,
        description: "",
        image: "",
        type: "",
    };
    const activityService = new ActivityService();
    const [activity, setActivity] = useState(null);
    const [activityDialog, setActivityDialog] = useState(false);
    const [submittedActivity, setSubmittedActivity] = useState(false);
    const [activities, setActivities] = useState([]);
    const [deleteActivityDialog, setDeleteActivityDialog] = useState(false);
    const [deleteActivitiesDialog, setDeleteActivitiesDialog] = useState(false);
    const [title, setTitle] = useState("");
    const [price, setPrice] = useState(0);
    const [description, setDescription] = useState("");
    const [img, setImg] = useState("");
    const [type, setType] = useState("");
    const [dateAc, setDateAc] = useState("");

    const dropdownValues = [
        { name: "Intérieure", code: "int" },
        { name: "Extérieure", code: "ext" },
        { name: "Culturelle", code: "clt" },
        { name: "Atelier", code: "atl" },
    ];

    useEffect(() => {
        activityService.getActivities().then((res) => {
            toast.current.show({ severity: "success", summary: "Opération Achevée", detail: "Opération effectuée avec succés", life: 3000 });
            setActivities(res);
        });
    }, []);

    const hideDeleteActivityDialog = () => {
        setDeleteActivityDialog(false);
    };

    const hideDeleteActivitiesDialog = () => {
        setDeleteActivitiesDialog(false);
    };

    const deleteActivity = (e, activity) => {
        e.preventDefault();
        setActivity({ ...activity });
        setDeleteActivityDialog(true);
    };

    const confirmDeleteActivity = async (e, id) => {
        e.preventDefault();
        await activityService
            .deleteActivity(id)
            .then((res) => {
                toast.current.show({ severity: "success", summary: "Opération Achevée", detail: "Cette Activité a été supprimée", life: 3000 });
                setDeleteActivityDialog(false);
                const trash = activities.filter(() => res?.result !== id);
                setActivities(trash);
                window.location.reload(false);
            }, [])
            .catch(() => {
                toast.current.show({ severity: "error", summary: "Opération échouée", detail: "L'activité que vous venez de la supprimer, contienne d'autres images. Merci de les supprimer avant.", life: 6000 });
            });
    };

    const openNewActivity = () => {
        setActivity(emptyActivity);
        setSubmittedActivity(false);
        setActivityDialog(true);
    };

    const saveActivity = async () => {
        setSubmittedActivity(true);
        const formData = new FormData();
        formData.append("title", title);
        formData.append("price", price);
        formData.append("description", description);
        formData.append("img", img);
        formData.append("type", type);
        //   formData.append('dateAc', dateAc)
        if(title === "" || img === "" || type === "")
        {
            toast.current.show({ severity: "error", summary: "Merci de bien compléter les champs", life: 3000 });   
            setActivityDialog(true);           
        }else {
        await activityService.createActivity(formData, {}).then(() => {
            toast.current.show({ severity: "success", summary: "Opération Achevée", detail: "Activité Créée", life: 3000 });
            setActivities(activities);
            window.location.reload(false);
            setActivityDialog(false);
        });
        }

      
    };

    const hideDialogActivity = () => {
        setSubmittedActivity(false);
        setActivityDialog(false);
    };

    const handleUploadChange = (e) => {
        if (e.target.files[0]) {
            setImg(e.target.files[0]);
        }
    };

    const deleteAllActivities = () => {
        activityService.deleteActivities().then((res) => {
            setActivities(null);
            setDeleteActivitiesDialog(false);
            toast.current.show({ severity: "success", summary: "Succés", detail: "Les activités sont supprimées avec succées", life: 3000 });
            window.location.reload(false);
        });
    };

    //END

    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    const exportCSV = () => {
        dt.current.exportCSV();
    };

    const confirmDeleteSelected = () => {
        setDeleteActivitiesDialog(true);
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Nouvelle activité" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNewActivity} />
                </div>
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Exporter en CSV" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        );
    };

    //Activity Section
    const titleBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Titre</span>
                <b> {rowData.title}</b>
            </>
        );
    };

    const imgBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Image</span>
                <img alt="" src={`${DEV}uploads/activities/${rowData?.img}`} height={140} width={"auto"} />
            </>
        );
    };

    const typeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Type</span>
                {rowData.type}
            </>
        );
    };

    const priceBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Prix</span>
                {rowData.price} {"DT"}
            </>
        );
    };

    // END

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <a href={`/#/activité/affecterimages/` + rowData.id}>
                    <Button title="Ajouter autres images" icon="pi pi-images" className="p-button-rounded p-button-info mt-2 mr-1" />
                </a>
                <a href={`/#/activité/modifier/` + rowData.id}>
                    {" "}
                    <Button title="Modifier cette activité" icon="pi pi-pencil" className="p-button-rounded p-button-success mr-1"></Button>{" "}
                </a>
                <a href={`/#/activité/modifierImage/` + rowData.id}>
                    {" "}
                    <Button title="Modifier Image" icon="pi pi-upload" className="p-button-rounded p-button-warning mr-1"></Button>{" "}
                </a>
                <Button title="Supprimer cette activité" icon="pi pi-trash" className="p-button-rounded p-button-danger mt-2 mr-1" onClick={(e) => deleteActivity(e, rowData)} />
            </div>
        );
    };

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Activités</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Cherchez..." />
            </span>
        </div>
    );

    const activityDialogFooter = (
        <>
            <Button label="Annuler" icon="pi pi-times" className="p-button-text" onClick={hideDialogActivity} />
            <Button label="Sauvegarder" icon="pi pi-check" className="p-button-text" onClick={saveActivity} />
        </>
    );

    const deleteActivityDialogFooter_ = (rowData) => (
        <>
            <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={hideDeleteActivityDialog} />
            <Button label="Oui" icon="pi pi-check" className="p-button-text" onClick={(e) => confirmDeleteActivity(e, rowData.id)} />
        </>
    );

    const deleteActivitiesDialogFooter = (
        <>
            <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={hideDeleteActivitiesDialog} />
            <Button label="Oui" icon="pi pi-check" className="p-button-text" onClick={deleteAllActivities} />
        </>
    );

    return (
        <div className="grid crud-demo" style={{ marginTop: 0 }}>
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    <DataTable
                        ref={dt}
                        value={activities}
                        dataKey="id"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Affichage {first} du {last} au {totalRecords} éléments  "
                        globalFilter={globalFilter}
                        emptyMessage="Aucune activité n'a été trouvée."
                        header={header}
                        responsiveLayout="scroll"
                    >
                        <Column field="title" header="Titre" sortable body={titleBodyTemplate} headerStyle={{ width: "20%", minWidth: "10rem" }}></Column>
                        <Column field="price" header="Prix" sortable body={priceBodyTemplate} headerStyle={{ width: "20%", minWidth: "10rem" }}></Column>
                        <Column header="Image" body={imgBodyTemplate} headerStyle={{ width: "20%", minWidth: "10rem" }}></Column>
                        <Column field="type" header="Type" body={typeBodyTemplate} sortable headerStyle={{ width: "20%", minWidth: "8rem" }}></Column>
                        <Column headerStyle={{ width: "20%", minWidth: "10rem" }} body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={activityDialog} style={{ width: "450px" }} header="Créer une Activité" modal className="p-fluid" footer={activityDialogFooter} onHide={hideDialogActivity}>
                        <div className="field">
                            <label htmlFor="title">Titre</label>
                            <InputText type={"text"} id="title" value={title} onChange={(e) => setTitle(e.target.value)} required autoFocus className={classNames({ "p-invalid": submittedActivity && !title })} />
                            {submittedActivity && !title && <small className="p-invalid">Champ Obligatoire.</small>}
                        </div>

                        <div className="field">
                            <label htmlFor="price">Prix</label>
                            <InputText id="price" value={price} onChange={(e) => setPrice(e.target.value)} required autoFocus className={classNames({ "p-invalid": submittedActivity && !price })} />
                            {submittedActivity && !price && <small className="p-invalid">Champ Obligatoire.</small>}
                        </div>
                        
                        <div className="field">
                            <label htmlFor="description">Description</label>
                            <InputTextarea id="description" value={description} onChange={(e) => setDescription(e.target.value)} required rows={3} cols={20} />
                        </div>

                        <div className="field">
                            <label htmlFor="image">Image</label>
                            <InputText 
                            className={classNames({ "p-invalid": submittedActivity && !img })}
                            type="file" id="img" name="img" onChange={handleUploadChange} required />
                            {submittedActivity && !img && <small className="p-invalid">Champ Obligatoire.</small>}

                            <div className="mt-3" style={{ borderRadius: 5, height: "auto", border: "1px solid green", backgroundColor: "#B7E5B0", color: "black" }}>
                                <p className="pt-2 mx-3">
                                    <i className="pi pi-fw pi-file"></i> <b>A noter :</b> <br />
                                    - L'image est un champ obligatoire. <br />- Le nom de l'image doit être sous format <b>jpeg, png ou bien jpg</b>. <br />- Le nom de l'image ne doit pas contenir des éspaces.
                                </p>
                            </div>

                            <div align="center">
                                <div className="mt-3" align="center">
                                    <img height={140} width="auto" src={img} alt="image-activité" />
                                </div>
                            </div>
                        </div>

                        <div className="field">
                            <label className="mb-3">Type</label>

                            <Dropdown className={classNames({ "p-invalid": submittedActivity && !type })} name="type" value={type} onChange={(e) => setType(e.target.value)} options={dropdownValues} optionValue="name" optionLabel="name" placeholder="Selectionner Votre Type" />
                            {submittedActivity && !type && <small className="p-invalid">Champ Obligatoire.</small>}

                        </div>
                    </Dialog>
                    <Dialog visible={deleteActivityDialog} style={{ width: "450px" }} header="Confirm" modal footer={deleteActivityDialogFooter_(activity)} onHide={hideDeleteActivityDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                            {activity && <span>Êtes-vous sûr de vouloir supprimer cette activité : {activity.title} ?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteActivitiesDialog} style={{ width: "450px" }} header="Confirm" modal footer={deleteActivitiesDialogFooter} onHide={hideDeleteActivitiesDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                            <span>Êtes-vous sûr de vouloir supprimer toutes vos activités ?</span>
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps?.location?.pathname === nextProps?.location?.pathname;
};

export default React.memo(Activités, comparisonFn);
