import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { Toolbar } from "primereact/toolbar";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { RestaurantService } from "../../service/restaurantService/restaurantService";

const ModifierVisibilité = () => {
    const history = useNavigate();
    const toast = useRef(null);
    const { id } = useParams();

    const restaurantService = new RestaurantService();

    const [visibility, setVisibility] = useState("");
    const [submittedRestaurant, setSubmittedRestaurant] = useState(false);

    const getMenuCard = async () => {
        restaurantService.getMenuCardById(id).then((res) => {
            setVisibility(res.visibility);
        });
    };

    const dropdownValues = [
        { name: "Visible", code: 1 },
        { name: "Caché", code: 0 },
    ];

    const modifier = async (e) => {
        e.preventDefault();
         
         restaurantService.updateVisibility(id, {visibility:visibility}).then(() => {
            toast.current.show({ severity: 'success', summary: 'Opération Achevée', detail: 'Visibilité Modifiée', life: 3000 });
        });
    }

    useEffect(() => {
        getMenuCard();
    }, []);

   
    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button onClick={(e) => modifier(e)} label="Sauvegarder" icon="pi pi-fw pi-check" className="p-button-success mr-2" />
            </React.Fragment>
        );
    };

    const gotRestaurants = (e) => {
        e.preventDefault();
        history("/menu_card");
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button onClick={(e) => gotRestaurants(e)} className="p-button-rounded p-button-outlined p-button-danger">
                    <i className="pi pi-times"></i>
                </Button>
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            <div className="grid" style={{ marginTop: 0 }}>
                <Toast ref={toast} />
                <Toolbar className="mb-4 col-12" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                <div className="col-12 md:col-12 mt-4">
                    <div className="card p-fluid">
                        <h5>Modifier Cette Carte Menu</h5>
                        <form>
                            
                               <div className="field">
                                <label className="mb-3">Visibilité</label>
                                <Dropdown name="visibility"
                                value={visibility}
                                onChange={(e) => setVisibility(e.target.value)}
                                options={dropdownValues}
                                optionValue="code"
                                optionLabel="name"
                                placeholder="Changer la visibilité" />
                            </div> 
                        </form>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps?.location?.pathname === nextProps?.location?.pathname;
};

export default React.memo(ModifierVisibilité, comparisonFn);
