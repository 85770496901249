import axios from 'axios';
import DEV from '../../url';
export class TermsService{


    create(data){
        return axios.post(`${DEV}terms/create`, data).then((res)=>res.data);

    }

    async all(){
        const res = await axios.get(`${DEV}terms/all`);
        return res.data.result;
    }

    update(id,data){
        return axios.put(`${DEV}terms/update/`+id,data).then((res)=>res.data);
    }

    async get(id){
        const res = await axios.get(`${DEV}terms/get/` + id);
        return res.data;
    }

    delete(id){
        return axios.delete(`${DEV}terms/delete/`+id);
    }
}
