import React, {useContext, useEffect, useRef, useState} from 'react';
import classNames from 'classnames';
import {Route, Routes, useLocation, useNavigate,Navigate} from 'react-router-dom';
import {CSSTransition} from 'react-transition-group';


import {AppTopbar} from './AppTopbar';
import {AppFooter} from './AppFooter';
import {AppMenu} from './AppMenu';
import {AppConfig} from './AppConfig';

import Dashboard from './components/Dashboard';

import PrimeReact from 'primereact/api';

import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'prismjs/themes/prism-coy.css';
import './assets/demo/flags/flags.css';
import './assets/demo/Demos.scss';
import './assets/layout/layout.scss';
import './App.scss';
import Hébergement from './pages/Hébergement/Hébergement';
import Activités from './pages/Activités/Activités';
import Packs from './pages/Packs/Packs';
import Produits from './pages/Produits/Produits';
import Bookings from './pages/Bookings/Bookings';
import Restaurant from "./pages/Restaurant/Restaurant";
import ModifierActivité from './pages/Activités/ModifierActivité';
import AffecterImagesActivité from './pages/Activités/AffecterImagesActivité';
import Banners from './pages/ConfigurationApp/Banners/Banners';
import ModifierBanner from './pages/ConfigurationApp/Banners/ModifierBanner';
import Sections from './pages/About/Sections';
import ModifierSection from './pages/About/ModifierSection';
import AjouterContenuSection from './pages/About/AjouterContenuSection';
import Labellisation from './pages/About/Labellisation';
import Contact from './pages/Contact/Contact';
import Events from './pages/Evenements/Events';
import ModifierEvenement from './pages/Evenements/ModifierEvenement';
import ModifierProduit from './pages/Produits/ModifierProduit';
import ModifierPack from './pages/Packs/ModifierPack';
import AffecterImagesEvent from './pages/Evenements/AffecterImagesEvent';
import AffecterImagesProduit from './pages/Produits/AffecterImagesProduit';
import ModifierHébérgement from './pages/Hébergement/ModifierHébérgement';

import Reminders from './pages/Reminder/Reminders';

import MenuCard from './pages/Menu/MenuCard';
import ModifierMenuCard from './pages/Menu/ModifierMenuCard';
import ModifierVisibilité from './pages/Menu/ModifierVisibilité';


import AffecterRoomImages from './pages/Hébergement/AffecterRoomImages';
import ModifierRestaurant from "./pages/Restaurant/ModifierRestaurant";
import Feedback from './pages/Feedback/Feedback';
import ConfirmFeedback from './pages/Feedback/ConfirmFeedback';
import Links from './pages/Links/Links';
import ModifierLien from './pages/Links/ModifierLien';
import Terms from './pages/Terms/Terms';
import ModifierTerms from './pages/Terms/ModifierTerms';
import Spa from './pages/Spa/Spa';
import ModifierSpa from './pages/Spa/ModifierSpa';
import AssignSpaImages from './pages/Spa/AssignSpaImages';
import AssignSpaVideos from './pages/Spa/AssignSpaVideos';
import ContactInfos from "./pages/Contact/ContactInfos/contactInfo";

import Channels from './pages/Reportage/Channels';
import ModifierChannel from './pages/Reportage/ModifierChannel';
import AssignVideosChannel from './pages/Reportage/AssignVideosChannel';
import Videos from "./pages/Reportage/Videos";
import ModifierVidéo from "./pages/Reportage/ModifierVidéo";
import AuthContext from "./AuthContext";
import Login from "./pages/auth/login/Login"

import ModifierImage from "./pages/Restaurant/ModifierImage";
import ModifierImageActivité from "./pages/Activités/ModifierImageActivité";
import ModifierImageBanner from "./pages/ConfigurationApp/Banners/ModifierImageBanner";
import ModifierImageEvent from "./pages/Evenements/ModifierImageEvent";
import ModifierImageHebergement from "./pages/Hébergement/ModifierImageHebergement";
import ModifierImagePack from "./pages/Packs/ModifierImagePack";
import ModifierImageProduit from "./pages/Produits/ModifierImageProduit";
import ModifierImageChannel from "./pages/Reportage/ModifierImageChannel";
import {LoginService} from "./service/userService/loginService";
import ModifierContactInfo from "./pages/Contact/ContactInfos/ModifierContactInfo";
import HeadingList from "./pages/Headings/HeadingList";
import ModifierHeadingImg from "./pages/Headings/ModifierHeadingImg";
import {setAuthToken} from "./AuthToken";
import Conventions from './pages/Conventions/Conventions';
import ModifierConvention from './pages/Conventions/ModifierConvention';
import Valeurs from './pages/Valeurs/Valeurs';
import ModifierImageValeur from './pages/Valeurs/ModifierImageValeur';
import ModifierValeur from './pages/Valeurs/ModifierValeur';
import ModifierLabellisation from './pages/About/ModifierLabellisation';


const Routers = () => {
    const [layoutMode, setLayoutMode] = useState('static');
    const [layoutColorMode, setLayoutColorMode] = useState('light')
    const [inputStyle, setInputStyle] = useState('outlined');
    const [ripple, setRipple] = useState(true);
    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
    const {isLoggedIn} = useContext(AuthContext);

    let {location} = useLocation();
    let navigate = useNavigate();

    PrimeReact.ripple = true;

    let menuClick = false;
    let mobileTopbarMenuClick = false;

    useEffect(() => {
        if (mobileMenuActive) {
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }
    }, [mobileMenuActive]);


    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    }

    const onRipple = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value)
    }

    const onLayoutModeChange = (mode) => {
        setLayoutMode(mode)
    }

    const onColorModeChange = (mode) => {
        setLayoutColorMode(mode)
    }

    const onWrapperClick = (event) => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }

        if (!mobileTopbarMenuClick) {
            setMobileTopbarMenuActive(false);
        }

        mobileTopbarMenuClick = false;
        menuClick = false;
    }

    const onToggleMenuClick = (event) => {
        menuClick = true;

        if (isDesktop()) {
            if (layoutMode === 'overlay') {
                if (mobileMenuActive === true) {
                    setOverlayMenuActive(true);
                }

                setOverlayMenuActive((prevState) => !prevState);
                setMobileMenuActive(false);
            } else if (layoutMode === 'static') {
                setStaticMenuInactive((prevState) => !prevState);
            }
        } else {
            setMobileMenuActive((prevState) => !prevState);
        }

        event.preventDefault();
    }

    const onSidebarClick = () => {
        menuClick = true;
    }

    const onMobileTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        setMobileTopbarMenuActive((prevState) => !prevState);
        event.preventDefault();
    }

    const onMobileSubTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;
        event.preventDefault();
    }

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
    }
    const isDesktop = () => {
        return window.innerWidth >= 992;
    }

    const menu = [
        {
            label: 'Accueil',
            items: [{
                label: 'Dashboard', icon: 'pi pi-fw pi-chart-line', to: '/dashboard'
            }]
        },
        {
            label: 'Rubriques', icon: 'pi pi-fw pi-sitemap',
            items: [
                {
                    label: 'A propos', icon: 'pi pi-fw pi-info-circle', items: [
                        {label: 'Labellisation', to: '/labellisation'},
                        {label: 'Chaînes, Radios  & Réseaux sociaux', to: '/channels'},
                        {label: 'Sections à propos', to: '/sections'},

                        //{label: 'Réseaux Sociaux', to: '/videos'}
                    ]
                },
                {label: "Activités", icon: "pi pi-fw pi-calendar", to: "/activites"},
                {label: "Avis", icon: "pi pi-fw pi-users", to: "/avis"},
                {label: "Contacts", icon: "pi pi-fw pi-user", to: "/contacts"},
                {label: "Conventions", icon: "pi pi-fw pi-file-o", to: "/conventions"},
                {label: "Evénements", icon: "pi pi-fw pi-calendar-minus", to: "/evenements"},
                {label: "Hammam & Spa", icon: "pi pi-fw pi-shopping-bag", to: '/hammam_spa'},
                {label: 'Hébergement', icon: 'pi pi-fw pi-home', to: '/hebergements'},
                {label: "Menu à la carte", icon: "pi pi-fw pi-map", to: '/menu_card'},
                {label: "Packs", icon: "pi pi-fw pi-inbox", to: "/packs"},
                {label: "Réservations", icon: "pi pi-fw pi-check-square", to: "/bookings"},
                {label: "Restauration", icon: "pi pi-fw pi-map", to: '/menu'},
                {label : "Valeurs & Transformations" , icon:"pi pi-fw pi-check                ",to:"/valeurs"},
                {label: "Terroir", icon: "pi pi-fw pi-check-square", to: "/produits"},


            ]
        },
        {
            label: 'Paramétres', icon: 'pi pi-fw pi-settings',
            items: [
                {
                    label: 'Configuration', icon: 'pi pi-fw pi-flag', items: [
                        {label: 'Couverture', to: '/banners'},
                        {label: 'Entêtes', to: '/entetes'},
                        {label: 'Informations de Contact', to: '/contactInfos'},
                        {label: 'Liens utiles', to: '/liens'},
                        {label: "Régles d'utilisation", to: '/terms'},
                    ]
                },
                /*
                                { label: 'Mot de passe', icon: 'pi pi-fw pi-lock' }
                */
            ]
        }
    ];

    const addClass = (element, className) => {
        if (element.classList)
            element.classList.add(className);
        else
            element.className += ' ' + className;
    }

    const removeClass = (element, className) => {
        if (element.classList)
            element.classList.remove(className);
        else
            element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }

    const wrapperClass = classNames('layout-wrapper', {
        'layout-overlay': layoutMode === 'overlay',
        'layout-static': layoutMode === 'static',
        'layout-static-sidebar-inactive': staticMenuInactive && layoutMode === 'static',
        'layout-overlay-sidebar-active': overlayMenuActive && layoutMode === 'overlay',
        'layout-mobile-sidebar-active': mobileMenuActive,
        'p-input-filled': inputStyle === 'filled',
        'p-ripple-disabled': ripple === false,
        'layout-theme-light': layoutColorMode === 'light'
    });

    const tokenExist = sessionStorage.getItem("refreshToken");

   const layoutMainContainerClass = classNames(tokenExist  ? 'layout-main-container' : '')
    const layoutMainClass = classNames(tokenExist  ? 'layout-main' : '')

    const RequireAuth = ({children}) => {
         if ( !tokenExist) {
             return <Navigate to="/" state={{from: location}}/>;
         }
         else{
            //  const token = localStorage.getItem("access");

            //setAuthToken(token);

              return children;
         }

     }

    const service = new LoginService();

  /*  const reloadToken = () => {
        try {
            service.token().then((response) => {

                setToken(response.accessToken);
                const decoded = jwt_decode(response?.accessToken);
                setFullname(decoded.fullname);
                setExpire(decoded?.expire);
            })
        } catch (error) {
            if (error.response) {
                navigate("/");
            }
        }
    }*/




    const copyTooltipRef = useRef();

    return (
        <>


            <div className={wrapperClass} onClick={onWrapperClick}>

  {tokenExist &&
            <>
                <AppTopbar onToggleMenuClick={onToggleMenuClick} layoutColorMode={layoutColorMode}
                           mobileTopbarMenuActive={mobileTopbarMenuActive} onMobileTopbarMenuClick={onMobileTopbarMenuClick} onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick}/>
                <div className="layout-sidebar" onClick={onSidebarClick}>
                    <AppMenu model={menu} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode}/>
                </div>
            </>}
            <div className={layoutMainContainerClass}>
                <div className={layoutMainClass}>
                        <Routes>
                                <Route path="/" element={<Login/>}/>


                            <>
                                <Route path="/dashboard" element={<RequireAuth><Dashboard/></RequireAuth>}/>
                                <Route path="/sections" element={<RequireAuth><Sections/></RequireAuth>}/>
                                <Route path="/labellisation" element={<RequireAuth><Labellisation/></RequireAuth>}/>
                                <Route path="/labellisation-modifier/:id" element={<RequireAuth><ModifierLabellisation/></RequireAuth>}/>

                                <Route path="/section/modifier/:id" element={<RequireAuth><ModifierSection/></RequireAuth>}/>
                                <Route path="/section/ajoutercontenu/:id" element={<RequireAuth><AjouterContenuSection/></RequireAuth>}/>
                                <Route path="/hebergements" element={<RequireAuth><Hébergement/></RequireAuth>}/>
                                <Route path="/hebergement/affecter/:id" element={<RequireAuth><AffecterRoomImages/></RequireAuth>}/>
                                <Route path="/hebergement/modifier/:id" element={<RequireAuth><ModifierHébérgement/></RequireAuth>}/>
                                <Route path="/hebergement/modifierImage/:id" element={<RequireAuth><ModifierImageHebergement/></RequireAuth>}/>
                                <Route path="/activites" element={<RequireAuth><Activités/></RequireAuth>}/>
                                <Route path="/activité/modifier/:id" element={<RequireAuth><ModifierActivité/></RequireAuth>}/>
                                <Route path="/activité/modifierImage/:id" element={<RequireAuth><ModifierImageActivité/></RequireAuth>}/>
                                <Route path="/activité/affecterimages/:id" element={<RequireAuth><AffecterImagesActivité/></RequireAuth>}/>
                                <Route path="/packs" element={<RequireAuth><Packs/></RequireAuth>}/>
                                <Route path="/pack/modifier/:id" element={<RequireAuth><ModifierPack/></RequireAuth>}/>
                                <Route path="/pack/modifierImage/:id" element={<RequireAuth><ModifierImagePack/></RequireAuth>}/>
                                <Route path="/produits" element={<RequireAuth><Produits/></RequireAuth>}/>
                                <Route path="/produit/modifier/:id" element={<RequireAuth><ModifierProduit/></RequireAuth>}/>
                                <Route path="/produit/modifierImage/:id" element={<RequireAuth><ModifierImageProduit/></RequireAuth>}/>
                                <Route path="/produit/affecterimages/:id" element={<RequireAuth><AffecterImagesProduit/></RequireAuth>}/>
                                <Route path="/evenements" element={<RequireAuth><Events/></RequireAuth>}/>
                                <Route path="/evenement/modifier/:id" element={<RequireAuth><ModifierEvenement/></RequireAuth>}/>
                                <Route path="/evenement/modifierImage/:id" element={<RequireAuth><ModifierImageEvent/></RequireAuth>}/>
                                <Route path="/evenement/affecterimages/:id" element={<RequireAuth><AffecterImagesEvent/></RequireAuth>}/>
                                <Route path="/bookings" element={<RequireAuth><Bookings/></RequireAuth>}/>
                                <Route path="/banners" element={<RequireAuth><Banners/></RequireAuth>}/>
                                <Route path="/banner/modifierImage/:id" element={<RequireAuth><ModifierImageBanner/></RequireAuth>}/>
                                <Route path="/banner/modifier/:id" element={<RequireAuth><ModifierBanner/></RequireAuth>}/>
                                <Route path="/contacts" element={<RequireAuth><Contact/></RequireAuth>}/>
                                <Route path="/rappels" element={<RequireAuth><Reminders/></RequireAuth>}/>
                                <Route path="/avis" element={<RequireAuth><Feedback/></RequireAuth>}/>
                                <Route path="/commentaire/:id" element={<RequireAuth><ConfirmFeedback/></RequireAuth>}/>
                                <Route path="/liens" element={<RequireAuth><Links/></RequireAuth>}/>
                                <Route path="/lien/modifier/:id" element={<RequireAuth><ModifierLien/></RequireAuth>}/>
                                <Route path="/terms" element={<RequireAuth><Terms/></RequireAuth>}/>
                                <Route path="/contactInfos" element={<RequireAuth><ContactInfos/></RequireAuth>}/>
                                <Route path="/contactInfos/modifier/:id" element={<RequireAuth><ModifierContactInfo/></RequireAuth>}/>
                                <Route path="/term/modifier/:id" element={<RequireAuth><ModifierTerms/></RequireAuth>}/>
                                <Route path="/menu_card" element={<RequireAuth><MenuCard/></RequireAuth>}/>
                                <Route path="/menuUpdate/modifierVisibilité/:id" element={<RequireAuth><ModifierVisibilité/></RequireAuth>}/>                                
                                <Route path="/menuUpdate/modifier/:id" element={<RequireAuth><ModifierMenuCard/></RequireAuth>}/>
                                <Route path="/menus/modifier/:id" element={<RequireAuth><ModifierRestaurant/></RequireAuth>}/>
                                <Route path="/menus/modifierImage/:id" element={<RequireAuth><ModifierImage/></RequireAuth>}/>
                                <Route path="/menu" element={<RequireAuth><Restaurant/></RequireAuth>}/>
                                <Route path="/channels" element={<RequireAuth><Channels/></RequireAuth>}/>
                                <Route path="/channel/modifierChannel/:id" element={<RequireAuth><ModifierChannel/></RequireAuth>}/>
                                <Route path="/channel/modifierImage/:id" element={<RequireAuth><ModifierImageChannel/></RequireAuth>}/>
                                <Route path="/channel/affecter/:id" element={<RequireAuth><AssignVideosChannel/></RequireAuth>}/>
                                <Route path="/videos" element={<RequireAuth><Videos/></RequireAuth>}/>
                                <Route path="/video/modifier/:id" element={<RequireAuth><ModifierVidéo/></RequireAuth>}/>
                                <Route path="/hammam_spa" element={<RequireAuth><Spa/></RequireAuth>}/>
                                <Route path="/spa_hammam/modifier/:id" element={<RequireAuth><ModifierSpa/></RequireAuth>}/>
                                <Route path="/spa_hammam/affecterimages/:id" element={<RequireAuth><AssignSpaImages/></RequireAuth>}/>
                                <Route path="/spa_hammam/affectervideos/:id" element={<RequireAuth><AssignSpaVideos/></RequireAuth>}/>
                                <Route path="/entetes" element={<RequireAuth><HeadingList/></RequireAuth>}/>
                                <Route path="/enteteImage/:id" element={<RequireAuth><ModifierHeadingImg/></RequireAuth>}/>
                                <Route path="/conventions" element={<RequireAuth><Conventions/></RequireAuth>}/>
                                <Route path="/convention/modifier/:id" element={<RequireAuth><ModifierConvention/></RequireAuth>}/>
                                <Route path="/valeurs" element={<RequireAuth><Valeurs/></RequireAuth>}/>
                                <Route path="/valeur/modifierValeur/:id" element={<RequireAuth><ModifierValeur/></RequireAuth>}/>
                                <Route path="/valeur/modifierImageValeur/:id" element={<RequireAuth><ModifierImageValeur/></RequireAuth>}/>

                            </>
                        </Routes>
                    </div>

                    <AppFooter layoutColorMode={layoutColorMode}/>
                </div>

                {tokenExist &&
            <>
                <AppConfig rippleEffect={ripple} onRippleEffect={onRipple} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange}
                           layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange} layoutColorMode={layoutColorMode} onColorModeChange={onColorModeChange}/>
                <CSSTransition classNames="layout-mask" timeout={{enter: 200, exit: 200}} in={mobileMenuActive} unmountOnExit>
                    <div className="layout-mask p-component-overlay"></div>
                </CSSTransition>
            </>}
            </div>


        </>

    );

}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Routers, comparisonFn);
