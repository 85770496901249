import { Button } from "primereact/button";
import { Toast } from 'primereact/toast';
import { Toolbar } from "primereact/toolbar";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import {ChannelService} from "../../service/channelService/ChannelService";
import classNames from "classnames";
import {Dropdown} from "primereact/dropdown";

const ModifierChannel = () => {

     const history = useNavigate();
    const toast = useRef(null);

        const dropdownValues = [
        { name: 'Chaîne', code: 'ch' },
        { name: 'Radio', code: 'rad' },
                    { name: 'Réseau Social', code: 'rs' },

    ];

    const { id } = useParams();
    const service = new ChannelService();

    const [title, setTitle] = useState("");
    const [link, setLink] = useState("");
    const [type_chaine, setType_chaine] = useState("");
 //   const [logo, setLogo] = useState("");


    const fetch = async () => {
        service.get(id).then((res) => {
            setTitle(res?.title);
            setType_chaine(res?.type_chaine);
            setLink(res?.link);
        });
    }



    const modifier = async (e) => {
        e.preventDefault();
        /*const formData = new FormData()
        formData.append('title', title)
        formData.append('type_chaine', type_chaine)*/
        if(title===""||type_chaine===""||link===""){
            toast.current.show({ severity: "error", summary: "Merci de bien compléter les champs", life: 3000 });
        }else{
                    service.updateChannel(id, {title:title,type_chaine:type_chaine,link:link}).then((res) => {
            toast.current.show({ severity: 'success', summary: 'Opération Achevée', detail: 'Channel Modifiée', life: 3000 });
        });
        }

    }


    useEffect(() => {
        fetch();

    }, []);

      const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button onClick={(e) => modifier(e)} label="Sauvegarder" icon="pi pi-fw pi-check" className="p-button-success mr-2" />
            </React.Fragment>
        )
    }

    const goto = (e) => {
        e.preventDefault();
        history("/channels")
    }

     const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button onClick={(e) => goto(e)} className="p-button-rounded p-button-outlined p-button-danger">
                    <i className="pi pi-times"></i>
                </Button>
            </React.Fragment>
        )
    }


     return (
        <React.Fragment>
            <div className="grid" style={{marginTop:0}}>
                <Toast ref={toast} />
                <Toolbar className="mb-4 col-12" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                <div className="col-12 md:col-12 mt-4">
                    <div className="card p-fluid">
                        <h5>Modifier Cet élément</h5>
                        <form>
                            <div className="field">
                                <label htmlFor="title">Titre</label>
                            <InputText id="title" value={title} onChange={(e) => setTitle(e.target.value)}
                                       className={classNames({'p-invalid': !title})}/>
                            </div>

                             <div className="field">
                            <label className="mb-3">Type</label>


                            <Dropdown name="type" value={type_chaine} onChange={(e) => setType_chaine(e.target.value)} options={dropdownValues} optionValue="name" optionLabel="name" placeholder="Selectionner Votre Type" />
                        </div>

                             <div className="field">
                                <label htmlFor="link">Lien</label>
                            <InputText id="link" value={link} onChange={(e) => setLink(e.target.value)}
                                        />
                            </div>

                         {/*<div className="field">
                                <label htmlFor="img">Image </label>
                                <InputText onChange={handleUploadChange} className="mt-3" id="logo" type="file" />

                                <div align="center">

                                                <div align="center" className="mt-3">
                                                    <img src={logo} alt="image activité" height={180} width="auto" />
                                                </div>
                                </div>

                            </div>*/}
                        </form>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )


}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps?.location?.pathname === nextProps?.location?.pathname;
};

export default React.memo(ModifierChannel, comparisonFn);
