import React, {  useRef } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
//import AuthContext from "./AuthContext";
import { Toast } from "primereact/toast";
import './AppTopbar.css';
import logo from "./assets/logo/avatar.png";
import { LoginService } from "./service/userService/loginService";
import { Button } from "primereact/button";
export const AppTopbar = (props) => {
    const toast = useRef(null);

    const navigate = useNavigate();
    const service = new LoginService();
   // const { isLoggedIn, signOut } = useContext(AuthContext);

    const signout = (e) => {
        e.preventDefault();

        service.logout().then((res) => {
            navigate("/");
            window.location.reload(false);
            console.log(res);
            sessionStorage.removeItem("refreshToken");
            toast.current.show({ severity: "success", summary: "Vous avez fermé votre session.", life: 3000 });
        });
    };


    return (
        <div className="layout-topbar">
            <Toast ref={toast} />
            <Link to="/dashboard" className="layout-topbar-logo">
                <img title="logo" alt="logo" style={{ height: 70, width: 140 }} src={logo} />
            </Link>

            <button type="button" className="p-link  layout-menu-button layout-topbar-button" onClick={props.onToggleMenuClick}>
                <i className="pi pi-bars" />
            </button>

            <button type="button" className="p-link layout-topbar-menu-button layout-topbar-button" onClick={props.onMobileTopbarMenuClick}>
                <i className="pi pi-ellipsis-v" />
            </button>

            <ul className={classNames("layout-topbar-menu lg:flex origin-top", { "layout-topbar-menu-mobile-active": props.mobileTopbarMenuActive })}>
                <li>
                    <a rel="noopener noreferrer" target={"_blank"} href="https://www.darzaghouane.com">
                        <Button className="p-button-primary">
                            <i className="pi pi-fw pi-globe"></i> &nbsp; Revenir à darzaghouane.com
                        </Button>
                    </a>
                </li>

                <li>
                    <button className="p-link layout-topbar-button" onClick={props.onMobileSubTopbarMenuClick}>
                        <i title="Session Ouverte" className="mt-1" style={{ backgroundColor: "green", borderRadius: 25, width: 12, height: 12 }} />
                    </button>
                </li>

                <li>
                    <button title="Se déconnecter" className="p-link layout-topbar-button btn-signout" onClick={signout}>
                        <i className="pi pi-sign-out" />  
                    </button>
                </li>
            </ul>
        </div>
    );
};
