import axios from "axios";
import DEV from "../../url";
export class PackService {
    post(data){
        return axios.post(`${DEV}packs/create`,data);
    }

    async all(){
        const res = await axios.get(`${DEV}packs/all`);
        return res.data;
    }

    updatebyid(id,data){
        return axios.put(`${DEV}packs/update/${id}`,data);
    }

        updateItem(id,data){
        return axios.put(`${DEV}packs/updateImage/${id}`, data).then(res=> res.data);
    }

    getbyid(id){
        return axios.get(`${DEV}packs/get/${id}`).then((res)=>res.data);
    }

    deletebyid(id){
        return axios.delete(`${DEV}packs/delete/${id}`);
    }

    assign(id,data){
        return axios.post(`${DEV}packs/assign/`+id, data).then(res=>res.data);

    }

    getImagesEvent(id){
        return axios.get(`${DEV}packs/images/`+id).then(res=>res.data);

    }
}
