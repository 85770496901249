import axios from 'axios';
import DEV from '../../url';
export class RestaurantService {


    getRestaurants() {
        return axios.get(`${DEV}restaurant/all`).then(res=>res.data);
    }


    getPetitDej() {
        return axios.get(`${DEV}restaurant/allPetitDej`).then(res=>res.data);
    }

    getDej() {
        return axios.get(`${DEV}restaurant/allDej`).then(res=>res.data);
    }

    getRestaurantById(id) {
        return axios.get(`${DEV}restaurant/get/${id}`).then((res)=>res.data );
    }

    deleteRestaurant(id){
        return axios.delete(`${DEV}restaurant/delete/`+id).then(res=> res.data);
    }
    deleteALL(){
        return axios.delete(`${DEV}restaurant/deleteALL`).then(res=> res.data);
    }

    createRestaurant(restaurant){
        return axios.post(`${DEV}restaurant/create`,restaurant).then(res=> res.data);
    }

    updateRestaurant(id,data){
        return axios.put(`${DEV}restaurant/update/${id}`, data).then(res=> res.data);
    }

    updateImageRestaurant(id,data){
        return axios.put(`${DEV}restaurant/updateImage/${id}`, data).then(res=> res.data);
    }

    createMenuRestaurant(data){
        return axios.post(`${DEV}restaurant/uploadMenu`, data).then(res=> res.data);
    }

    displayMenuRestaurant(){
        return axios.get(`${DEV}restaurant/menuCards`).then(res=> res.data);
    }

    getMenuCardById(id) {
        return axios.get(`${DEV}restaurant/menuCard/${id}`).then((res)=>res.data );
    }
    updateMenuCardById(id,data) {
        return axios.put(`${DEV}restaurant/updateMenuCard/${id}`,data).then((res)=>res.data );
    }


    deleteMenuCard(id){
        return axios.delete(`${DEV}restaurant/deleteCardMenu/`+id).then(res=> res.data);
    }

    updateVisibility(id,data){
        return axios.put(`${DEV}restaurant/udpateVisibility/${id}`, data).then(res=> res.data);
    }

}
