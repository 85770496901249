import axios from "axios";
import DEV from "../../url";
export class ProductService {

    post(data){
        return axios.post(`${process.env.REACT_APP_BASE_URL}product/create`,data);
    }

    async all(){
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}product/all`);
        return res.data;
    }

    updatebyid(id,data){
        return axios.put(`${process.env.REACT_APP_BASE_URL}product/update/${id}`,data);
    }

        updateItem(id,data){
        return axios.put(`${process.env.REACT_APP_BASE_URL}product/updateImage/${id}`, data).then(res=> res.data);
    }

    getbyid(id){
        return axios.get(`${process.env.REACT_APP_BASE_URL}product/get/${id}`).then(res=>res.data);
    }

    deletebyid(id){
        return axios.delete(`${process.env.REACT_APP_BASE_URL}product/delete/${id}`);
    }


    assign(id,data){
        return axios.post(`${process.env.REACT_APP_BASE_URL}product/assign/${id}`, data);

    }

    getImages(id){
        return axios.get(`${process.env.REACT_APP_BASE_URL}product/images/`+id).then(res=>res.data);
    }


    deleteimagebyid(id){
        return axios.delete(`${process.env.REACT_APP_BASE_URL}product/deleteimageproduct/${id}`);
    }

}
