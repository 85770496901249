import axios from 'axios';
import DEV from '../../url';
export class SpaService {

    create(data) {
        return axios.post(`${DEV}spa/create`, data).then((res) => res.data);
    }

    all() {
        return axios.get(`${DEV}spa/all`).then((res) => res.data);
    }

    delete(id) {
        return axios.delete(`${DEV}spa/delete/${id}`);
    }

    async get(id) {
        const res = await axios.get(`${DEV}spa/get/${id}`);
        return res;
    }

    update(id, data) {
        return axios.put(`${DEV}spa/update/` + id, data).then((res) => res.data);
    }

     assignImg(id,data) {
        return axios.post(`${DEV}spa/assignImages/`+id, data).then((res) => res.data);
    }

     assignVideos(id,data) {
        return axios.post(`${DEV}spa/assignVideos/`+id, data).then((res) => res.data);
    }

    deleteSpaImg(idSpa) {
        return axios.delete(`${DEV}spa/deleteSpaImg/`+idSpa).then((res)=>res.data);
    }

    deleteVideos(idSpaVideo) {
        return axios.delete(`${DEV}spa/deleteSpaVideos/`+idSpaVideo).then((res)=>res.data);
    }

    async getSpaImg() {
        const res = await axios.get(`${DEV}spa/getSpaImages`);
        return res;
    }

    async getSpaVideos() {
        const res = await axios.get(`${DEV}spa/getSpaVideos`);
        return res;
    }
}
