import React, {useEffect, useRef, useState} from "react";
import { useContext } from "react";
import {useLocation, useNavigate, useHref } from "react-router-dom";

import { Toast } from "primereact/toast";
import AuthContext  from "../../../AuthContext";
import {LoginService}  from "../../../service/userService/loginService";
import Logo from '../../../assets/logo/avatar.png';
import '../../../assets/demo/Login.scss';
import {setAuthToken} from "../../../AuthToken";

const Login = () => {
    const toast = useRef(null);

    //Testing Source Code
        const [email,setEmail]=useState("");
        const [password,setPassword]=useState("");
        const [msg,setMsg]=useState("");
        const navigate = useNavigate();
        const service = new LoginService();


        useEffect(()=>{
            toast.current.show({ severity: 'success', summary: 'Dar Zaghouan - Administrateur.', life: 3000 });
        },[]);

        const authenticate = async (e) => {
            e.preventDefault();
            
            try{
            service.auth({email, password}).then((res)=>{
                 sessionStorage.setItem("refreshToken",res.accessToken)
                 setAuthToken(res.accessToken) ;
                 toast.current.show({ severity: 'success', summary: 'Connection effectuée', life: 3000 });
                 navigate("/dashboard")
                // signIn();

                
            }) .catch(()=>{
                 toast.current.show({ severity: 'error', summary: 'Veuillez vérifier vos champs', life: 3000 });
            });   
                             
            }
            catch (error) {
                
                toast.current.show({ severity: 'warning', summary: 'Probléme de connection', life: 3000 });

                  // setMsg(error.res.data.result.msg);
                
            }

           
        }




  return (
      <div className="container">
          <div className="screen">
          <Toast ref={toast}/>

              <div className="screen__content">
                  <img height={100} width={150} src={Logo} alt=""/>
                 
                  <form className="login">
                      <p className={"alert alert-danger"}>{msg && <>{msg}</> }</p>
                      <div className="login__field">
                          <i className="login__icon fas fa-user"></i>
                          <input id="email" name="email" onChange={(e)=>setEmail(e.target.value)} type="email" className="login__input" placeholder="Addresse Email"/>
                      </div>
                      <div className="login__field">
                          <i className="login__icon fas fa-lock"></i>
                          <input name="password"
                                 required id="password"
                                 onChange={(e)=>setPassword(e.target.value)} type="password" className="login__input" placeholder="Mot de Passe"/>
                      </div>
                      <button onClick={authenticate} className="button login__submit">
                          <span className="button__text">Se Connecter</span>
                          <i className="button__icon fas fa-chevron-right"></i>
                      </button>
                  </form>
              </div>

              <div className="screen__background">
                  <span className="screen__background__shape screen__background__shape4"></span>
                  <span className="screen__background__shape screen__background__shape3"></span>
                  <span className="screen__background__shape screen__background__shape2"></span>
                  <span className="screen__background__shape screen__background__shape1"></span>
              </div>
          </div>
      </div>
  );
};

export default Login;
