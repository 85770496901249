/* import axios from "axios";


const instance = axios.create({
    baseURL : "http://localhost:4000",
    timeout: 1000,
    headers: {"Content-Type":"application/json"}
  });

export default instance;
 */
import axios from 'axios';

export const setAuthToken = token => {
   if (token) {
       axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
   }
   else
       delete axios.defaults.headers.common["Authorization"];
}
