import axios from "axios";
import DEV from "../../url";

export class BookingService {

    getAll(){
       return axios.get( `${DEV}book/all`).then((res)=>res.data);
    }

    recent(){
        return axios.get( `${DEV}book/current`).then((res)=>res.data);
     }

    supprimer(id)
    {
        return axios.delete(`${DEV}book/delete/`+id);
    }
    getBook(id)
    {
        return axios.delete(`${DEV}book/get/`+id);
    }
}
