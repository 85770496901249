import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from 'primereact/toast';
import { Toolbar } from "primereact/toolbar";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BannerService } from "../../../service/ConfigServiceApp/BannerService/BannerService";

const ModifierBanner = () => {

    const navigate = useNavigate();

    const toast = useRef(null);

    const bannerService = new BannerService();
    const { id } = useParams();
    const [title, setTitle] = useState();
    const [subtitle, setSubtitle] = useState("");
    const [text, setText] = useState("");
    const [img, setImg] = useState("");



    const getBanner = async () => {
         bannerService.getBanner(id).then((res) => {
             setTitle(res?.title);
             setSubtitle(res?.subtitle);
             setText(res?.text);
             setImg(res?.img);
         });
     }

     useEffect(() => {
         getBanner();
     }, []);


    const modifierBanner = async (e) => {
        e.preventDefault();
         const formData = new FormData()
        formData.append('title', title)
        formData.append('subtitle', subtitle)
        formData.append('text', text)
       // formData.append('img', img)
       if(title===""||subtitle===""||text==="")
       {
            toast.current.show({ severity: "error", summary: "Merci de bien compléter les champs", life: 3000 });
       }else{
            bannerService.updateBanner(id, formData,{ }).then(() => {
                toast.current.show({ severity: 'success', summary: 'Opération Achevée', detail: 'Item Modifié', life: 3000 });
            });
       }
       
    }

    const handleUploadChange = e => {
        if (e.target.files[0]) {
            setImg(e.target.files[0]);
        }
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button onClick={(e) => modifierBanner(e)} label="Sauvegarder" icon="pi pi-fw pi-check" className="p-button-success mr-2" />
            </React.Fragment>
        )
    }

    const gotobanners = (e) => {
        e.preventDefault();
       navigate("/banners")
    }


    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button onClick={(e) => gotobanners(e)} className="p-button-rounded p-button-outlined p-button-danger">
                    <i className="pi pi-times"></i>
                </Button>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <div className="grid" style={{marginTop:0}}>
                <Toast ref={toast} />
                <Toolbar className="mb-4 col-12" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                <div className="col-12 md:col-12 mt-4">
                    <div className="card p-fluid">
                        <h5>Modifier Cette Banniére</h5>
                        <form>
                            <div className="field">
                                <label htmlFor="title">Titre</label>
                                <InputText value={title || ''} onChange={(e) => setTitle(e.target.value)} id="title" type="text" />

                            </div>

                            <div className="field">
                                <label htmlFor="subtitle">Sous Titre</label>
                                <InputText value={subtitle || ''} onChange={(e) => setSubtitle(e.target.value)} id="subtitle" type="text" />
                            </div>



                            <div className="field">
                                <label htmlFor="text">Description</label>
                                <InputTextarea cols={8} rows={3} className="form-control" value={text || ''} onChange={(e) => setText(e.target.value)} id="text" />
                            </div>

                           {/* <div className="field">
                                <label htmlFor="img">Image </label>
                                <InputText onChange={handleUploadChange} className="mt-3" id="img" type="file" />

                                <div align="center">

                                                <div align="center" className="mt-3">
                                                    <img src={img} alt="image activité" height={180} width="auto" />
                                                </div>
                                </div>

                            </div>*/}

                        </form>
                    </div>


                </div>

            </div>
        </React.Fragment>
    );

}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(ModifierBanner, comparisonFn);
