import React, { useEffect, useRef, useState } from "react";
import { ContactService } from "../../service/ContactService/ContactService";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { InputText } from "primereact/inputtext";
import ShowMoreText from "react-show-more-text";
import ReactWhatsapp from 'react-whatsapp';
import { Dialog } from "primereact/dialog";

import Moment from "react-moment";
import { Toolbar } from "primereact/toolbar";

const Contact = () => {

    const service = new ContactService();
    const toast = useRef(null);
    const dt = useRef(null);
    const [contact, setContact] = useState();
    const [deleteItemDialog, setDeleteItemDialog] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [contacts, setContacts] = useState([null]);
    useEffect(() => {
        service.getAll().then((res) => {
            toast.current.show({ severity: 'success', summary: 'Opération Achevée', detail: 'Opération effectuée avec succés', life: 3000 });
            setContacts(res);
        });
    }, []);

    const exportCSV = () => {
        dt.current.exportCSV();
    };
    const confirmDelete = async (e, id) => {
        e.preventDefault();
        await service.supprimer(id).then((res) => {
            toast.current.show({ severity: 'success', summary: 'Opération Achevée', detail: 'Cet item a été supprimé', life: 3000 });
            setDeleteItemDialog(false);
            const trash = contacts.filter(() => res?.result !== id);
            setContacts(trash);
            window.location.reload(false);
        }, []);

    }

    const hideDeleteItemDialog = () => {
        setDeleteItemDialog(false);
    }

    const deleteItem = (e, contact) => {
        e.preventDefault();
        setContact({ ...contact });
        setDeleteItemDialog(true);
    }

    const executeOnClick = (isExpanded) => {
        console.log(isExpanded);
    }


    const fullnameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Nom Complet</span>
                <span> {rowData?.fullname}</span>
            </>
        );
    }

    const emailBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Adresse</span>
                <a href={`mailto:`+rowData?.email}>{rowData?.email}</a>
            </>
        );
    }

    const createdAtBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Date de création</span>
                <Moment format="YYYY MMM dd">
                    {rowData?.created_at}
                </Moment>
            </>
        );
    }

    const phonenumberBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Téléphone</span>
                <span>{rowData?.phonenumber}</span>
            </>
        );
    }

    const subjectBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Objet</span>
                <span> {rowData?.subject}</span>
            </>
        );
    }

    const messageBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Message</span>
                <ShowMoreText

                    lines={2}
                    more="Voir Plus"
                    less="Voir Moins"
                    className="text-justify"
                    anchorClass="my-anchor-css-class"
                    onClick={executeOnClick}
                    expanded={false}
                    width={280}
                    truncatedEndingComponent={"... "}
                >
                    <b> {rowData?.message}</b>
                </ShowMoreText>

            </>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">


                    <ReactWhatsapp style={{height:"42px",width:"42px",border:"1px solid transparent",backgroundColor:"#5EBA7D",marginBottom:"0px",borderRadius:"50px"}}
                    title={" " + rowData?.phonenumber}
                    number={" " + rowData?.phonenumber}
                    message="Lancer un appel">
                        <span className="pi pi-phone text-white"></span>
                    </ReactWhatsapp>
                    &nbsp;

                <Button onClick={(e) => deleteItem(e, rowData)} title='Supprimer cet item' icon="pi pi-trash" className="p-button-rounded p-button-danger mt-2 mr-1" />

            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Contacts</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Cherchez ..." />
            </span>
        </div>
    );

    const deleteItemDialogFooter_ = (rowData) => (
        <>
            <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={hideDeleteItemDialog} />
            <Button label="Oui" icon="pi pi-check" className="p-button-text" onClick={(e) => confirmDelete(e, rowData.id)} />
        </>
    );


    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Exporter en CSV" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        )
    }



    return (
        <div className="grid crud-memo" style={{marginTop:0}}>
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4"  right={rightToolbarTemplate}></Toolbar>

                    <DataTable ref={dt} value={contacts}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Affichage {first} du {last} au {totalRecords} éléments"
                        globalFilter={globalFilter} emptyMessage="Aucun contact n'a été trouvé." header={header} responsiveLayout="scroll">

                        <Column field="fullname" header="Nom Complet" sortable body={fullnameBodyTemplate} headerStyle={{ width: '20%', minWidth: '10rem' }}></Column>
                        <Column field="email" header="Adresse" sortable body={emailBodyTemplate} headerStyle={{ width: '20%', minWidth: '10rem' }}></Column>
                        <Column field="phonenumber" header="Téléphone" body={phonenumberBodyTemplate} headerStyle={{ width: '20%', minWidth: '10rem' }}></Column>
                        <Column field="subject" header="Objet" body={subjectBodyTemplate} sortable headerStyle={{ width: '20%', minWidth: '8rem' }}></Column>
                        <Column field="message" header="Message" body={messageBodyTemplate} sortable headerStyle={{ width: '20%', minWidth: '8rem' }}></Column>
                        <Column field="created_at" header="Date de création" body={createdAtBodyTemplate} sortable headerStyle={{ width: '20%', minWidth: '8rem' }}></Column>

                        <Column headerStyle={{ width: '20%', minWidth: '10rem' }} body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={deleteItemDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteItemDialogFooter_(contact)} onHide={hideDeleteItemDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {contact && <span>Êtes-vous sûr de vouloir supprimer ce contact : {contact?.fullname}{" Ayant le numéro : "}{contact?.phonenumber} ?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    )

}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps?.location?.pathname === nextProps?.location?.pathname;
};

export default React.memo(Contact, comparisonFn);
