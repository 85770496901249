import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { WhoAreWeService } from "../../service/whoAreWeService/WhoAreWeService";
import { Button } from "primereact/button";
import classNames from "classnames";
import DEV from "../../url";

const AjouterContenuSection = () => {
    const history = useNavigate();
    const toast = useRef(null);

    const [submitted, setSubmitted] = useState(false);

    const service = new WhoAreWeService();
    const { id } = useParams();
    const [tag, setTag] = useState("");
    const [text, setText] = useState("");
    const [img, setImg] = useState("");

    const imgFilehandler = (e) => {
        if (e.target.files.length !== 0) {
            setImg(e.target.files[0]);
        }
    };

    const [contents, setContents] = useState([]);

    const postContent = async (e) => {
        e.preventDefault();
        setSubmitted(true);

        const formData = new FormData();
        formData.append("tag", tag);
        formData.append("text", text);
        formData.append("img", img);

        if (tag === "" || text === "") {
            toast.current.show({ severity: "error", summary: "Merci de bien compléter les champs", life: 3000 });
        } else {
            service.postContent(id, formData, {}).then(() => {
                toast.current.show({ severity: "success", summary: "Vous avez affecter du contenu à cette section", life: 3000 });
                window.location.reload(false);
            });
        }
    };

    const getContentSection = async () => {
        service.getContentSection(id).then((res) => {
            toast.current.show({ severity: "success", summary: "Opération effectuée avec succées", life: 3000 });
            setContents(res);
        });
    };

    useEffect(() => {
        getContentSection();
    }, []);

    const supprimerContenu = async (e, idc) => {
        e.preventDefault();
        await service.deleteContent(idc).then((res) => {
            toast.current.show({ severity: "success", summary: "Opération Achevée", detail: "Vous avez supprimé le contenu lié à cette section", life: 3000 });
            window.location.reload(false);
            const trash = contents.filter(() => res !== idc);
            setContents(trash);
            console.log(res);
        });
    };

    const gotosections = (e) => {
        e.preventDefault();
        history("/sections");
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button onClick={(e) => postContent(e)} label="Assigner" icon="pi pi-fw pi-check" className="p-button-success mr-2" />
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button onClick={(e) => gotosections(e)} className="p-button-rounded p-button-outlined p-button-danger">
                    <i className="pi pi-times"></i>
                </Button>
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            <div className="grid" style={{ marginTop: 0 }}>
                <Toast ref={toast} />
                <Toolbar className="mb-4 col-12" right={rightToolbarTemplate}></Toolbar>
                <div className="col-12 md:col-12 mt-4">
                    <div className="card p-fluid">
                        <h5>Ajouter du contenu</h5>
                        <form>
                            <div className="field">
                                <label htmlFor="tag">Sous Titre</label>
                                <InputText 
                                autoFocus className={classNames({ "p-invalid": submitted && !tag })} onChange={(e) => setTag(e.target.value)} type="text" name="tag" id="tag" value={tag} />
                                {submitted && !tag && <small className="p-invalid">Champ Obligatoire.</small>}
                            </div>
                            <div className="field">
                                <label htmlFor="text">Text</label>
                                <InputTextarea 
                                autoFocus className={classNames({ "p-invalid": submitted && !text })} 
                                id="text" value={text} onChange={(e) => setText(e.target.value)} required rows={3} cols={20} />
                                {submitted && !text && <small className="p-invalid">Champ Obligatoire.</small>}

                            </div>
                            <div className="field">
                                <label htmlFor="img">Image</label>
                                <InputText 
                                 autoFocus className={classNames({ "p-invalid mt-3": submitted && !img })}
                                aria-required required onChange={imgFilehandler}  id="img" type="file" />
                                {submitted && !img && <small className="p-invalid">Champ Obligatoire.</small>}

                                <div className="mt-3" style={{ borderRadius: 5, height: "auto", border: "1px solid green", backgroundColor: "#B7E5B0", color: "black" }}>
                                    <p className="pt-2 mx-3">
                                        <i className="pi pi-fw pi-file"></i> <b>A noter :</b> <br />
                                        - L'image est un champ obligatoire. <br />- Le nom de l'image doit être sous format <b>jpeg, png ou bien jpg</b>. <br />- Le nom de l'image ne doit pas contenir des éspaces.
                                    </p>
                                </div>
                                <div align="center">
                                    {img ? (
                                        <React.Fragment>
                                            <div align="center">
                                                <img src={img} alt="img-section" height={100} width="auto" />
                                            </div>
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment></React.Fragment>
                                    )}
                                </div>
                                <div align="center" className="mt-5">
                                    <Toolbar className="mb-4 col-12 justify-content-center" left={leftToolbarTemplate}></Toolbar>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="grid">
                <Toast ref={toast} />
                <div className="col-12 md:col-12 mt-4">
                    <div className="card p-fluid">
                        <h5>Consulter le contenu</h5>
                        <div className="row">
                            {contents.map((item, index) => (
                                <div key={index} className="formgrid grid">
                                    <div className="field col">
                                        <img alt="apropos" src={`${DEV}uploads/whoarewe/${item?.img}`} height={260} width={300} />
                                    </div>

                                    <div className="field col mt-2">
                                        <h5 className="mt-1">{item.tag}</h5>
                                        <h1 className="mt-2">
                                            {" "}
                                            <b>{item.sectionName}</b>
                                        </h1>
                                        <p align="justify" className="mt-2 md:col-10" style={{ fontSize: "15px" }}>
                                            {item.text}
                                        </p>
                                    </div>
                                    <Button onClick={(e) => supprimerContenu(e, item?.idc)} className="p-button-rounded p-button-danger p-button-text">
                                        <i className="pi pi-times"></i> &nbsp; Retirer
                                    </Button>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
const comparisonFn = function (prevProps, nextProps) {
    return prevProps?.location?.pathname === nextProps?.location?.pathname;
};

export default React.memo(AjouterContenuSection, comparisonFn);
