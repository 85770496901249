import { Button } from "primereact/button";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ProductService } from "../../service/productService/ProductService";
import { Toolbar } from "primereact/toolbar";
import { Toast } from 'primereact/toast';
import { InputText } from "primereact/inputtext";
import classNames from "classnames";


const AffecterImagesProduit = () => {
    const history = useNavigate();
    const toast = useRef(null);
    const [submitted,setSubmitted]=useState(false);

    const service_ = new ProductService();
    const {id} = useParams();

    const [img, setImg] = useState("");

    const [listImg, setListImg] = useState([]);



    useEffect(() => {
        service_.getbyid(id).then(() => {
            console.log("");
        });
    }, []);

    const affecter = async (e) => {
        e.preventDefault();
        setSubmitted(true);

       const formData = new FormData()

        formData.append('img', img)
        if(img===""){
            toast.current.show({ severity: "error", summary: "Merci de bien compléter le champ requis.", life: 3000 });
        }else{
            service_.assign(id, formData,{ }).then(() => {
            toast.current.show({ severity: 'success', summary: 'Opération Achevée', detail: 'Vous avez affecté une image à votre produit', life: 3000 });
            window.location.reload(false);

        });
        }

    }


    const afficherImages = async () => {
        await service_.getImages(id).then((res)=>{
            setListImg(res);
        });
    }

    useEffect(() => {
        afficherImages();
    }, []);

    const supprimerImage = async(e,id) => {
        e.preventDefault();
        await service_.deleteimagebyid(id).then((res)=>{
            toast.current.show({ severity: 'success', summary: 'Opération Achevée', detail: 'Vous avez supprimé une image en relation à votre produit', life: 3000 });
            window.location.reload(false);
            const trash = listImg.filter(() => res?.result !== id);
            setListImg(trash);
        });
    }

    const handleUploadChange = e => {
        if (e.target.files[0]) {
            setImg(e.target.files[0]);
        }
    }


    const goto = (e) => {
        e.preventDefault();
        history("/produits")
    }


    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button onClick={(e) => affecter(e)} label="Assigner" icon="pi pi-fw pi-check" className="p-button-success mr-2" />
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button onClick={(e) => goto(e)} className="p-button-rounded p-button-outlined p-button-danger">
                    <i className="pi pi-times"></i>
                </Button>
            </React.Fragment>
        )
    }

    return(
        <React.Fragment>

            <div className="grid" style={{marginTop:0}}>
                <Toast ref={toast} />
                <Toolbar className="mb-4 col-12" right={rightToolbarTemplate}></Toolbar>

                <div className="col-12 md:col-12 mt-4">
                    <div className="card p-fluid">
                        <h5>Affecter des images à votre produit</h5>
                        <form>

                            <div className="field">
                                <label htmlFor="img">Image</label>
                                <InputText  
                                autoFocus className={classNames({ "p-invalid mt-3": submitted && !img })}
                                onChange={handleUploadChange}  id="img" type="file" />
                                {submitted && !img && <small className="p-invalid">Champ Obligatoire.</small>}

                                <div className='mt-3' style={{borderRadius:5, height:"auto",border:'1px solid green',backgroundColor:"#B7E5B0",color:"black"}}>
                                                <p className='pt-2 mx-3'>   
                                                <i className="pi pi-fw pi-file"></i> <b>A noter :</b> <br/>
                                                - L'image est un champ obligatoire. <br/>
                                                - Le nom de l'image doit être sous format <b>jpeg, png ou bien jpg</b>. <br/>
                                                - Le nom de l'image ne doit pas contenir des éspaces.</p>
                                </div>
                                <div align="center">

                                                <div align="center">
                                                    <img src={img} alt="produit" height={100} width="auto" />
                                                </div>

                                </div>
                                <div align="center" className="mt-5">
                                    <Toolbar className="mb-4 col-12 justify-content-center" left={leftToolbarTemplate}></Toolbar>
                                </div>

                            </div>

                        </form>
                    </div>


                </div>

                <div className="col-12 md:col-12 mt-4">
                    <div className="card p-fluid">
                        <h5>Voir les images en relation avec ce produit</h5>
                        <div className="grid">
                            {
                                listImg.map((item,index)=>(
                                    <div className="field col" key={index}>
                                        <img alt={" produit"} height={"200"} className="mx-3" width="310" src={`${process.env.REACT_APP_BASE_URL}uploads/products/${item?.img}`}/>
                                        <Button onClick={(e) => supprimerImage(e,item.id)} className="p-button-rounded p-button-danger p-button-text">
                                            <i className="pi pi-times"></i> &nbsp; Retirer
                                        </Button>
                                    </div>
                                ))
                            }
                        </div>

                    </div>


                </div>

            </div>

        </React.Fragment>
    )
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps?.location?.pathname === nextProps?.location?.pathname;
};

export default React.memo(AffecterImagesProduit, comparisonFn);
