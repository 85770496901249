import axios from 'axios';
import DEV from '../../url';
export class HeadingService {

    all() {
        return axios.get(`${DEV}heading/all`).then((res) => res.data);
    }

    async get(id) {
        const res = await axios.get(`${DEV}heading/get/${id}`);
        return res;
    }

    update(id, data) {
        return axios.put(`${DEV}heading/updateImage/` + id, data).then((res) => res.data);
    }


}
