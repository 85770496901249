import axios from "axios";
import DEV from "../../url";
export class LabellisationService {

    getAll()
    {
        return axios.get(`${DEV}labellisation/all`).then((data)=>data.data);
    }

    deletebyid(id){
        return axios.delete(`${DEV}labellisation/delete/`+id).then(res=> res.data);
    }

    deleteall(id){
        return axios.delete(`${DEV}labellisation/deleteAll`).then(res=> res.data);
    }

    create(data){
        return axios.post(`${DEV}labellisation/create`,data).then(res=> res.data);
    }

    getbyid(id)
    {
        return axios.get(`${DEV}labellisation/get/`+id);
    }

    updatebyid(id,data)
    {
        return axios.put(`${DEV}labellisation/update/${id}`,data).then(res=>res.data);
    }
}
