import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { Toolbar } from "primereact/toolbar";
import React, { useEffect, useRef, useState } from "react";
import { EventService } from "../../service/eventService/EventService";
import { useNavigate, useParams } from "react-router-dom";

const ModifierEvenement = () => {

    const history = useNavigate();
    const toast = useRef(null);

    const service_ = new EventService();
    const {id}= useParams();

    const [title, setTitle] = useState("");
    const [price, setPrice] = useState("");
    const [description, setDescription] = useState("");
    const [img, setImg] = useState("");
    const [type, setType] = useState("");



    const dropdownValues = [
        { name: 'Mariage & Réception', code: 'mar_rcp' },
        { name: 'Réunion & Séminaire', code: 're_sem' },
        { name: 'Team Building', code: 'tb' },
        { name: 'Fête Entreprise', code: 'ent' },
        { name: 'Fête de fin année scolaire', code: 'scl' },
        { name: 'Anniversaires', code: 'anniv' },

    ];

    const modifier = async (e) => {
        e.preventDefault();
                const formData = new FormData()
        formData.append('title', title)
        formData.append('price', price)
        formData.append('description', description)
        //formData.append('img', img)
        formData.append('type', type)
        if(title===""||description===""||type===""){
            toast.current.show({ severity: "error", summary: "Merci de bien compléter les champs", life: 3000 });
        }
        else{
        service_.updatebyid(id, formData,{  }).then(() => {
            toast.current.show({ severity: 'success', summary: 'Opération Achevée', detail: 'Evenement Modifié', life: 3000 });
        });
        }

    }

    const get = async () => {
       await service_.getbyid(id).then((res) => {
            setTitle(res.title);
            setPrice(res.price);
            setDescription(res.description);
            setImg(res.img);
            setType(res.type);
        });
    }

    useEffect(() => {
        get();
    }, []);

    const handleUploadChange = e => {
        if (e.target.files[0]) {
            setImg(e.target.files[0]);
        }
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button onClick={(e) => modifier(e)} label="Sauvegarder" icon="pi pi-fw pi-check" className="p-button-success mr-2" />
            </React.Fragment>
        )
    }

    const backto = (e) => {
        e.preventDefault();
        history("/evenements")
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button onClick={(e) => backto(e)} className="p-button-rounded p-button-outlined p-button-danger">
                    <i className="pi pi-times"></i>
                </Button>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <div className="grid" style={{marginTop:0}}>
                <Toast ref={toast} />
                <Toolbar className="mb-4 col-12" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                <div className="col-12 md:col-12 mt-4">
                    <div className="card p-fluid">
                        <h5>Modifier Cet Evenement</h5>
                        <form>
                            <div className="field">
                                <label htmlFor="title">Titre</label>
                                <InputText value={title} onChange={(e) => setTitle(e.target.value)} id="title" type="text" />
                            </div>

                            <div className="field">
                                <label htmlFor="price">Prix</label>
                                <InputText value={price} onChange={(e) => setPrice(e.target.value)} id="price" type="text" />
                            </div>



                            <div className="field">
                                <label htmlFor="description">Description</label>
                                <InputTextarea cols={8} rows={3} className="form-control" value={description} onChange={(e) => setDescription(e.target.value)} id="description" />
                            </div>

                           {/* <div className="field">
                                <label htmlFor="img">Image </label>
                                <InputText onChange={handleUploadChange} className="mt-3" id="img" type="file" />

                                <div align="center">

                                                <div align="center" className="mt-3">
                                                    <img src={img} alt="image activité" height={180} width="auto" />
                                                </div>

                                </div>

                            </div>*/}
                            <div className="field">
                                <label className="mb-3">Type</label>
                                <Dropdown name="type" value={type} onChange={(e) => setType(e.target.value)} options={dropdownValues} optionValue="name" optionLabel="name" placeholder="Selectionner Votre Type" />
                            </div>
                        </form>
                    </div>


                </div>

            </div>
        </React.Fragment>
    );



}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps?.location?.pathname === nextProps?.location?.pathname;
};

export default React.memo(ModifierEvenement, comparisonFn);
