import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { Toolbar } from "primereact/toolbar";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {RestaurantService} from "../../service/restaurantService/restaurantService";
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState } from "draft-js";
import { convertToHTML } from "draft-convert";
import DOMPurify from 'dompurify';

const ModifierRestaurant = () => {

    const history = useNavigate();
    const toast = useRef(null);

    const restaurantService = new RestaurantService();
    const { id } = useParams();
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [img, setImg] = useState("");
    const [rate, setRate] = useState("");
    const [type, setType] = useState("");
    const [price, setPrice] = useState("")


 const getRestaurant = async () => {
        restaurantService.getRestaurantById(id).then((res) => {
            setTitle(res?.title);
            setDescription(res?.description);
            setImg(res?.img);
            setRate(res?.rate);
            setType(res?.type);
            setPrice(res?.price);
        });
    }

  const dropdownValues = [
        {name: 'Petit déjeuner', code: 'petit_dej'},
        {name: 'Déjeuner', code: 'dej'},
      {name: 'Tisanerie', code: 'ts'},

    ];


     const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty(),
    );
    const [convertedContent, setConvertedContent] = useState(null);

    const handleEditorChange = (state) => {
        setEditorState(state);
        convertContentToHTML();
    }

    const convertContentToHTML = () => {
        let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
        setConvertedContent(currentContentAsHTML);
    }
    const createMarkup = (html) => {
        return {
            __html: DOMPurify.sanitize(html)
        }
    }


    const modifierRestaurant = async (e) => {
        e.preventDefault();
                                const formData = new FormData()
        formData.append('title', title)
        formData.append('description', convertedContent)
        formData.append('rate', rate)
      //  formData.append('img', img)
        formData.append('type', type)
        formData.append('price', price)
        
        if(title===""||type===""){
            toast.current.show({ severity: "error", summary: "Merci de bien compléter les champs", life: 3000 });
        }else{
           restaurantService.updateRestaurant(id, formData).then(() => {
            toast.current.show({ severity: 'success', summary: 'Opération Achevée', detail: 'Menu Modifiée', life: 3000 });
        }); 
        }
        
    }




    useEffect(() => {
        getRestaurant();
    },[]);


    const handleUploadChange = e => {
        if (e.target.files[0]) {
            setImg(e.target.files[0]);
        } else {
            setImg(img);
        }
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button onClick={(e) => modifierRestaurant(e)} label="Sauvegarder" icon="pi pi-fw pi-check" className="p-button-success mr-2" />
            </React.Fragment>
        )
    }

    const gotRestaurants = (e) => {
        e.preventDefault();
        history("/menu")
    }


    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button onClick={(e) => gotRestaurants(e)} className="p-button-rounded p-button-outlined p-button-danger">
                    <i className="pi pi-times"></i>
                </Button>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <div className="grid" style={{marginTop:0}}>
                <Toast ref={toast} />
                <Toolbar className="mb-4 col-12" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                <div className="col-12 md:col-12 mt-4">
                    <div className="card p-fluid">
                        <h5>Modifier Ce Menu</h5>
                        <form>
                            <div className="field">
                                <label htmlFor="title">Titre</label>
                                <InputText value={title} onChange={(e) => setTitle(e.target.value)} id="title" type="text" />
                            </div>

                            <div className="field">
                                <label htmlFor="description">Description</label>
 <Editor
                                    editorStyle={{height:140}}
                                    placeholder='mettre à jour votre contenu'
                                    editorState={editorState}
                                    onEditorStateChange={handleEditorChange}
                                    wrapperClassName="wrapper-class"
                                    editorClassName="editor-class"
                                    toolbarClassName="toolbar-class"
                                />
                                <hr className='mt-5' />

                                {
                                    description ?
                                        <div>
                                            <h3>Consulter la description à modifier</h3>

                                                <div  className='card' dangerouslySetInnerHTML={createMarkup(description)} />
                                        </div> : <></>
                                }                            </div>

                             <div className="field">
                                <label htmlFor="rate">Note</label>
                                <InputText type={"number"} cols={8} rows={3} className="form-control" value={rate} onChange={(e) => setRate(e.target.value)} id="rate"  />
                            </div>

                              <div className="field">
                                <label htmlFor="price">Prix</label>
                                <InputText value={price} onChange={(e) => setPrice(e.target.value)} id="price" type="text" />
                            </div>

                            {/*<div className="field">
                                <label htmlFor="img">Image</label>
                                <InputText  onChange={handleUploadChange} className="mt-3" id="img" type="file" />

                                <div align="center">

                                                <div align="center">
                                                    <img src={img} alt="image-restaurant" height={100} width="auto" id="img" name="img" />
                                                </div>
                                </div>


                            </div>*/}

                            <div className="field">
                                <label className="mb-3">Type</label>
                                <Dropdown name="type" value={type} onChange={(e) => setType(e.target.value)} options={dropdownValues} optionValue="name" optionLabel="name" placeholder="Selectionner Votre Type" />
                            </div>



                        </form>
                    </div>


                </div>

            </div>
        </React.Fragment>
    );

}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps?.location?.pathname === nextProps?.location?.pathname;
};

export default React.memo(ModifierRestaurant, comparisonFn);
