import classNames from "classnames";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import React, { useRef, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { WhoAreWeService } from "../../service/whoAreWeService/WhoAreWeService";

const ModifierSection = () => {

    const history = useNavigate();
    const toast = useRef(null);
    const [submittedSection, setSubmittedSection] = useState(false);


    const service = new WhoAreWeService();
    const { id } = useParams();
    const [sectionName, setSectionName] = useState();
    const [isDisplayed, setIsDisplayed] = useState("");

    const dropdownValues = [
        { name: 'Oui', code: 'oui' },
        { name: 'Non', code: 'non' },
    ];

    const get = async () => {
        service.getSection(id).then(res => {
            setSectionName(res.data.sectionName);
            setIsDisplayed(res.data.isDisplayed);
        });
    }

    useEffect(() => {
        get();
    }, []);

    const modifier = async (e) => {
        e.preventDefault();
        
        if(sectionName === "" || isDisplayed === "")
        {
            toast.current.show({ severity: "error", summary: "Merci de bien compléter les champs", life: 3000 });              
        }else{
            service.updateSection(id, { sectionName: sectionName, isDisplayed: isDisplayed }).then(() => {
                setSubmittedSection(true);
                toast.current.show({ severity: 'success', summary: 'Opération Achevée', detail: 'Section Modifiée', life: 3000 });
            });
        }
       
    }

    const gotosections = (e) => {
        e.preventDefault();
        history("/sections")
    }



    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button onClick={(e) => modifier(e)} label="Sauvegarder" icon="pi pi-fw pi-check" className="p-button-success mr-2" />
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button onClick={(e) => gotosections(e)} className="p-button-rounded p-button-outlined p-button-danger">
                    <i className="pi pi-times"></i>
                </Button>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <div className="grid" style={{marginTop:0}}>
                <Toast ref={toast} />
                <Toolbar className="mb-4 col-12" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                <div className="col-12 md:col-12 mt-4">
                    <div className="card p-fluid">
                        <h5>Modifier cette section</h5>
                        <form>
                            <div className="field">
                                <label htmlFor="sectionName">Nom de la section</label>
                                <InputText 
                                autoFocus className={classNames({'p-invalid': submittedSection && !sectionName})} 
                                value={sectionName || ''} onChange={(e) => setSectionName(e.target.value)} id="sectionName" type="text" />
                                {submittedSection && !sectionName && <small className="p-invalid">Champ Obligatoire.</small>}

                            </div>

                            <div className="field">
                                <label className="mb-3">Afficher Cette Section à la page d'accueil</label>
                                <Dropdown 
                                name="isDisplayed" value={isDisplayed || ''} onChange={(e) => setIsDisplayed(e.target.value)} options={dropdownValues} optionValue="name" optionLabel="name" placeholder="Selectionner Votre Valeur" />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )

}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps?.location?.pathname === nextProps?.location?.pathname;
};

export default React.memo(ModifierSection, comparisonFn);
