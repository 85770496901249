import React, { useState, useEffect } from 'react';
import { FeedbackService } from '../../service/FeedbackService/FeedbackService';

function RecentReviews(props) {

    const service_ = new FeedbackService();
    const [items_, setItems_] = useState([]);


    const fetchRecent_ = () => {
        service_.recent().then((res) => {
            setItems_(res.data);
        })
    }

    useEffect(() => {
        fetchRecent_();
    },[]);
    return (
        <ul className="p-0 mx-0 mt-0 mb-4 list-none">
            {
                items_.map((item_, index_) => (
                    <li key={index_} className="flex align-items-center py-2 border-bottom-1 surface-border">
                        <div className="w-3rem h-3rem flex align-items-center justify-content-center bg-blue-100 border-circle mr-3 flex-shrink-0">
                            <i className="pi pi-user text-xl text-blue-500" />
                        </div>
                        <span className="text-900 line-height-3"> <b>{item_.fullname} : </b>    &nbsp;
                            <span className="text-700">
                                <span className="text-blue-500">
                                    {item_.subject} :
                                </span>
                            </span>
                            <b style={{ color: "green" }}> &nbsp; {item_.message}</b>

                        </span>
                    </li>
                ))
            }
        </ul>
    );
}

export default RecentReviews;
