import React, { useState, useEffect ,useRef} from "react";
import { BookingService } from "../../service/bookkingService/bookingService";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import ShowMoreText from "react-show-more-text";
import Moment from "react-moment";

const BookingStat = () => {

    const service = new BookingService();

    const [items, setItems] = useState([]);
    const executeOnClick = (isExpanded) => {
        console.log(isExpanded);
    }


    const recent = () => {

        service.recent().then((res)=>{
            setItems(res);
        })

    }

    useEffect(()=>{
          recent();
    },[]);

    const fullnameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Nom du client</span>
                <span> {rowData?.fullname}</span>
            </>
        );
    }

    const emailBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Email</span>
                <span> {rowData?.email}</span>
            </>
        );
    }

    const titleBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Type de chambre</span>
                <span> {rowData?.title}</span>
            </>
        );
    }

    const phonenumberBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Téléphone</span>
                <span>{rowData?.phonenumber}</span>
            </>
        );
    }



    const currentBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Date courante</span>
                <Moment format="YYYY/MM/DD">
                    {rowData?.current}
                </Moment>
            </>
        );
    }

    const endDateBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Date de départ</span>
                <Moment format="YYYY/MM/DD">
                    {rowData?.end_date}
                </Moment>
            </>
        );
    }


    const bookingDateBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Date d'arrivée</span>
                <Moment format="YYYY/MM/DD">
                    {rowData?.date_booking}
                </Moment>
            </>
        );
    }




    const countryBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Pays</span>
                {rowData?.country}
            </>
        );
    }


    const messageBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Détails</span>
                <ShowMoreText

                    lines={2}
                    more="Voir Plus"
                    less="Voir Moins"
                    className="text-justify"
                    anchorClass="my-anchor-css-class"
                    onClick={executeOnClick}
                    expanded={false}
                    width={280}
                    truncatedEndingComponent={"... "}
                >
                    <b> {rowData?.description}</b>
                </ShowMoreText>

            </>
        );
    }


    const typeChambreBodyTemplate = (rowData) => {
        return (
            <>
            <span className="p-column-title">Type de chambre</span>
            {rowData?.qte_single === ("") ? <span> <b>{rowData?.single}</b> - Nombre  : 0</span> : <span> <b>{rowData?.single}</b> - Nombre  : {rowData?.qte_single}</span>}
                 <br/>
                 {rowData?.qte_double === ("") ? <span> <b>{rowData?.double_}</b> - Nombre  : 0</span> : <span> <b>{rowData?.double_}</b> - Nombre  : {rowData?.qte_double}</span>}
                <br/>
                {rowData?.qte_triple === ("") ? <span> <b>{rowData?.triple}</b> - Nombre  : 0</span> : <span> <b>{rowData?.triple}</b> - Nombre  : {rowData?.qte_triple}</span>}
                <br/>
                {rowData?.qte_quadriple === ("") ? <span> <b>{rowData?.quadriple}</b> - Nombre  : 0</span> : <span> <b>{rowData?.quadriple}</b> - Nombre  : {rowData?.qte_quadriple}</span>}
                <br/>

        </>
        );
    }


    const personnesChambreBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Nombre de personnes</span>
                <span> Adultes : {rowData?.nb_adultes === "" ? <p>0</p> : <p>{rowData?.nb_adultes}</p> } </span> <br/>
                <span> Enfants : {rowData?.nb_enfants === "" ? <p>0</p>: <p>{rowData?.nb_enfants}</p>} </span> <br/>
                <span> Bébés : {rowData?.nb_bébés === "" ? <p>0</p>:<p>{rowData?.nb_bébés}</p>} </span> <br/>


            </>
        );
    }



    const dt = useRef(null);


    return (
      
        <React.Fragment>
  <div className="flex align-items-center justify-content-between mb-4">
                        <h5>Demandes de réservation</h5>
                    </div>                    <DataTable ref={dt} value={items}
                        dataKey="id" paginator rows={5} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Affichage {first} du {last} au {totalRecords} items"
                         emptyMessage="Aucun élément n'a été trouvé." responsiveLayout="scroll">

                        <Column field="fullname" header="Nom du client" sortable body={fullnameBodyTemplate} headerStyle={{ width: '20%', minWidth: '10rem' }}></Column>
                        <Column field="country" header="Pays" sortable body={countryBodyTemplate} headerStyle={{ width: '20%', minWidth: '10rem' }}></Column>
                        <Column field="email" header="Email" sortable body={emailBodyTemplate} headerStyle={{ width: '20%', minWidth: '10rem' }}></Column>
                        <Column field="phonenumber" header="Téléphone" body={phonenumberBodyTemplate} headerStyle={{ width: '20%', minWidth: '10rem' }}></Column>
                        <Column field="title" header="Objet de la réservation" body={titleBodyTemplate} sortable headerStyle={{ width: '20%', minWidth: '8rem' }}></Column>
                        <Column field="type" header="Type de chambre" body={typeChambreBodyTemplate} sortable headerStyle={{ width: '20%', minWidth: '8rem' }}></Column>
                        <Column field="message" header="Autre Informations" body={messageBodyTemplate} sortable headerStyle={{ width: '20%', minWidth: '8rem' }}></Column>
                        <Column field="personnes" header="Nombre de personnes" body={personnesChambreBodyTemplate} sortable headerStyle={{ width: '20%', minWidth: '8rem' }}></Column>
                        <Column field="date_booking" header="Date d'arrivée" body={bookingDateBodyTemplate} sortable headerStyle={{ width: '20%', minWidth: '8rem' }}></Column>
                        <Column field="end_date" header="Date de départ" body={endDateBodyTemplate} sortable headerStyle={{ width: '20%', minWidth: '8rem' }}></Column>


                    </DataTable>

                    </React.Fragment>

           
    );

}

export default React.memo(BookingStat);
