import { Button } from "primereact/button";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Toolbar } from "primereact/toolbar";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { SpaService } from "../../service/SpaService/SpaService";
import { Dropdown } from "primereact/dropdown";
import classNames from "classnames";
import DEV from "../../url";
const AssignSpaImages = () => {
    const history = useNavigate();
    const toast = useRef(null);
    const [submitted,setSubmitted]=useState(false);

    const service = new SpaService();
    const { id } = useParams();

    const [tag, setTag] = useState("");
    const [img, setImg] = useState("");

    const [listImg, setListImg] = useState([]);

    const getSpa = async () => {
        service.get(id).then(() => {
            console.log("");
        });
    };

    useEffect(() => {
        getSpa();
    }, []);

    const affecter = async (e) => {
        e.preventDefault();
        setSubmitted(true);
        const formData = new FormData();
        formData.append("tag", tag);
        formData.append("img", img);

        if (tag === "" || img === "") {
            toast.current.show({ severity: "error", summary: "Merci de bien compléter les champs", life: 3000 });
        } else {
            service.assignImg(id, formData, {}).then(() => {
                toast.current.show({ severity: "success", summary: "Opération Achevée", detail: "Vous avez affecté une image", life: 3000 });
                window.location.reload(false);
            });
        }
    };

    const afficherImages = async () => {
        await service.getSpaImg().then((res) => {
            setListImg(res?.data);
        });
    };

    useEffect(() => {
        afficherImages();
    }, []);

    const supprimerImage = async (idSpa, e) => {
        e.preventDefault();
        service.deleteSpaImg(idSpa).then((res) => {
            toast.current.show({ severity: "success", summary: "Opération Achevée", detail: "Vous avez supprimé une image ", life: 3000 });
            window.location.reload(false);
            
            const trash = listImg.filter(() => res !== idSpa);
            setListImg(trash);
        });
    };

    const handleUploadChange = (e) => {
        if (e.target.files[0]) {
            setImg(e.target.files[0]);
        }
    };

    const goto = (e) => {
        e.preventDefault();
        history("/hammam_spa");
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button onClick={(e) => affecter(e)} label="Assigner" icon="pi pi-fw pi-check" className="p-button-success mr-2" />
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button onClick={(e) => goto(e)} className="p-button-rounded p-button-outlined p-button-danger">
                    <i className="pi pi-times"></i>
                </Button>
            </React.Fragment>
        );
    };

    const dropdownValues = [
        { name: "Hammam", code: "ham" },
        { name: "Spa", code: "spa" },
    ];

    return (
        <React.Fragment>
            <div className="grid" style={{ marginTop: 0 }}>
                <Toast ref={toast} />
                <Toolbar className="mb-4 col-12" right={rightToolbarTemplate}></Toolbar>

                <div className="col-12 md:col-12 mt-4">
                    <div className="card p-fluid">
                        <h5>Affecter des images à votre Hammam & Spa</h5>
                        <form>
                            <div className="field">
                                <label htmlFor="tag">Tag</label>
                                <Dropdown 
                                autoFocus className={classNames({ "p-invalid": submitted && !tag })} 
                                name="tag" value={tag} onChange={(e) => setTag(e.target.value)} options={dropdownValues} optionValue="name" optionLabel="name" placeholder="Selectionner Votre Tag" />
                                
                                {submitted && !tag && <small className="p-invalid">Champ Obligatoire.</small>}

                            </div>
                            <div className="field">
                                <label htmlFor="img">Image</label>
                                <InputText 
                                autoFocus className={classNames({ "p-invalid mt-3": submitted && !img })} 
                                onChange={handleUploadChange}  id="img" type="file" />
                               
                                {submitted && !img && <small className="p-invalid">Champ Obligatoire.</small>}

                                <div className="mt-3" style={{ borderRadius: 5, height: "auto", border: "1px solid green", backgroundColor: "#B7E5B0", color: "black" }}>
                                    <p className="pt-2 mx-3">
                                        <i className="pi pi-fw pi-file"></i> <b>A noter :</b> <br />
                                        - L'image est un champ obligatoire. <br />- Le nom de l'image doit être sous format <b>jpeg, png ou bien jpg</b>. <br />- Le nom de l'image ne doit pas contenir des éspaces.
                                    </p>
                                </div>
                                <div align="center">
                                    <div align="center">
                                        <img src={img} alt="hammam spa" height={100} width="auto" />
                                    </div>
                                </div>
                                <div align="center" className="mt-5">
                                    <Toolbar className="mb-4 col-12 justify-content-center" left={leftToolbarTemplate}></Toolbar>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div className="col-12 md:col-12 mt-4">
                    <div className="card p-fluid">
                        <h5>Voir les images</h5>
                        <div className="grid">
                            {listImg.map((itemSpa, index) => (
                               
                                        <div key={index} className="field col">
                                            <img height={150} width="auto" src={`${DEV}uploads/hammams/${itemSpa?.img}`} />
                                            <br />
                                            <Button onClick={(e) => supprimerImage(itemSpa?.idSpa, e)} className="p-button-rounded p-button-danger p-button-text">
                                                <i className="pi pi-times"></i> &nbsp; Retirer
                                            </Button>
                                        </div>
                                    
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps?.location?.pathname === nextProps?.location?.pathname;
};

export default React.memo(AssignSpaImages, comparisonFn);
