import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
var firebaseConfig = {
  apiKey: "AIzaSyDWeOP9nSrEy4wNcXKGGgUZ02H3Tc85iyg",
  authDomain: "darzaghouane2022.firebaseapp.com",
  projectId: "darzaghouane2022",
  storageBucket: "darzaghouane2022.appspot.com",
  messagingSenderId: "212830038203",
  appId: "1:212830038203:web:bf8124c38c9191d833219e",
  measurementId: "G-V1ET2DRZ0L"
};

firebase.initializeApp(firebaseConfig);

const storage = firebase.storage();

export { storage, firebase as default };