import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { BannerService } from '../../../service/ConfigServiceApp/BannerService/BannerService';

const Banners = () => {

    let emptyBanner = {
        id: 0,
        title: '',
        subtitle: '',
        text: '',
        img: ''

    };

    //new
    const bannerService = new BannerService();
    const [banners, setBanners] = useState([]);
    const [deleteBannerDialog, setDeleteBannerDialog] = useState(false);
    const [banner, setBanner] = useState(null);
    const [deleteBannersDialog, setDeleteBannersDialog] = useState(false);
    const [bannerDialog, setBannerDialog] = useState(false);
    const [submittedBanner, setSubmittedBanner] = useState(false);

    const [title, setTitle] = useState("");
    const [subtitle, setSubtitle] = useState("");
    const [text, setText] = useState("");
    const [img, setImg] = useState("");

   //new

    useEffect(() => {
        bannerService.getBanners().then((res) => {
            toast.current.show({ severity: 'success', summary: 'Opération Achevée', detail: 'Opération effectuée avec succés', life: 3000 });
            setBanners(res);
        });
    }, []);


    const hideDeleteBannerDialog = () => {
        setDeleteBannerDialog(false);
    }

    const hideDeleteBannersDialog = () => {
        setDeleteBannersDialog(false);
    }

    const deleteBanner = (e, banner) => {
        e.preventDefault();
        setBanner({ ...banner });
        setDeleteBannerDialog(true);
    }


    const confirmDeleteBanner = async (e, id) => {
        e.preventDefault();
        await bannerService.deleteBanner(id).then((res) => {
            toast.current.show({ severity: 'success', summary: 'Opération Achevée', detail: 'Cet item a été supprimée', life: 3000 });
            setDeleteBannerDialog(false);
            const trash = banners.filter(() => res?.result !== id);
            setBanners(trash);
            window.location.reload(false);
        }, []);

    }

    const openNewBanner = () => {
        setBanner(emptyBanner);
        setSubmittedBanner(false);
        setBannerDialog(true);
    }

    const saveBanner = async () => {
        setSubmittedBanner(true);
        const formData = new FormData()
        formData.append('title', title)
        formData.append('subtitle', subtitle)
        formData.append('text', text)
        formData.append('img', img)
        if(title===""||subtitle===""||text===""||img==="")
        {
            toast.current.show({ severity: "error", summary: "Merci de bien compléter les champs", life: 3000 });
            setBannerDialog(true);

        }else{
            await bannerService.createBanner(formData,{  }).then(() => {
                toast.current.show({ severity: 'success', summary: 'Opération Achevée', detail: 'Item Créé', life: 3000 });
                setBanners(banners);
                setBannerDialog(false);
                window.location.reload(false);
            });
        }

      

    }

    const hideDialogBanner = () => {
        setSubmittedBanner(false);
        setBannerDialog(false);
    }


    const handleUploadChange = e => {
        if (e.target.files[0]) {
            setImg(e.target.files[0]);
        }
    }


    const deleteAllBanners = () => {
        bannerService.deleteBanners().then((res) => {
            setBanners(null);
            setDeleteBannersDialog(false);
            toast.current.show({ severity: 'success', summary: 'Succés', detail: 'Les items sont supprimés avec succées', life: 3000 });
            window.location.reload(false);
        });

    }



    //END



    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);


    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const confirmDeleteSelected = () => {
        setDeleteBannersDialog(true);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Nouvelle banniére" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNewBanner} />
                </div>
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Exporter en CSV" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        )
    }

    const titleBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Titre</span>
                <b> {rowData.title}</b>
            </>
        );
    }

    const subtitleBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Sous Titre</span>
                        {rowData.subtitle}


            </>
        );
    }



    const imgBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Couverture</span>
                <img src={`https://restapi.darzaghouane.com/uploads/banners/${rowData?.img}`} height={140} width={250} />
            </>
        );
    }


    const textBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Text</span>
                {rowData.text}
            </>
        );
    }


    // END



    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <a href={`/#/banner/modifier/${rowData.id}`}>    <Button  title='Modifier cet item' icon="pi pi-pencil"  className="p-button-rounded p-button-success mr-1">
                </Button> </a>
                 <a href={`/#/banner/modifierImage/${rowData.id}`}>    <Button  title='Modifier Couverture' icon="pi pi-upload"  className="p-button-rounded p-button-warning mr-1">
                </Button> </a>
                <Button title='Supprimer cet item' icon="pi pi-trash" className="p-button-rounded p-button-danger mt-2 mr-1" onClick={(e) => deleteBanner(e, rowData)} />

            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Couvertures</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Cherchez ..." />
            </span>
        </div>
    );

    const bannerDialogFooter = (
        <>
            <Button label="Annuler" icon="pi pi-times" className="p-button-text" onClick={hideDialogBanner} />
            <Button label="Sauvegarder" icon="pi pi-check" className="p-button-text" onClick={saveBanner} />
        </>
    );


    const deleteBannerDialogFooter_ = (rowData) => (
        <>
            <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={hideDeleteBannerDialog} />
            <Button label="Oui" icon="pi pi-check" className="p-button-text" onClick={(e) => confirmDeleteBanner(e, rowData.id)} />
        </>
    );

    const deleteBannersDialogFooter = (
        <>
            <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={hideDeleteBannersDialog} />
            <Button label="Oui" icon="pi pi-check" className="p-button-text" onClick={deleteAllBanners} />
        </>
    );


    return (
        <div className="grid crud-demo" style={{marginTop:0}}>
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>


                    <DataTable ref={dt} value={banners}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Affichage {first} du {last} au {totalRecords} éléments"
                        globalFilter={globalFilter} emptyMessage="Aucune banniére n'a été trouvée." header={header} responsiveLayout="scroll">

                        <Column field="title" header="Titre" sortable body={titleBodyTemplate} headerStyle={{ width: '20%', minWidth: '10rem' }}></Column>
                        <Column field="dateAc" header="Sous titre" sortable body={subtitleBodyTemplate} headerStyle={{ width: '20%', minWidth: '10rem' }}></Column>
                        <Column header="Image" body={imgBodyTemplate} headerStyle={{ width: '20%', minWidth: '10rem' }}></Column>
                        <Column field="text" header="Description" body={textBodyTemplate} sortable headerStyle={{ width: '20%', minWidth: '8rem' }}></Column>
                        <Column headerStyle={{ width: '20%', minWidth: '10rem' }} body={actionBodyTemplate}></Column>
                    </DataTable>


                    <Dialog visible={bannerDialog} style={{ width: '450px' }} header="Créer une banniére" modal className="p-fluid" footer={bannerDialogFooter} onHide={hideDialogBanner}>
                        <div className="field">
                            <label htmlFor="title">Titre</label>
                            <InputText id="title" value={title} onChange={(e) => setTitle(e.target.value)} required autoFocus className={classNames({ 'p-invalid': submittedBanner && !title })} />
                            {submittedBanner && !title && <small className="p-invalid">Champ Obligatoire.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="subtitle">Sous Titre</label>
                            <InputText id="subtitle" type="text" value={subtitle} onChange={(e) => setSubtitle(e.target.value)} required autoFocus className={classNames({ 'p-invalid': submittedBanner && !subtitle })} />
                            {submittedBanner && !subtitle && <small className="p-invalid">Champ Obligatoire.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="text">Description</label>
                            <InputTextarea id="text" value={text} onChange={(e) => setText(e.target.value)} required rows={3} cols={20} />
                        </div>

                        <div className="field">
                            <label htmlFor="image">Couverture</label>
                            <InputText
                                type="file"
                                id="img"
                                name="img"
                                onChange={handleUploadChange}
                                required
                                autoFocus className={classNames({ "p-invalid mt-3": submittedBanner && !img })}

                            />
                         {submittedBanner && !img && <small className="p-invalid">Champ Obligatoire.</small>}

                            <div align="center">

                                            <div className='mt-3' align="center">
                                                <img required={true}  height={140} width="auto" src={img} alt="image-banniére" />
                                            </div>
                                            <div className='mt-3' style={{borderRadius:5, height:"auto",border:'1px solid green',backgroundColor:"#B7E5B0",color:"black"}}>
                                                <p className='pt-2'>  
                                                L'image est un champ obligatoire.
                                                Le nom de l'image doit être sous format <b>jpeg, png ou bien jpg</b>.
                                                Le nom de l'image ne doit pas contenir des éspaces.</p>
                                            </div>
                            </div>


                        </div>

                    </Dialog>
                    <Dialog visible={deleteBannerDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteBannerDialogFooter_(banner)} onHide={hideDeleteBannerDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {banner && <span>Êtes-vous sûr de vouloir supprimer cette couverture : {banner.title} ?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteBannersDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteBannersDialogFooter} onHide={hideDeleteBannersDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                             <span>Êtes-vous sûr de vouloir supprimer toutes vos items ?</span>
                        </div>
                    </Dialog>



                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps?.location?.pathname === nextProps?.location?.pathname;
};

export default React.memo(Banners, comparisonFn);
