import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { RestaurantService } from "../../service/restaurantService/restaurantService";
import classNames from "classnames";
const ModifierMenuCard = () => {
    const history = useNavigate();
    const toast = useRef(null);
    const { id } = useParams();

    const restaurantService = new RestaurantService();
    const [visibility, setVisibility] = useState("");
    const [file_src, setFile_src] = useState("");
    const [submittedRestaurant, setSubmittedRestaurant] = useState(false);

    const getMenuCard = async () => {
        restaurantService.getMenuCardById(id).then((res) => {
            setFile_src(res.file_src);
            setVisibility(res.visibility);
            console.log(visibility);
        });
    };

    const modifierRestaurant = async (e) => {
        e.preventDefault();
        setSubmittedRestaurant(true);
        const formData = new FormData();
        formData.append("file_src", file_src);
       // formData.append('visibility', visibility)
        if (file_src === "") {
            toast.current.show({ severity: "error", summary: "Merci de bien compléter les champs", life: 3000 });
        } else {
            restaurantService.updateMenuCardById(id, formData, {}).then(() => {
                toast.current.show({ severity: "success", summary: "Opération Achevée", detail: "Menu Modifiée", life: 3000 });
            });
        }
    };

    useEffect(() => {
        getMenuCard();
    }, []);

    const handleUploadChange = (e) => {
        if (e.target.files[0]) {
            setFile_src(e.target.files[0]);
        }
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button onClick={(e) => modifierRestaurant(e)} label="Sauvegarder" icon="pi pi-fw pi-check" className="p-button-success mr-2" />
            </React.Fragment>
        );
    };

    const gotRestaurants = (e) => {
        e.preventDefault();
        history("/menu_card");
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button onClick={(e) => gotRestaurants(e)} className="p-button-rounded p-button-outlined p-button-danger">
                    <i className="pi pi-times"></i>
                </Button>
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            <div className="grid" style={{ marginTop: 0 }}>
                <Toast ref={toast} />
                <Toolbar className="mb-4 col-12" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                <div className="col-12 md:col-12 mt-4">
                    <div className="card p-fluid">
                        <h5>Modifier Cette Carte Menu</h5>
                        <form>
                            <div className="field">
                                <label htmlFor="file_src">Image</label>
                                <InputText
                                autoFocus className={classNames({ "p-invalid mt-3": submittedRestaurant && !file_src })} 
                                onChange={handleUploadChange}  id="file_src" name="file_src" type="file" />
                                {submittedRestaurant && !file_src && <small className="p-invalid">Champ Obligatoire.</small>}

                                <div className="mt-3" style={{ borderRadius: 5, height: "auto", border: "1px solid green", backgroundColor: "#B7E5B0", color: "black" }}>
                                    <p className="pt-2 mx-3">
                                        <i className="pi pi-fw pi-file"></i> <b>A noter :</b> <br />
                                        - L'image est un champ obligatoire. <br />- Le nom de l'image doit être sous format <b>jpeg, png ou bien jpg</b>. <br />- Le nom de l'image ne doit pas contenir des éspaces.
                                    </p>
                                </div>

                                <div align="center">
                                    <React.Fragment>
                                        <div align="center">
                                            <img src={file_src} id="file_src" name="file_src" alt="menu" height={100} width="auto" />
                                        </div>
                                    </React.Fragment>{" "}
                                    :{" "}
                                    <React.Fragment>
                                        <div align="center">
                                            <img height={100} width="auto" src={file_src} alt="img" />
                                        </div>{" "}
                                    </React.Fragment>
                                </div>
                            </div>

                            {/*   <div className="field">
                                <label className="mb-3">Visibilité</label>
                                <Dropdown name="visibility"
                                value={visibility}
                                onChange={(e) => setVisibility(e.target.value)}
                                options={dropdownValues}
                                optionValue="code"
                                optionLabel="name"
                                placeholder="Selectionner la Visibilité" />
                            </div> */}
                        </form>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps?.location?.pathname === nextProps?.location?.pathname;
};

export default React.memo(ModifierMenuCard, comparisonFn);
