import axios from 'axios';
import DEV from '../../url';
export class ChannelService {


    create(data){
        return axios.post(`${DEV}channel/create`,data).then((res)=>res.data);
    }

    createVideo(id,data){
        return axios.post(`${DEV}video/create/`+id,data).then((res)=>res.data);
    }


    getByChannel(id){
        return axios.get(`${DEV}video/getByChannelID/`+id).then((res)=>res.data);
    }

    deleteVideo(id){
        return axios.delete(`${DEV}video/deleteVideo/`+id);
    }

    updateChannel(id,data){
        return axios.put(`${DEV}channel/update/${id}`, data).then(res=> res.data);
    }

    updateItem(id,data){
        return axios.put(`${DEV}channel/updateImage/${id}`, data).then(res=> res.data);
    }

    get(id){
        return axios.get(`${DEV}channel/get/${id}`).then((res)=>res.data);
    }

    all(){
        return axios.get(`${DEV}channel/all`).then((res)=>res.data);
    }

    delete(id){
        return axios.delete(`${DEV}channel/delete/`+id).then((res)=>res.data);
    }
}
