import React, {useEffect, useRef, useState} from 'react';
import classNames from 'classnames';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Toast} from 'primereact/toast';
import {Button} from 'primereact/button';
import {Toolbar} from 'primereact/toolbar';
import {InputTextarea} from 'primereact/inputtextarea';
import {Dialog} from 'primereact/dialog';
import {InputText} from 'primereact/inputtext';
import {RoomsService} from '../../service/roomsService/RoomsService';
import {Dropdown} from 'primereact/dropdown';
import ShowMoreText from "react-show-more-text";
import DEV from '../../url';

const Hébergement = () => {

    let empty = {
        id: 0,
        title: '',
        price: '',
        description: '',
        img: '',
        type: '',
        options: '',
        rules: '',
        maxCapacity: 0,

    };

    const service_ = new RoomsService();
    const [room, setRoom] = useState(null);
    const [dialog, setDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [rooms, setRooms] = useState([null]);
    const [deleteDialog, setDeleteDialog] = useState(false);

    const [title, setTitle] = useState("");
    const [price, setPrice] = useState("");
    const [description, setDescription] = useState("");
    const [img, setImg] = useState("");
    const [type, setType] = useState("");
    const [options, setOptions] = useState("");
    const [rules, setRules] = useState([]);
    const [maxCapacity, setMaxCapacity] = useState(0);


    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);


    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const dropdownValues = [
        {name: 'Chalet', code: 'cha'},
        {name: 'Suite', code: 'st'},
        {name: 'Chambre Hôte', code: 'ch'},
        {name: 'Gîte', code: 'gt'},


    ];

    useEffect(() => {
        service_.all().then((res) => {
            toast.current.show({severity: 'success', summary: 'Opération Achevée', detail: 'Opération effectuée avec succés', life: 3000});
            setRooms(res);
        });
    }, []);

    const hideDeleteDialog = () => {
        setDeleteDialog(false);
    }

    const delete_ = (e, event) => {
        e.preventDefault();
        setRoom({...event});
        setDeleteDialog(true);
    }

    const confirmDelete = async (e, id) => {
        e.preventDefault();
        await service_.deletebyid(id).then((res) => {
            toast.current.show({severity: 'success', summary: 'Opération Achevée', detail: 'Cet Hébérgement a été supprimé', life: 3000});
            setDeleteDialog(false);
            const trash = rooms.filter(() => res?.result !== id);
            setRooms(trash);
            window.location.reload(false);
        }, []).catch(()=>{
            toast.current.show({ severity: 'error', summary: 'Opération échouée', detail: "L'hébérgement que vous venez de le supprimer, contient d'autres images. Merci de les supprimer avant.", life: 6000 });
        });

    }

    const openNew = () => {
        setRoom(empty);
        setSubmitted(false);
        setDialog(true);
    }

    const save = () => {
        setSubmitted(true);
        const formData = new FormData()
        formData.append('title', title)
        formData.append('price', price)
        formData.append('description', description)
        formData.append('img', img)
        formData.append('type', type)
        formData.append('options', options)
        formData.append('rules', rules)
        formData.append('maxCapacity', maxCapacity)

        if(title===""||img===""||type===""||options===""||rules===""||maxCapacity===0){
            toast.current.show({ severity: "error", summary: "Merci de bien compléter les champs", life: 3000 });
            setDialog(true);
        }else{
            service_.post(formData, {}).then(() => {
            toast.current.show({severity: 'success', summary: 'Opération Achevée', detail: 'Hébérgement Ajouté', life: 3000});
            setRooms(rooms);

            window.location.reload(false);        
            setDialog(false);

        });
        }
        


    }

    const hideDialog = () => {
        setSubmitted(false);
        setDialog(false);
    }

    const handleUploadChange = e => {
        if (e.target.files[0]) {
            setImg(e.target.files[0]);
            toast.current.show({severity: 'success', summary: 'Opération Achevée', detail: 'Image Choisie', life: 3000});

        }
    }

    const executeOnClick = (isExpanded) => {
        console.log(isExpanded);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Nouvel hébergement" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew}/>
                </div>
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Exporter en CSV" icon="pi pi-upload" className="p-button-help" onClick={exportCSV}/>
            </React.Fragment>
        )
    }

    const titleBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Titre</span>
                <b> {rowData?.title}</b>
            </>
        );
    }

     const priceBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Prix</span>
                 {
                   (rowData?.price === "")  ? <p  style={{color:"red"}}>Aucun prix n'est affiché</p> :  <b> {rowData?.price}{"DT"}</b>

                }
            </>
        );
    }

    const imgBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Image</span>
                <img alt="img-hebergement" src={`${DEV}uploads/rooms/${rowData?.img}`} height={120} width={200}/>
            </>
        );
    }

    const rulesBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Rules</span>
                <ShowMoreText

                    lines={1}
                    more="Voir Plus"
                    less="Voir Moins"
                    className="text-justify"
                    anchorClass="my-anchor-css-class"
                    onClick={executeOnClick}
                    expanded={false}
                    width={100}
                    truncatedEndingComponent={"... "}
                >
                    <b> {rowData?.rules}</b>
                </ShowMoreText>
            </>
        );
    }

    const maxCapacityBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Capacité Moyenne</span>
                {rowData?.maxCapacity}
            </>
        );
    }

    const typeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Type</span>
                {rowData?.type}
            </>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <a href={`/#/hebergement/affecter/` + rowData?.id}> <Button title='Affecter des images' icon="pi pi-images" className="p-button-rounded p-button-info mr-1">
                </Button> </a>
                <a href={`/#/hebergement/modifier/` + rowData?.id}> <Button title='Modifier cet hébérgement' icon="pi pi-pencil" className="p-button-rounded p-button-success mr-1">
                </Button> </a>
                <a href={`/#/hebergement/modifierImage/` + rowData?.id}> <Button title='Modifier Image' icon="pi pi-upload" className="p-button-rounded p-button-warning mr-1">
                </Button> </a>
                <Button title='Supprimer cet item' icon="pi pi-trash" className="p-button-rounded p-button-danger mt-2 mr-1" onClick={(e) => delete_(e, rowData)}/>

            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Hébérgements</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search"/>
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Cherchez ..."/>
            </span>
        </div>
    );

    const dialogFooter = (
        <>
            <Button label="Annuler" icon="pi pi-times" className="p-button-text" onClick={hideDialog}/>
            <Button label="Sauvegarder" icon="pi pi-check" className="p-button-text" onClick={save}/>
        </>
    );

    const deleteDialogFooter_ = (rowData) => (
        <>
            <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={hideDeleteDialog}/>
            <Button label="Oui" icon="pi pi-check" className="p-button-text" onClick={(e) => confirmDelete(e, rowData.id)}/>
        </>
    );


    return (
        <div className="grid crud-demo" style={{marginTop: 0}}>
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast}/>
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>


                    <DataTable ref={dt} value={rooms}
                               dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                               paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                               currentPageReportTemplate="Affichage {first} du {last} au {totalRecords} éléments"
                               globalFilter={globalFilter} emptyMessage="Aucun item n'a été trouvé." header={header} responsiveLayout="scroll">

                        <Column field="title" header="Titre" sortable body={titleBodyTemplate} headerStyle={{width: '20%', minWidth: '10rem'}}></Column>
                        <Column field="price" header="Prix" sortable body={priceBodyTemplate} headerStyle={{width: '20%', minWidth: '10rem'}}></Column>
                        <Column header="Image" body={imgBodyTemplate} headerStyle={{width: '20%', minWidth: '10rem'}}></Column>
                        <Column field="maxCapacity" header="Capacité Moyenne" body={maxCapacityBodyTemplate} sortable headerStyle={{width: '20%', minWidth: '8rem'}}></Column>
                        <Column field="type" header="Type" body={typeBodyTemplate} sortable headerStyle={{width: '20%', minWidth: '8rem'}}></Column>

                        <Column headerStyle={{width: '20%', minWidth: '10rem'}} body={actionBodyTemplate}></Column>
                    </DataTable>


                    <Dialog visible={dialog} style={{width: '450px'}} header="Ajouter un nouveau Hébérgement" modal className="p-fluid" footer={dialogFooter} onHide={hideDialog}>
                        <div className="field">
                            <label htmlFor="title">Titre</label>
                            <InputText id="title" value={title} onChange={(e) => setTitle(e.target.value)} required autoFocus className={classNames({'p-invalid': submitted && !title})}/>
                            {submitted && !title && <small className="p-invalid">Champ Obligatoire.</small>}
                        </div>

                        <div className="field">
                            <label htmlFor="price">Prix</label>
                            <InputText type={"text"} id="price" value={price} onChange={(e) => setPrice(e.target.value)} required 
                            autoFocus/>
                        </div>

                        <div className="field">
                            <label htmlFor="description">Description</label>
                            <InputTextarea id="description" value={description} onChange={(e) => setDescription(e.target.value)} required rows={3} cols={20}/>
                        </div>

                        <div className="field">
                            <label htmlFor="image">Image</label>
                            <InputText
                                type="file"
                                id="img"
                                name="img"
                                onChange={handleUploadChange}
                                autoFocus className={classNames({'p-invalid': submitted && !img})}
                                required={true}
                            />
                        {submitted && !img && <small className="p-invalid">Champ Obligatoire.</small>}

<div className='mt-3' style={{borderRadius:5, height:"auto",border:'1px solid green',backgroundColor:"#B7E5B0",color:"black"}}>
                                                <p className='pt-2 mx-3'>   
                                                <i className="pi pi-fw pi-file"></i> <b>A noter :</b> <br/>
                                                - L'image est un champ obligatoire. <br/>
                                                - Le nom de l'image doit être sous format <b>jpeg, png ou bien jpg</b>. <br/>
                                                - Le nom de l'image ne doit pas contenir des éspaces.</p>
                                </div>


                        </div>

                        <div className="field">
                            <label htmlFor="rules">Régles</label>
                            <InputTextarea 
                            autoFocus className={classNames({'p-invalid': submitted && !rules})}
                            id="rules" value={rules} onChange={(e) => setRules(e.target.value)} required rows={3} cols={20}/>
                            
                            {submitted && !rules && <small className="p-invalid">Champ Obligatoire.</small>}

                        </div>

                        <div className="field">
                            <label htmlFor="options">Options</label>
                            <InputTextarea 
                            autoFocus className={classNames({'p-invalid': submitted && !options})}
                            id="options" value={options} onChange={(e) => setOptions(e.target.value)} required rows={3} cols={20}/>
                            
                            {submitted && !options && <small className="p-invalid">Champ Obligatoire.</small>}

                        </div>

                        <div className="field">
                            <label htmlFor="title">Capacité Moyenne</label>
                            <InputText type="number" id="maxCapacity" value={maxCapacity} onChange={(e) => setMaxCapacity(e.target.value)} required autoFocus className={classNames({'p-invalid': submitted && !title})}/>
                            {submitted && !maxCapacity && <small className="p-invalid">Champ Obligatoire.</small>}
                        </div>

                        <div className="field">
                            <label className="mb-3">Type</label>
                            <Dropdown 
                            autoFocus className={classNames({'p-invalid': submitted && !type})}
                            name="type" value={type} onChange={(e) => setType(e.target.value)} options={dropdownValues} optionValue="name" optionLabel="name" placeholder="Selectionner Votre Type"/>
                        </div>


                    </Dialog>
                    <Dialog visible={deleteDialog} style={{width: '450px'}} header="Confirm" modal footer={deleteDialogFooter_(room)} onHide={hideDeleteDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{fontSize: '2rem'}}/>
                            {room && <span>Êtes-vous sûr de vouloir supprimer cet hébérgement : {room.title} ?</span>}
                        </div>
                    </Dialog>


                </div>
            </div>
        </div>
    )
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps?.location?.pathname === nextProps?.location?.pathname;
};

export default React.memo(Hébergement, comparisonFn);
