import Clock from 'react-clock';
import React,{useState,useEffect} from 'react';

function Hour() {
    const [value, setValue] = useState(new Date());

    useEffect(() => {
        const interval = setInterval(() => setValue(new Date()), 1000);

        return () => {
            clearInterval(interval);
        };
    }, []);
    return (
        <Clock className={"mt-3"}  value={value} />
    );
}

export default Hour;
