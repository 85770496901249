import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { TermsService } from "../../service/TermsService/TermsService";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState } from "draft-js";
import { convertToHTML } from "draft-convert";
import DOMPurify from "dompurify";
import classNames from "classnames";
const Terms = () => {
    let empty = {
        id: 0,
        description: "",
    };

    const service = new TermsService();
    const [row, setRow] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [dialog, setDialog] = useState(false);
    const [items, setItems] = useState([null]);

    const [deleteDialog, setDeleteDialog] = useState(false);

    const [title, setTitle] = useState("");

    const [description, setDescription] = useState("");

    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const [convertedContent, setConvertedContent] = useState(null);

    const handleEditorChange = (state) => {
        setEditorState(state);
        convertContentToHTML();
    };

    const convertContentToHTML = () => {
        let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
        setConvertedContent(currentContentAsHTML);
    };
    const createMarkup = (html) => {
        return {
            __html: DOMPurify.sanitize(html),
        };
    };

    const exportCSV = () => {
        dt.current.exportCSV();
    };

    useEffect(() => {
        service.all().then((res) => {
            toast.current.show({ severity: "success", summary: "Opération Achevée", detail: "Opération effectuée avec succés", life: 3000 });
            setItems(res);
        });
    }, []);

    const hideDeleteDialog = () => {
        setDeleteDialog(false);
    };

    const delete_ = (e, row) => {
        e.preventDefault();
        setRow({ ...row });
        setDeleteDialog(true);
    };

    const confirmDelete = async (e, id) => {
        e.preventDefault();
        await service.delete(id).then((res) => {
            toast.current.show({ severity: "success", summary: "Opération Achevée", detail: "Ce Contenu a été supprimé", life: 3000 });
            setDeleteDialog(false);
            const trash = items.filter(() => res?.result !== id);
            setItems(trash);
            window.location.reload(false);
        }, []);
    };

    const openNew = () => {
        setRow(empty);
        setDialog(true);
    };

    const save = () => {
        setSubmitted(true);
        if (title === "" || description === "") {
            toast.current.show({ severity: "error", summary: "Merci de bien compléter les champs", life: 3000 });
            setDialog(true);
        } else {
            service.create({ title, description: convertedContent }).then(() => {
                toast.current.show({ severity: "success", summary: "Opération Achevée", detail: "Contenu Créé", life: 3000 });
                setItems(items);
                window.location.reload(false);
                setDialog(false);
            });
        }
    };

    const hideDialog = () => {
        setDialog(false);
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Nouvelle régle" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                </div>
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Exporter en CSV" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        );
    };

    const descriptionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Contenu</span>
                <span dangerouslySetInnerHTML={createMarkup(rowData?.description)} />
            </>
        );
    };

    const titleBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Titre</span>
                {rowData?.title}
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <a href={`/#/term/modifier/` + rowData?.id}>
                    {" "}
                    <Button title="Modifier cet item" icon="pi pi-pencil" className="p-button-rounded p-button-success mr-1"></Button>{" "}
                </a>
                <Button title="Supprimer cet contenu" icon="pi pi-trash" className="p-button-rounded p-button-danger mt-2 mr-1" onClick={(e) => delete_(e, rowData)} />
            </div>
        );
    };

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Régles d'utilsation</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Cherchez ..." />
            </span>
        </div>
    );

    const dialogFooter = (
        <>
            <Button label="Annuler" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Sauvegarder" icon="pi pi-check" className="p-button-text" onClick={save} />
        </>
    );

    const deleteDialogFooter_ = (rowData) => (
        <>
            <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={hideDeleteDialog} />
            <Button label="Oui" icon="pi pi-check" className="p-button-text" onClick={(e) => confirmDelete(e, rowData.id)} />
        </>
    );

    return (
        <div className="grid crud-demo" style={{ marginTop: 0 }}>
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    <DataTable
                        ref={dt}
                        value={items}
                        dataKey="id"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Affichage {first} du {last} au {totalRecords} éléments"
                        globalFilter={globalFilter}
                        emptyMessage="Aucun item n'a été trouvé."
                        header={header}
                        responsiveLayout="scroll"
                    >
                        <Column field="description" header="Contenu" sortable body={descriptionBodyTemplate} headerStyle={{ width: "40%", height: "100%", minWidth: "10rem" }}></Column>
                        <Column headerStyle={{ width: "20%", minWidth: "10rem" }} body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={dialog} style={{ width: "450px" }} header="Ajouter des Régles" modal className="p-fluid" footer={dialogFooter} onHide={hideDialog}>
                        <div className="field">
                            <label htmlFor="description">Contenu</label>
                            <Editor autoFocus className={classNames({ "p-invalid mt-3": submitted && !description })} editorState={editorState} onEditorStateChange={handleEditorChange} wrapperClassName="wrapper-class" editorClassName="editor-class" toolbarClassName="toolbar-class" />
                            {submitted && !description && <small className="p-invalid">Champ Obligatoire.</small>}
                        </div>
                    </Dialog>
                    <Dialog visible={deleteDialog} style={{ width: "450px" }} header="Confirm" modal footer={deleteDialogFooter_(row)} onHide={hideDeleteDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                            {row && <span>Êtes-vous sûr de vouloir supprimer ce contenu ?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps?.location?.pathname === nextProps?.location?.pathname;
};

export default React.memo(Terms, comparisonFn);
