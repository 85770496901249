import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { ContactInfoService } from '../../../service/ConfigServiceApp/ContactInformations/contactInfoService';
import { Dropdown } from 'primereact/dropdown';
import iconJson from "../../Contact/ContactInfos/icons.json"
const ContactInfos = () => {

    let empty = {
        id: 0,
        title: '',
        icon: '',
        content: '',
        phonenumber: '',

    };

    const service_ = new ContactInfoService();
    const [contactInfo, setContactInfo] = useState(null);
    const [dialog, setDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [contactInfos, setContactInfos] = useState([]);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [deleteActivitiesDialog, setDeleteActivitiesDialog] = useState(false);
    const [title, setTitle] = useState("");
    const [icon, setIcon] = useState("");
    const [content, setContent] = useState("");
    const [phonenumber, setPhonenumber] = useState("");
    const [icons,setIcons]=useState([]);


    useEffect(() => {
        setIcons(iconJson);
        console.log("icons",icons)
    }, []);

    useEffect(() => {
        service_.getContactInfos().then((res) => {
            toast.current.show({ severity: 'success', summary: 'Opération Achevée', detail: 'Opération effectuée avec succés', life: 3000 });
            setContactInfos(res);
        });
    }, []);

    const hideDeleteDialog = () => {
        setDeleteDialog(false);
    }

    const delete_ = (e, event) => {
        e.preventDefault();
        setContactInfo({ ...event });
        setDeleteDialog(true);
    }

    const confirmDelete = async (e, id) => {
        e.preventDefault();
        await service_.deleteContactInfo(id).then((res) => {
            toast.current.show({ severity: 'success', summary: 'Opération Achevée', detail: 'Ce Contact a été supprimé', life: 3000 });
            setDeleteDialog(false);
            const trash = contactInfos.filter(() => res?.result !== id);
            setContactInfos(trash);
            window.location.reload(false);
        }, []);

    }



    const openNew = () => {
        setContactInfo(empty);
        setSubmitted(false);
        setDialog(true);
    }

    const save = () => {
        setSubmitted(true);
        service_.createContactInfo({ title,icon, content, phonenumber }).then(() => {
            toast.current.show({ severity: 'success', summary: 'Opération Achevée', detail: 'Contact Créé', life: 3000 });
            setContactInfos(contactInfos);
            window.location.reload(false);
        });

        setDialog(false);

    }

    const hideDialog = () => {
        setSubmitted(false);
        setDialog(false);
    }

    const executeOnClick = (isExpanded) => {
        console.log(isExpanded);
    }


    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);


    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const confirmDeleteSelected = () => {
        setDeleteActivitiesDialog(true);
    }
/* 
    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Nouveau" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                </div>
            </React.Fragment>
        )
    } */

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        )
    }

    const titleBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Titre</span>
                <b> {rowData.title}</b>
            </>
        );
    }

    const iconBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Icone</span>
                <i className={`pi `+rowData.icon} aria-hidden="true"></i>
            </>
        );
    }


    const iconToDisplayBodyTemplate =  () =>{
        return (
            <>
                {icons.map((icon,index)=>(
                    <div key={index}>
                      <i  className={`pi `+icon.name} aria-hidden="true"></i></div>
                ))
                }
        </>
        );
    }

    const contentBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Contenu</span>
                {rowData.content}
            </>
        );
    }

    const phoneNumberBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Téléphone</span>
                {rowData.phonenumber}
            </>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <a href={`/#/contactInfos/modifier/${rowData?.id}`}>    <Button title='Modifier ce contact' icon="pi pi-pencil" className="p-button-rounded p-button-success mr-1">
                </Button> </a>
                <Button title='Supprimer ce contact' icon="pi pi-trash" className="p-button-rounded p-button-danger mt-2 mr-1" onClick={(e) => delete_(e, rowData)} />

            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Informations de contact</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const dialogFooter = (
        <>
            <Button label="Annuler" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Sauvegarder" icon="pi pi-check" className="p-button-text" onClick={save} />
        </>
    );


    const deleteDialogFooter_ = (rowData) => (
        <>
            <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={hideDeleteDialog} />
            <Button label="Oui" icon="pi pi-check" className="p-button-text" onClick={(e) => confirmDelete(e, rowData.id)} />
        </>
    );

    return (
        <div className="grid crud-demo" style={{marginTop:0}}>
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4"  right={rightToolbarTemplate}></Toolbar>


                    <DataTable ref={dt} value={contactInfos}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Affichage {first} du {last} au {totalRecords} éléments"
                        globalFilter={globalFilter} emptyMessage="Aucun contact n'a été trouvé." header={header} responsiveLayout="scroll">

                        <Column field="title" header="Titre" sortable body={titleBodyTemplate} headerStyle={{ width: '20%', minWidth: '10rem' }}></Column>
                        <Column header="Image" body={iconBodyTemplate} headerStyle={{ width: '20%', minWidth: '10rem' }}></Column>
                        <Column field="content" header="Contenu" body={contentBodyTemplate} sortable headerStyle={{ width: '20%', minWidth: '8rem' }}></Column>
                        <Column field="phonenumber" header="Téléphone" body={phoneNumberBodyTemplate} sortable headerStyle={{ width: '20%', minWidth: '8rem' }}></Column>

                        <Column headerStyle={{ width: '20%', minWidth: '10rem' }} body={actionBodyTemplate}></Column>
                    </DataTable>


{/*                     <Dialog visible={dialog} style={{ width: '450px' }} header="Créer un contact" modal className="p-fluid" footer={dialogFooter} onHide={hideDialog}>
                        <div className="field">
                            <label htmlFor="title">Titre</label>
                            <InputText id="title" value={title} onChange={(e) => setTitle(e.target.value)} required autoFocus className={classNames({ 'p-invalid': submitted && !title })} />
                            {submitted && !title && <small className="p-invalid">Champ Obligatoire.</small>}
                        </div>

                        <div className="field">
                            <label htmlFor="content">Content</label>
                            <InputTextarea id="content" value={content} onChange={(e) => setContent(e.target.value)} required rows={3} cols={20} />
                        </div>



                        <div className="field">
                            <label htmlFor="phonenumber">Téléphone</label>
                            <InputText id="phonenumber" value={phonenumber} onChange={(e) => setPhonenumber(e.target.value)} required  />
                        </div>



                        <div className="field">
                            <label className="mb-3">Icone</label>
                            <Dropdown name="icon" value={icon} onChange={(e) => setIcon(e.target.value)}
                                 options={icons} optionValue="name" optionLabel="name"
                                      placeholder="Selectionner Votre Icône" />

                        </div>



                    </Dialog> */}
                    <Dialog visible={deleteDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteDialogFooter_(contactInfo)} onHide={hideDeleteDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {contactInfo && <span>Êtes-vous sûr de vouloir supprimer cette information de contact  : {contactInfo.title} ?</span>}
                        </div>
                    </Dialog>





                </div>
            </div>
        </div>
    );


}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps?.location?.pathname === nextProps?.location?.pathname;
};

export default React.memo(ContactInfos, comparisonFn);
