import axios from 'axios';
import DEV from '../../url';
export class LoginService{


    async login(data){
        const res = await axios.post(`${DEV}login/login`, data);
        return res.data;

    }

    async auth(data){
        const res = await axios.post(`${DEV}api/loginUser`, data);
        return res.data;
    }

     async token(){
        const res = await axios.get(`${DEV}api/verify`);
        return res.data;
    } 

    async logout(){
        const res = await axios.delete(`${DEV}api/logout`);
        return res.data;
    }

    Refreshtoken(){
       // return axios.get(`${DEV}api/token`).then((res)=>res.data);

    }


}
