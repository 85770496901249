import React, { useEffect, useRef, useState } from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { InputText } from "primereact/inputtext";
import ShowMoreText from "react-show-more-text";
import { Dialog } from "primereact/dialog";
import { FeedbackService } from "../../service/FeedbackService/FeedbackService";
import Moment from "react-moment";

const Feedback = () => {

    const service = new FeedbackService();

    const toast = useRef(null);
    const dt = useRef(null);
    const [feedback, setFeedback] = useState();
    const [deleteItemDialog, setDeleteItemDialog] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [items, setItems] = useState([null]);
    useEffect(() => {
        service.all().then((res) => {
            toast.current.show({ severity: 'success', summary: 'Opération Achevée', detail: 'Opération effectuée avec succés', life: 3000 });
            setItems(res);
        });
    }, []);


    const executeOnClick = (isExpanded) => {
        console.log(isExpanded);
    }

    const confirmDelete = async (e, id) => {
        e.preventDefault();
        await service.delete(id).then((res) => {
            toast.current.show({ severity: 'success', summary: 'Opération Achevée', detail: 'Cet item a été supprimé', life: 3000 });
            setDeleteItemDialog(false);
            const trash = items.filter(() => res?.result !== id);
            setItems(trash);
            window.location.reload(false);
        }, []);

    }

    const hideDeleteItemDialog = () => {
        setDeleteItemDialog(false);
    }

    const deleteItem = (e, avis) => {
        e.preventDefault();
        setFeedback({ ...avis });
        setDeleteItemDialog(true);
    }

    const fullnameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Nom Complet</span>
                <span> {rowData?.fullname}</span>
            </>
        );
    }

    const subjectBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Objet</span>
                <span> {rowData?.subject}</span>
            </>
        );
    }

    const dateFeedbackBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Date de création</span>
                <span>
                    <Moment format="DD MMM YYYY">
                        {rowData?.dateFeedback}
                    </Moment>
                </span>
            </>
        );
    }

    const approbationBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Approbation</span>
                {
                    rowData?.approbation === 'Oui' ?
                        <Button disabled title="Cet avis est approuvé" icon="pi pi-check" className=" p-button-success mt-2 mr-1">
                        </Button>
                        :
                        <Button disabled title="Cet avis n'est pas approuvé" icon="pi pi-times" className=" p-button-warning mt-2 mr-1">

                        </Button>
                }
            </>
        );
    }

    const messageBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Message</span>
                <ShowMoreText

                    lines={2}
                    more="Voir Plus"
                    less="Voir Moins"
                    className="text-justify"
                    anchorClass="my-anchor-css-class"
                    onClick={executeOnClick}
                    expanded={false}
                    width={280}
                    truncatedEndingComponent={"... "}
                >
                    <b> {rowData?.message}</b>
                </ShowMoreText>

            </>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <a href={`/#/commentaire/${rowData?.id}`}>
                    <Button title='Consulter le message' icon="pi pi-check" className="p-button-rounded p-button-info mt-2 mr-1" />
                </a>
                &nbsp;

                <Button onClick={(e) => deleteItem(e, rowData)} title='Supprimer cet item' icon="pi pi-trash" className="p-button-rounded p-button-danger mt-2 mr-1" />

            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Avis</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Cherchez ..." />
            </span>
        </div>
    );

    const deleteItemDialogFooter_ = (rowData) => (
        <>
            <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={hideDeleteItemDialog} />
            <Button label="Oui" icon="pi pi-check" className="p-button-text" onClick={(e) => confirmDelete(e, rowData.id)} />
        </>
    );

    return (
        <div className="grid crud-memo" style={{marginTop:0}}>
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <DataTable ref={dt} value={items}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Affichage {first} du {last} au {totalRecords} éléments"
                        globalFilter={globalFilter} emptyMessage="Aucun avis n'a été trouvé." header={header} responsiveLayout="scroll">

                        <Column field="fullname" header="Nom Complet" sortable body={fullnameBodyTemplate} headerStyle={{ width: '20%', minWidth: '10rem' }}></Column>
                        <Column field="subject" header="Objet" body={subjectBodyTemplate} sortable headerStyle={{ width: '20%', minWidth: '8rem' }}></Column>
                        <Column field="message" header="Message" body={messageBodyTemplate} sortable headerStyle={{ width: '20%', minWidth: '8rem' }}></Column>
                        <Column field="dateFeedback" header="Date de création" body={dateFeedbackBodyTemplate} sortable headerStyle={{ width: '20%', minWidth: '8rem' }}></Column>
                        <Column field="approbation" header="Approbation" body={approbationBodyTemplate} sortable headerStyle={{ width: '20%', minWidth: '8rem' }}></Column>

                        <Column headerStyle={{ width: '20%', minWidth: '10rem' }} body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={deleteItemDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteItemDialogFooter_(feedback)} onHide={hideDeleteItemDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {feedback && <span>Êtes-vous sûr de vouloir supprimer cet avis : {feedback?.fullname} ?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    )


}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Feedback, comparisonFn);
