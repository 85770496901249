import axios from 'axios';
import DEV from '../../url';
export class ReminderService {

    getAll(){
        return axios.get(`${DEV}reminder/all`).then((res)=>res.data);
     }

     supprimer(id)
     {
         return axios.delete(`${DEV}reminder/delete/`+id);
     }
}
