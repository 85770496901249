import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';

import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import {BookingService} from "../../service/bookkingService/bookingService";
import ShowMoreText from "react-show-more-text";
import ReactWhatsapp from "react-whatsapp";

import Moment from "react-moment";
import { Toolbar } from 'primereact/toolbar';

const Bookings = () => {
      const service = new BookingService();
    const toast = useRef(null);
    const dt = useRef(null);
    const [book, setBook] = useState();
    const [deleteItemDialog, setDeleteItemDialog] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [bookings, setBooks] = useState([null]);

    const exportCSV = () => {
        dt.current.exportCSV();
    };
    useEffect(() => {
        service.getAll().then((res) => {
            toast.current.show({ severity: 'success', summary: 'Opération Achevée', detail: 'Opération effectuée avec succés', life: 3000 });
            setBooks(res);
        });
    }, []);

    const confirmDelete = async (e, id) => {
        e.preventDefault();
        await service.supprimer(id).then((res) => {
            toast.current.show({ severity: 'success', summary: 'Opération Achevée', detail: 'Cet item a été supprimé', life: 3000 });
            setDeleteItemDialog(false);
            const trash = bookings.filter(() => res?.result !== id);
            setBooks(trash);
            window.location.reload(false);
        }, []);

    }

    const hideDeleteItemDialog = () => {
        setDeleteItemDialog(false);
    }

    const deleteItem = (e, book) => {
        e.preventDefault();
        setBook({ ...book });
        setDeleteItemDialog(true);
    }

    const executeOnClick = (isExpanded) => {
        console.log(isExpanded);
    }


    const fullnameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Nom du client</span>
                <span> {rowData?.fullname}</span>
            </>
        );
    }

    const emailBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Email</span>
                <a href={`mailto:`+rowData?.email}>{rowData?.email}</a>
            </>
        );
    }

    const titleBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Objet de réservation</span>
                <span> {rowData?.title}</span>
            </>
        );
    }


    const typeChambreBodyTemplate = (rowData) => {
        return (
            <>
            <span className="p-column-title">Type de chambre</span>
            {rowData?.qte_single === ("") ? <span> <b>{rowData?.single}</b> - Nombre  : 0</span> : <span> <b>{rowData?.single}</b> - Nombre  : {rowData?.qte_single}</span>}
                 <br/>
                 {rowData?.qte_double === ("") ? <span> <b>{rowData?.double_}</b> - Nombre  : 0</span> : <span> <b>{rowData?.double_}</b> - Nombre  : {rowData?.qte_double}</span>}
                <br/>
                {rowData?.qte_triple === ("") ? <span> <b>{rowData?.triple}</b> - Nombre  : 0</span> : <span> <b>{rowData?.triple}</b> - Nombre  : {rowData?.qte_triple}</span>}
                <br/>
                {rowData?.qte_quadriple === ("") ? <span> <b>{rowData?.quadriple}</b> - Nombre  : 0</span> : <span> <b>{rowData?.quadriple}</b> - Nombre  : {rowData?.qte_quadriple}</span>}
                <br/>

        </>
        );
    }


    

    const phonenumberBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Téléphone</span>
                <a href={`tel:`+rowData?.phonenumber}>{rowData?.phonenumber}</a>
            </>
        );
    }

    const subjectBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Address</span>
                <span> {rowData?.address}</span>
            </>
        );
    }

    const currentBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Date courante</span>
                <Moment format="YYYY/MM/DD">
                    {rowData?.current}
                </Moment>
            </>
        );
    }

    const endDateBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Date de départ</span>
                <Moment format="YYYY/MM/DD">
                    {rowData?.end_date}
                </Moment>
            </>
        );
    }


    const bookingDateBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Date d'arrivée</span>
                <Moment format="YYYY/MM/DD">
                    {rowData?.date_booking}
                </Moment>
            </>
        );
    }

   
    const countryBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Pays</span>
                {rowData?.country}
            </>
        );
    }


    const messageBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">description</span>
                <ShowMoreText

                    lines={2}
                    more="Voir Plus"
                    less="Voir Moins"
                    className="text-justify"
                    anchorClass="my-anchor-css-class"
                    onClick={executeOnClick}
                    expanded={false}
                    width={280}
                    truncatedEndingComponent={"... "}
                >
                    <b> {rowData?.description}</b>
                </ShowMoreText>

            </>
        );
    }



    const personnesChambreBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Nombre de personnes</span>
                <span> Adultes : {rowData?.nb_adultes === "" ? <p>0</p> : <p>{rowData?.nb_adultes}</p> } </span> <br/>
                <span> Enfants : {rowData?.nb_enfants === "" ? <p>0</p>: <p>{rowData?.nb_enfants}</p>} </span> <br/>
                <span> Bébés : {rowData?.nb_bébés === "" ? <p>0</p>:<p>{rowData?.nb_bébés}</p>} </span> <br/>


            </>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">


                    <ReactWhatsapp style={{height:"42px",width:"42px",border:"1px solid transparent",backgroundColor:"#5EBA7D",marginBottom:"0px",borderRadius:"50px"}}
                    title={" " + rowData?.phonenumber}
                    number={" " + rowData?.phonenumber}
                    message="">
                        <span className="pi pi-phone text-white"></span>
                    </ReactWhatsapp>
                    &nbsp;

                <Button onClick={(e) => deleteItem(e, rowData)} title='Supprimer cet item' icon="pi pi-trash" className="p-button-rounded p-button-danger mt-2 mr-1" />

            </div>
        );
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Exporter en CSV" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        )
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Demandes de réservation</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Cherchez ..." />
            </span>
        </div>
    );

    const deleteItemDialogFooter_ = (rowData) => (
        <>
            <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={hideDeleteItemDialog} />
            <Button label="Oui" icon="pi pi-check" className="p-button-text" onClick={(e) => confirmDelete(e, rowData.id)} />
        </>
    );



    return (
        <div className="grid crud-memo" style={{marginTop:0}}>
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4"  right={rightToolbarTemplate}></Toolbar>

                    <DataTable ref={dt} value={bookings}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Affichage {first} du {last} au {totalRecords} éléments"
                        globalFilter={globalFilter} emptyMessage="Aucune demande de réservation n'a été trouvée." header={header} responsiveLayout="scroll">

                        <Column field="fullname" header="Nom Complet" sortable body={fullnameBodyTemplate} headerStyle={{ width: '20%', minWidth: '10rem' }}></Column>
                        <Column field="country" header="Pays" sortable body={countryBodyTemplate} headerStyle={{ width: '20%', minWidth: '10rem' }}></Column>

                        <Column field="email" header="Email" sortable body={emailBodyTemplate} headerStyle={{ width: '20%', minWidth: '10rem' }}></Column>
                        <Column field="phonenumber" header="Téléphone" body={phonenumberBodyTemplate} headerStyle={{ width: '20%', minWidth: '10rem' }}></Column>
                        <Column field="subject" header="Adresse" body={subjectBodyTemplate} sortable headerStyle={{ width: '20%', minWidth: '8rem' }}></Column>
                        <Column field="title" header="Objet" body={titleBodyTemplate} sortable headerStyle={{ width: '20%', minWidth: '8rem' }}></Column>
                        <Column field="type" header="Type de chambre" body={typeChambreBodyTemplate} sortable headerStyle={{ width: '50%', minWidth: '8rem' }}></Column>
                        <Column field="message" header="Autre Informations" body={messageBodyTemplate} sortable headerStyle={{ width: '20%', minWidth: '8rem' }}></Column>
                        <Column field="personnes" header="Nombre de personnes" body={personnesChambreBodyTemplate} sortable headerStyle={{ width: '20%', minWidth: '8rem' }}></Column>
                        <Column field="date_booking" header="Date d'arrivée" body={bookingDateBodyTemplate} sortable headerStyle={{ width: '20%', minWidth: '8rem' }}></Column>
                        <Column field="end_date" header="Date de départ" body={endDateBodyTemplate} sortable headerStyle={{ width: '20%', minWidth: '8rem' }}></Column>
                        <Column field="current" header="Date de la demande" body={currentBodyTemplate} sortable headerStyle={{ width: '20%', minWidth: '8rem' }}></Column>
                        <Column headerStyle={{ width: '20%', minWidth: '10rem' }} body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={deleteItemDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteItemDialogFooter_(book)} onHide={hideDeleteItemDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {book && <span>Êtes-vous sûr de vouloir supprimer cette demande de réservation : {book?.fullname}{" Ayant le numéro : "}{book?.phonenumber} ? {"et au nom de : "}{book?.fullname}</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    )

}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Bookings, comparisonFn);
