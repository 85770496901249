import {Button} from "primereact/button";
import React, {useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {ContactInfoService} from "../../../service/ConfigServiceApp/ContactInformations/contactInfoService";
import iconJson from "../../Contact/ContactInfos/icons.json"
import {Toast} from "primereact/toast";
import {Toolbar} from "primereact/toolbar";
import {InputText} from "primereact/inputtext";
import {InputTextarea} from "primereact/inputtextarea";
import {Dropdown} from "primereact/dropdown";

const ModifierContactInfo = () => {

    const history = useNavigate();
    const toast = useRef(null);

    const service = new ContactInfoService();
    const {id} = useParams();

    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [phonenumber, setPhonenumber] = useState("");
    const [icon, setIcon] = useState("");
    const [icons, setIcons] = useState([]);

    useEffect(() => {
        setIcons(iconJson);
        console.log("icons", icons)
    }, []);

    const modifier = async (e) => {
        e.preventDefault();
        service.updateContactInfo(id, {title: title, icon: icon, content: content, phonenumber: phonenumber}).then((res) => {
            toast.current.show({severity: 'success', summary: 'Opération Achevée', detail: 'Lien Modifié', life: 3000});
        });
    }

    const fetch = async () => {
        service.getContactInfo(id).then((res) => {
            setTitle(res?.data?.title);
            setIcon(res?.data?.icon);
            setContent(res?.data?.content);
            setPhonenumber(res?.data?.phonenumber);
        });
    }

    useEffect(() => {
        fetch();
    }, []);


    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button onClick={(e) => modifier(e)} label="Sauvegarder" icon="pi pi-fw pi-check" className="p-button-success mr-2"/>
            </React.Fragment>
        )
    }

    const goto = (e) => {
        e.preventDefault();
        history("/contactInfos")
    }



    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button onClick={(e) => goto(e)} className="p-button-rounded p-button-outlined p-button-danger">
                    <i className="pi pi-times"></i>
                </Button>
            </React.Fragment>
        )
    }

    return(
                <React.Fragment>
            <div className="grid" style={{marginTop:0}}>
                <Toast ref={toast} />
                <Toolbar className="mb-4 col-12" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                <div className="col-12 md:col-12 mt-4">
                    <div className="card p-fluid">
                        <h5>Modifier cette information</h5>
                        <form>
                            <div className="field">
                                <label htmlFor="title">Titre</label>
                                <InputText disabled readOnly value={title || ''} onChange={(e) => setTitle(e.target.value)} id="sectionName" type="text" />
                                 <div className="card mt-2" style={{background:"#FB7E7E"}}>
                                    <p className="text-white"> <i className="pi pi-fw pi-lock"></i> Ce champ est en mode lecture seul.</p>
                                 </div>
                            </div>

                            <div className="field">
                            <label htmlFor="content">Content</label>
                            <InputTextarea id="content" value={content || ''} onChange={(e) => setContent(e.target.value)} required rows={3} cols={20} />
                        </div>



                        <div className="field">
                            <label htmlFor="phonenumber">Téléphone</label>
                            <InputText  id="phonenumber" value={phonenumber || ''} onChange={(e) => setPhonenumber(e.target.value)} required  />
                        </div>



                        <div className="field">
                            <label className="mb-3">Icone</label>
                            <Dropdown name="icon" value={icon || ''} onChange={(e) => setIcon(e.target.value)}
                                 options={icons} optionValue="name" optionLabel="name"
                                      placeholder="Selectionner Votre Icône" />

                        </div>
                        </form>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )

}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps?.location?.pathname === nextProps?.location?.pathname;
};

export default React.memo(ModifierContactInfo, comparisonFn);
