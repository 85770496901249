import React, { useContext } from "react";
import ActivitiesStat from "./ActivitiesStat/ActivitiesStat";
import EventStat from "./EventsStat/EventStat";
import RoomsStat from "./RoomsStat/RoomsStat";
import ProductsStat from "./ProductsStat/ProductsStat";
import RecentActivities from "./RecentActivities/RecentActivities";
import RecentReviews from "./RecentReviews/RecentReviews";
import Hour from "./Clock/Hour";

import { AuthContext } from "../AuthContext";
import RestaurantStat from "./RestaurantStat/RestaurantStat";
import DejeunerStat from "./RestaurantStat/DejeunerStat";
import ConventionStat from "./ConventionStat/ConventionStat";
import PackStat from "./PackStat/PackStat";
import BookingStat from "./BookingStat/BookingStat";
import BookingChart from "./BookingChart/BookingChart";
import AvisStat from "./AvisStat/AvisStat";

const Dashboard = () => {
    //const { login } = useContext(AuthContext);

    return (
        <div className="grid">
            <div className="col-12 lg:col-12 xl:col-12" align="center">
                <h1>Local Time in Dar Zaghouan</h1>
                <Hour />
            </div>

            <div className="col-12 xl:col-6">
                <div className="card">
                    <BookingStat />
                </div>
            </div>

            <div className="col-12 xl:col-6">
                <div className="card">
                    <div className="flex align-items-center justify-content-between mb-4">
                        <h5>Avis récents</h5>
                    </div>

                    <span className="block text-600 font-medium mb-3">Aujourd'hui</span>

                    <RecentReviews />
                </div>
            </div>

            <div className="col-12 lg:col-4 xl:col-4">
                <ActivitiesStat />
            </div>
            <div className="col-12 lg:col-4 xl:col-4">
                <EventStat />
            </div>
            <div className="col-12 lg:col-4 xl:col-4">
                <RoomsStat />
            </div>
            <div className="col-12 lg:col-4 xl:col-4">
                <ProductsStat />
            </div>

            <div className="col-12 lg:col-4 xl:col-4">
                <RestaurantStat />
            </div>

            <div className="col-12 lg:col-4 xl:col-4">
                <DejeunerStat />
            </div>
            <div className="col-12 lg:col-4 xl:col-4">
                <ConventionStat />
            </div>
            <div className="col-12 lg:col-4 xl:col-4">
                <PackStat />
            </div>
            <div className="col-12 lg:col-4 xl:col-4">
                <AvisStat />
            </div>

            
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps?.location?.pathname === nextProps?.location?.pathname && prevProps.colorMode === nextProps.colorMode;
};

export default React.memo(Dashboard, comparisonFn);
