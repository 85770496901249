import React, { useState, useEffect } from 'react';
import { ActivityService } from '../../service/activityService/ActivityService';

function RecentActivities() {

    const [items, setItems] = useState([]);
    const service = new ActivityService();
    const fetchRecent = () => {
        service.getRecent().then((res) => {
            setItems(res.data);
        });
    }
    useEffect(() => {
        fetchRecent();
    },[]);
    return (
        <div>
            {
                items.length !== 0 ?
                    <ul className="p-0 mx-0 mt-0 mb-4 list-none">
                        {
                            items.map((item, index) => (
                                <li key={index} className="flex align-items-center py-2 border-bottom-1 surface-border">
                                    <div className="w-3rem h-3rem flex align-items-center justify-content-center bg-blue-100 border-circle mr-3 flex-shrink-0">
                                        <i className="pi pi-calendar text-xl text-blue-500" />
                                    </div>
                                    <span className="text-900 line-height-3"> <b>{item?.title}</b>    &nbsp;
                                        <span className="text-700">
                                            <b style={{ color: "green" }}>{item?.type}</b>
                                            <span className="text-blue-500"></span></span>
                                    </span>
                                </li>
                            ))
                        }


                    </ul> : <React.Fragment>
                        <i className="pi pi-spin pi-spinner" style={{ fontSize: "1rem" }}></i> Aucune activité
                    </React.Fragment>
            }
        </div>
    );
}

export default RecentActivities;
