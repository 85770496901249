import axios from 'axios';
import DEV from '../../url';
export class WhoAreWeService {

    getSections()
    {
        return axios.get(`${DEV}whoarewe/all`).then((data)=>data.data);
    }

    deleteSection(id){
        return axios.delete(`${DEV}whoarewe/delete/`+id).then(res=> res.data);
    }

    deleteSections(){
        return axios.delete(`${DEV}whoarewe/deleteAll`).then(res=> res.data);
    }

    createSection(data){
        return axios.post(`${DEV}whoarewe/create`,data).then(res=> res.data);
    }

    getSection(id)
    {
        return axios.get(`${DEV}whoarewe/get/`+id);
    }

    updateSection(id,data)
    {
        return axios.put(`${DEV}whoarewe/update/`+id,data).then(res=>res.data);
    }

    postContent(id,data){
        return axios.post(`${DEV}whoarewe/postcontent/`+id,data).then(res=>res.data);
    }

    getContentSection(id)
    {
        return axios.get(`${DEV}whoarewe/getbysection/`+id).then(res=>res.data);
    }

    getContentSections()
    {
        return axios.get(`${DEV}whoarewe/getbysectionbydisplayed`);
    }

    getContentSectionsToMainPage()
    {
        return axios.get(`${DEV}whoarewe/displaytomainpage`);
    }

    getContentSectionsNotToMainPage()
    {
        return axios.get(`${DEV}whoarewe/displaynottomainpage`);
    }

    deleteContent(idc){
        return axios.delete(`${DEV}whoarewe/deletecontent/`+idc);
    }





}
