import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { WhoAreWeService } from '../../service/whoAreWeService/WhoAreWeService';


const Sections = () => {

    let emptySection = {
        id: 0,
        sectionName: ''
    };

    const sectionService = new WhoAreWeService();
    const [sections, setSections] = useState([]);
    const [deleteSectionDialog, setDeleteSectionDialog] = useState(false);
    const [deleteSectionsDialog, setDeleteSectionsDialog] = useState(false);
    const [whoarewe, setWhoarewe] = useState(null);
    const [sectionDialog, setSectionDialog] = useState(false);
    const [submittedSection, setSubmittedSection] = useState(false);

    const [sectionName, setSectionName] = useState("");
    const [isDisplayed, setIsDisplayed] = useState("");

    const dropdownValues = [
        { name: 'Oui', code: 'oui' },
        { name: 'Non', code: 'non' },
    ];

    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        sectionService.getSections().then((res) => {
            toast.current.show({ severity: 'success', summary: 'Opération Achevée', detail: 'Opération effectuée avec succés', life: 3000 });
            setSections(res);
        });
    }, []);

    const hideDeleteSectionDialog = () => {
        setDeleteSectionDialog(false);
    }

    const hideDeleteSectionsDialog = () => {
        setDeleteSectionsDialog(false);
    }

    const deleteSection = (e, whoarewe) => {
        e.preventDefault();
        setWhoarewe({ ...whoarewe });
        setDeleteSectionDialog(true);
    }

    const confirmDeleteSection = async (e, id) => {
        e.preventDefault();
        await sectionService.deleteSection(id).then((res) => {
            toast.current.show({ severity: 'success', summary: 'Opération Achevée', detail: 'Cet item a été supprimée', life: 3000 });
            setDeleteSectionDialog(false);
            const trash = sections.filter(() => res?.result !== id);
            setSections(trash);
            window.location.reload(false);
        }, []).catch(()=>{
            toast.current.show({ severity: 'error', summary: 'Opération échouée', detail: "La section que vous venez de la supprimer, contienne un autre contenu. Merci de les supprimer avant.", life: 6000 });
        });

    }

    const openNewSection = () => {
        setWhoarewe(emptySection);
        setSubmittedSection(false);
        setSectionDialog(true);
    }

    const save = async () => {
        setSubmittedSection(true);

        if(sectionName === "" || isDisplayed === ""){
            toast.current.show({ severity: "error", summary: "Merci de bien compléter les champs", life: 3000 });              
            setSectionDialog(true);
        }else {
            await sectionService.createSection({ sectionName, isDisplayed }).then(res => {
                toast.current.show({ severity: 'success', summary: 'Opération Achevée', detail: 'Une nouvelle section a été ajouté', life: 3000 });
                setSections(sections);
                window.location.reload(false);
                setSectionDialog(false);
            });
        }
        
    }

    const hideDialogSection = () => {
        setSubmittedSection(false);
        setSectionDialog(false);
    }


    const deleteAll = () => {
        sectionService.deleteSections().then((res) => {
            setSections(null);
            setDeleteSectionsDialog(false);
            toast.current.show({ severity: 'success', summary: 'Succés', detail: 'Les items sont supprimés avec succées', life: 3000 });
            window.location.reload(false);
        });

    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const confirmDeleteSelected = () => {
        setDeleteSectionsDialog(true);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Nouvelle section" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNewSection} />
                </div>
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Exporter en CSV" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        )
    }

    const sectionNameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Nom Section</span>
                <b> {rowData.sectionName}</b>
            </>
        );
    }

    const isDisplayedBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Afficher à la page d'accueil</span>
                <b> {rowData.isDisplayed}</b>
            </>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (

            <div className="actions">

                <a href={`/#/section/modifier/${rowData.id}`}>    <Button title='Modifier cet item' icon="pi pi-pencil" className="p-button-rounded p-button-success mr-1">
                </Button> </a>
                <a href={`/#/section/ajoutercontenu/${rowData.id}`}>    <Button title='Ajouter du contenu cet item' icon="pi pi-plus" className="p-button-rounded p-button-info mr-1">
                </Button> </a>
                <Button title='Supprimer cet item' icon="pi pi-trash" className="p-button-rounded p-button-danger mt-2 mr-1" onClick={(e) => deleteSection(e, rowData)} />

            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Sections</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Cherchez..." />
            </span>
        </div>
    );

    const sectionDialogFooter = (
        <>
            <Button label="Annuler" icon="pi pi-times" className="p-button-text" onClick={hideDialogSection} />
            <Button label="Sauvegarder" icon="pi pi-check" className="p-button-text" onClick={save} />
        </>
    );


    const deleteSectionDialogFooter_ = (rowData) => (
        <>
            <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={hideDeleteSectionDialog} />
            <Button label="Oui" icon="pi pi-check" className="p-button-text" onClick={(e) => confirmDeleteSection(e, rowData.id)} />
        </>
    );

    const deleteSectionsDialogFooter = (
        <>
            <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={hideDeleteSectionsDialog} />
            <Button label="Oui" icon="pi pi-check" className="p-button-text" onClick={deleteAll} />
        </>
    );


    return (
        <div className="grid crud-demo" style={{marginTop:0}}>
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>


                    <DataTable ref={dt} value={sections}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Affichage {first} du {last} au {totalRecords} éléments"
                        globalFilter={globalFilter} emptyMessage="Aucune section n'a été trouvée." header={header} responsiveLayout="scroll">

                        <Column field="sectionName" header="Nom de la section" sortable body={sectionNameBodyTemplate} headerStyle={{ width: '20%', minWidth: '10rem' }}></Column>
                        <Column field="isDisplayed" header="Afficher à la page d'accueil" sortable body={isDisplayedBodyTemplate} headerStyle={{ width: '20%', minWidth: '10rem' }}></Column>

                        <Column headerStyle={{ width: '20%', minWidth: '10rem' }} body={actionBodyTemplate}></Column>
                    </DataTable>


                    <Dialog visible={sectionDialog} style={{ width: '450px' }} header="Créer une section" modal className="p-fluid" footer={sectionDialogFooter} onHide={hideDialogSection}>
                        <div className="field">
                            <label htmlFor="sectionName">Nom de la section</label>
                            <InputText  id="sectionName" value={sectionName} onChange={(e) => setSectionName(e.target.value)} required 
                            autoFocus className={classNames({'p-invalid': submittedSection && !sectionName})} 
                            />
                            {submittedSection && !sectionName && <small className="p-invalid">Champ Obligatoire.</small>}

                        </div>

                        <div className="field">
                            <label className="mb-3">Afficher Cette Section à la page d'accueil</label>
                            <Dropdown required={true} name="isDisplayed" value={isDisplayed} onChange={(e) => setIsDisplayed(e.target.value)} options={dropdownValues} 
                            autoFocus 
                            className={classNames({'p-invalid': submittedSection && !isDisplayed})}
                            optionValue="name" optionLabel="name" placeholder="Selectionner Votre Valeur" />
                            {submittedSection && !isDisplayed && <small className="p-invalid">Champ Obligatoire.</small>}

                        </div>


                    </Dialog>
                    <Dialog visible={deleteSectionDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteSectionDialogFooter_(whoarewe)} onHide={hideDeleteSectionDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {whoarewe && <span>Êtes-vous sûr de vouloir supprimer cet élément : {whoarewe.sectionName} ?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteSectionsDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteSectionDialogFooter_} onHide={hideDeleteSectionsDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            <span>Êtes-vous sûr de vouloir supprimer toutes vos items ?</span>
                        </div>
                    </Dialog>



                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps?.location?.pathname === nextProps?.location?.pathname;
};

export default React.memo(Sections, comparisonFn);
